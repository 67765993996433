/***
 *
 *   Prospects Page
 *
 **********/

import { React, useContext, useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Animate,
  ViewContext,
  TitleRow,
  TabView,
} from "components/lib";
import axios from "axios";

export function Prospects(props) {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const [companiesData, setCompaniesData] = useState([]);
  const [archiveCompanies, setArchiveCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const res = await axios.get("/api/user/getProspects");

    // format the companies list table
    let list = [];

    if (res.data instanceof Array) {
      list = res.data.map((x) => {
        return {
          "dot_#": x.dotNumber,
          company_name: x.legalName,
          owner_name: x.companyRep1,
          phone: x.telephone,
          email: x.emailAddress,
          status: x.status,
          type: "prospect",
        };
      });
    }
    setCompaniesData(list);
    const resArchive = await axios.get("/api/user/getArchivedProspects");
    let archiveList = [];

    if (resArchive.data) {
      archiveList = resArchive.data.map((x) => {
        return {
          "dot_#": x.dotNumber,
          company_name: x.legalName,
          owner_name: x.companyRep1,
          phone: x.telephone,
          email: x.emailAddress,
          status: x.status,
          type: "prospect",
        };
      });
    }
    setArchiveCompanies(archiveList);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const addProspect = () => {
    context.modal.show(
      {
        title: "Add Prospect",
        addCompany: "Prospect",
      },
      (form, res) => {
        if (res?.newProspect) {
          context.notification.show(
            `Company ${res.newProspect.legalName} Created `,
            "success",
            true
          );
          const company = {
            "dot_#": res.newProspect.dotNumber,
            company_name: res.newProspect.legalName,
            owner_name: res.newProspect.companyRep1,
            phone: res.newProspect.telephone,
            email: res.newProspect.emailAddress,
            status: res.newProspect.status,
            type: "prospect",
          };
          setCompaniesData([company, ...companiesData]);
        }
      }
    );
  };

  const archiveProspect = (data) => {
    context.modal.show(
      {
        title: "Archive Prospect",
        text: `Are you sure you want to archive Prospect "${data.company_name}" ?`,
        secondText:
          "Warning: All archived items (such as archived vehicles) will be converted to active",
        form: {
          legalName: {
            type: "hidden",
            value: data.company_name,
          },
          dotNumber: {
            type: "hidden",
            value: data["dot_#"],
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          userName: {
            type: "hidden",
            value: user.name,
          },
          editedField: {
            type: "hidden",
            value: `Restored company "${data.company_name}"`,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Archive",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/prospect/archive",
        method: "DELETE",
      },
      (form, res) => {
        if (res?.Archives) {
          let list = [...companiesData];
          setCompaniesData(
            list.filter((item) => item["dot_#"] !== data["dot_#"])
          );
          context.notification.show(
            res.Archives.legalName + " archived",
            "success",
            true
          );

          delete data.actions;

          const archiveList = [data, ...archiveCompanies];

          setArchiveCompanies(archiveList);
        }
      }
    );
  };

  const restoreCompany = (data) => {
    context.modal.show(
      {
        title: "Restore Company",
        text: `Are you sure you want to restore company "${data.company_name}" ?`,
        form: {
          dotNumber: {
            type: "hidden",
            value: data["dot_#"],
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          userName: {
            type: "hidden",
            value: user.name,
          },
          editedField: {
            type: "hidden",
            value: `Restored company "${data.company_name}"`,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Restore",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/prospect/restore",
        method: "POST",
      },
      (form, res) => {
        if (res) {
          delete data.actions;

          let companiesLiest = [data, ...companiesData];
          setCompaniesData(companiesLiest);

          const archiveList = [...archiveCompanies];
          setArchiveCompanies(
            archiveList.filter((item) => item["dot_#"] !== data["dot_#"])
          );

          context.notification.show("Company Restored", "success", true);
        }
      }
    );
  };

  const approveProspect = (data) => {
    context.modal.show(
      {
        title: `Convert Prospect "${data.company_name}" to a Company?`,
        text: `Are you sure you want to convert Prospect "${data.company_name}" to a Company?`,
        form: {
          legalName: {
            type: "hidden",
            value: data.company_name,
          },
          dotNumber: {
            type: "hidden",
            value: data["dot_#"],
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          userName: {
            type: "hidden",
            value: user.name,
          },
          editedField: {
            type: "hidden",
            value: `Approved prospect "${data.company_name}"`,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Approve",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/prospect/approve",
        method: "POST",
      },
      (form, res) => {
        if (res?.Company) {
          let list = [...companiesData];
          setCompaniesData(
            list.filter((item) => item["dot_#"] !== data["dot_#"])
          );
          context.notification.show(
            res.Company.legalName + " approved",
            "success",
            true
          );
        }
      }
    );
  };

  return (
    <Animate type="pop">
      <TitleRow>
        {!["customer"].includes(user.permission) && (
          <Button
            small
            text="Add Prospect"
            icon="plus-circle"
            className="bg-blue-700 text-white rounded mr-4"
            action={addProspect}
          />
        )}
      </TitleRow>

      <TabView
        name="prospects"
        labels={["Active Prospects", "Archived Prospects"]}
      >
        <Card>
          <Table
            search
            data={companiesData}
            loading={loading}
            badge={{
              col: "status",
              color: "orange",
              condition: [
                { value: "inactive", color: "red" },
                { value: "active", color: "green" },
              ],
            }}
            actions={{
              viewCompany: {
                url: "prospects",
                col: "dot_#",
                saveData: true,
              },
              custom: [
                {
                  col: "approve",
                  value: "approve",
                  icon: "arrow-up-circle",
                  action: approveProspect,
                },
                {
                  col: "archive",
                  value: "archive",
                  icon: "archive",
                  action: archiveProspect,
                },
              ],
            }}
            show={["dot_#", "company_name", "owner_name", "phone", "email"]}
            paginate
            paginateLimit="10"
          ></Table>
        </Card>

        <Card>
          <Table
            search
            data={archiveCompanies}
            loading={loading}
            actions={{
              custom: [
                {
                  col: "restore",
                  value: "restore",
                  icon: "rotate-ccw",
                  action: restoreCompany,
                },
              ],
            }}
            show={["dot_#", "company_name", "owner_name", "phone", "email"]}
            paginate
            paginateLimit="5"
          ></Table>
        </Card>
      </TabView>
    </Animate>
  );
}
