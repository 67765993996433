import { Text, View } from "@react-pdf/renderer";
function PartOne({ insured, carrierInfo }) {
  return (
    <View
      style={{
        border: "1.5px solid black",
        width: "95%",
        paddingHorizontal: " 10px",
        paddingTop: "2px"
      }}
    >
      <Text
        style={{
          borderBottom: "1.5px solid black",
          fontFamily: "Helvetica-Bold",
          fontSize: "8px",
          fontWeight: "600",
          lineHeight: "1.25",
          marginLeft: "-10px",
          marginRight: "-10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "2px"
        }}
      >
        THIS CERTIFICATE IS ISSUED AS A MATTER OF INFORMATION ONLY AND CONFERS
        NO RIGHTS UPON THE CERTIFICATE HOLDER. THIS CERTIFICATE DOES NOT
        AFFIRMATIVELY OR NEGATIVELY AMEND, EXTEND OR ALTER THE COVERAGE AFFORDED
        BY THE POLICIES BELOW. THIS CERTIFICATE OF INSURANCE DOES NOT CONSTITUTE
        A CONTRACT BETWEEN THE ISSUING INSURER(S), AUTHORIZED REPRESENTATIVE OR
        PRODUCER, AND THE CERTIFICATE HOLDER.
      </Text>
      <Text
        style={{
          borderBottom: "1.5px solid black",
          fontFamily: "Helvetica-Bold",
          fontSize: "9px",
          fontWeight: "600",
          marginLeft: "-10px",
          marginRight: "-10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingVertical: "2px"
        }}
      >
        IMPORTANT : If the certificate holder is an ADDITIONAL INSURED, the
        policy(ies) must be endorsed. If SUBROGATION IS WAIVED, subject to the
        terms and conditions of the policy, certain policies may require an
        endorsement. A statement on this certificate does not confer rights to
        the certificate holder in lieu of such endorsement(s).
      </Text>
      <View
        style={{
          flexDirection: "row",
          marginTop: "-10px",
          marginBottom: "-1px",
          fontFamily: "Helvetica-Bold"
        }}
      >
        <View
          style={{
            marginTop: "10px",
            flex: "1",
            borderRight: "1.5px solid black",
            fontSize: "7px"
          }}
        >
          <View
            style={{
              height: "83px",
              borderBottom: "1px solid black",
              marginLeft: "-10px",
              paddingTop: "2px",
              paddingLeft: "10px"
            }}
          >
            <Text>PRODUCER</Text>
            <Text
              style={{
                textAlign: "left",
                paddingTop: "3px",
                fontFamily: "Helvetica",
                fontSize: "12px",
                marginTop: "10px"
              }}
            >
              American Fleet Insurance
            </Text>
            <Text
              style={{
                textAlign: "left",
                paddingTop: "3px",
                fontFamily: "Helvetica",
                fontSize: "10px"
              }}
            >
              5650 N Riverside Suite 101, Ft. Worth, TX 76137
            </Text>
          </View>
          <View style={{ height: "63px" }}>
            <Text style={{ fontSize: "7px", paddingTop: "2px" }}>INSURED</Text>
            <Text
              style={{
                textAlign: "left",
                paddingTop: "3px",
                fontFamily: "Helvetica",
                fontSize: "10px",
                marginTop: "10px"
              }}
            >
              {insured.legal_name}
            </Text>
            <Text
              style={{
                textAlign: "left",
                paddingTop: "3px",
                fontFamily: "Helvetica",
                fontSize: "10px"
              }}
            >
              {insured.mailing_address}, {insured.mailing_city},{" "}
              {insured.mailing_state}, {insured.mailing_zip}
            </Text>
          </View>
        </View>
        <View style={{ flex: "1", marginTop: "9px" }}>
          <View
            style={{
              flexDirection: "row",
              marginRight: "-10px",
              fontSize: "7px",
              paddingTop: "2px",
              borderBottom: "1px solid black"
            }}
          >
            <View
              style={{
                width: "20%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                paddingLeft: "6px"
              }}
            >
              <Text>CONTACT</Text>
              <Text>NAME:</Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: "1"
              }}
            >
              <Text style={{ fontSize: "7px" }}>
                Stephen Langford
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginRight: "-10px",
              fontSize: "7px",
              paddingTop: "2px",
              borderBottom: "1px solid black"
            }}
          >
            <View
              style={{
                width: "30%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                paddingLeft: "6px"
              }}
            >
              <Text>PHONE</Text>
              <Text>(A/C No. Ext):</Text>
            </View>
            <Text
              style={{
                fontSize: "7px",
                borderRight: "1px solid black",
                marginTop: "-2px",
                width: "30%",
                textAlign: "justify",
                paddingTop: "4px"
              }}
            >
              (866) 915-6048
            </Text>
            <View style={{ width: "20%", paddingLeft: "3px" }}>
              <Text>FAX</Text>
              <Text>(A/C No.)</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginRight: "-10px",
              fontSize: "7px",
              paddingTop: "2px",
              borderBottom: "1.5px solid black"
            }}
          >
            <View
              style={{
                width: "20%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                paddingLeft: "6px"
              }}
            >
              <Text>EMAIL</Text>
              <Text>ADDRESS:</Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: "1"
              }}
            >
              <Text style={{ fontSize: "7px" }}>certs@teamafi.com</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginRight: "-10px",
              fontSize: "7px",
              paddingTop: "3px",
              borderBottom: "1px solid black"
            }}
          >
            <Text
              style={{
                width: "70%",
                textAlign: "center",
                borderRight: "1.5px solid black",
                marginTop: "-3px",
                paddingTop: "5px"
              }}
            >
              INSURER(S) AFFORDING COVERAGE
            </Text>
            <Text style={{ fontSize: "7px", textAlign: "center", flex: "1" }}>
              NAIC #
            </Text>
          </View>
          <View
            style={{
              marginRight: "-10px",
              fontSize: "6.5px",
              borderBottom: "1px solid black",
              flexDirection: "row"
            }}
          >
            <View
              style={{
                width: "70%",
                borderRight: "1.5px solid black",
                marginTop: "-2px",
                flexDirection: "row",
                paddingTop: "8px"
              }}
            >
              <View
                style={{
                  borderRight: "1px solid black",
                  flexDirection: "row",
                  marginTop: "-7px"
                }}
              >
                <Text
                  style={{
                    width: "28%",
                    textAlign: "center",
                    marginTop: "7px"
                  }}
                >
                  INSURER A:
                </Text>
              </View>
              <Text style={{ width: "62%", textAlign: "center" }}>
                {carrierInfo[0] ? carrierInfo[0].carrier : ""}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: "1"
              }}
            >
              <Text>{carrierInfo[0] ? carrierInfo[0].carrier_naic : ""}</Text>
            </View>
          </View>
          <View
            style={{
              marginRight: "-10px",
              fontSize: "6.5px",
              borderBottom: "1px solid black",
              flexDirection: "row"
            }}
          >
            <View
              style={{
                width: "70%",
                borderRight: "1.5px solid black",
                marginTop: "-2px",
                flexDirection: "row",
                paddingTop: "8px"
              }}
            >
              <View
                style={{
                  borderRight: "1px solid black",
                  flexDirection: "row",
                  marginTop: "-7px"
                }}
              >
                <Text
                  style={{
                    width: "28%",
                    textAlign: "center",
                    marginTop: "7px"
                  }}
                >
                  INSURER B:
                </Text>
              </View>
              <Text style={{ width: "62%", textAlign: "center" }}>
                {carrierInfo[1] ? carrierInfo[1].carrier : ""}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: "1"
              }}
            >
              <Text>{carrierInfo[1] ? carrierInfo[1].carrier_naic : ""}</Text>
            </View>
          </View>
          <View
            style={{
              marginRight: "-10px",
              fontSize: "6.5px",
              borderBottom: "1px solid black",
              flexDirection: "row"
            }}
          >
            <View
              style={{
                width: "70%",
                borderRight: "1.5px solid black",
                marginTop: "-2px",
                flexDirection: "row",
                paddingTop: "8px"
              }}
            >
              <View
                style={{
                  borderRight: "1px solid black",
                  flexDirection: "row",
                  marginTop: "-7px"
                }}
              >
                <Text
                  style={{
                    width: "28%",
                    textAlign: "center",
                    marginTop: "7px"
                  }}
                >
                  INSURER C:
                </Text>
              </View>
              <Text style={{ width: "62%", textAlign: "center" }}>
                {carrierInfo[2] ? carrierInfo[2].carrier : ""}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: "1"
              }}
            >
              <Text>{carrierInfo[2] ? carrierInfo[2].carrier_naic : ""}</Text>
            </View>
          </View>
          <View
            style={{
              marginRight: "-10px",
              fontSize: "6.5px",
              borderBottom: "1px solid black",
              flexDirection: "row"
            }}
          >
            <View
              style={{
                width: "70%",
                borderRight: "1.5px solid black",
                marginTop: "-2px",
                flexDirection: "row",
                paddingTop: "8px"
              }}
            >
              <View
                style={{
                  borderRight: "1px solid black",
                  flexDirection: "row",
                  marginTop: "-7px"
                }}
              >
                <Text
                  style={{
                    width: "28%",
                    textAlign: "center",
                    marginTop: "7px"
                  }}
                >
                  INSURER D:
                </Text>
              </View>
              <Text style={{ width: "62%", textAlign: "center" }}>
                {carrierInfo[3] ? carrierInfo[3].carrier : ""}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: "1"
              }}
            >
              <Text>{carrierInfo[3] ? carrierInfo[3].carrier_naic : ""}</Text>
            </View>
          </View>
          <View
            style={{
              marginRight: "-10px",
              fontSize: "6.5px",
              borderBottom: "1px solid black",
              flexDirection: "row"
            }}
          >
            <View
              style={{
                width: "70%",
                borderRight: "1.5px solid black",
                marginTop: "-2px",
                flexDirection: "row",
                paddingTop: "8px"
              }}
            >
              <View
                style={{
                  borderRight: "1px solid black",
                  flexDirection: "row",
                  marginTop: "-7px"
                }}
              >
                <Text
                  style={{
                    width: "28%",
                    textAlign: "center",
                    marginTop: "7px"
                  }}
                >
                  INSURER E:
                </Text>
              </View>
              <Text style={{ width: "62%", textAlign: "center" }}>
                {carrierInfo[4] ? carrierInfo[4].carrier : ""}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: "1"
              }}
            >
              <Text>{carrierInfo[4] ? carrierInfo[4].carrier_naic : ""}</Text>
            </View>
          </View>
          <View
            style={{
              marginRight: "-10px",
              fontSize: "6.5px",
              flexDirection: "row"
            }}
          >
            <View
              style={{
                width: "70%",
                borderRight: "1.5px solid black",
                flexDirection: "row",
                paddingTop: "8px"
              }}
            >
              <View
                style={{
                  borderRight: "1px solid black",
                  flexDirection: "row",
                  marginTop: "-8px"
                }}
              >
                <Text
                  style={{
                    width: "28%",
                    textAlign: "center",
                    marginTop: "7px"
                  }}
                >
                  INSURER F:
                </Text>
              </View>
              <Text style={{ width: "62%", textAlign: "center" }}>
                {carrierInfo[5] ? carrierInfo[5].carrier : ""}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flex: "1"
              }}
            >
              <Text>{carrierInfo[5] ? carrierInfo[5].carrier_naic : ""}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
export default PartOne;
