import { useContext, useState } from 'react';
import { ViewContext, Button, NumberInput, Fieldset, Form, Error } from 'components/lib';
import Axios from 'axios';

const AddCompanyForm = (props) => {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const [loading, setLoading] = useState(false);
  const [validDOT, setValidDOT] = useState(true);
  const [existDOT, setExistDOT] = useState(true);
  const [step, setStep] = useState(true);
  const [ownDOTNumber, setOwnDOTNumber] = useState(false);
  const [dotNumber, setDOTNumber] = useState('');
  const [modalData, setModalData] = useState({
    legalName: '',
    dbaName: '',
    emailAddress: '',
    phyCity: '',
    phyCountry: '',
    phyState: '',
    phyStreet: '',
    phyZip: '',
    powerUnits: '',
    telephone: '',
    totalDrivers: ''
  });
  const addCompanyURL = props.type === 'Company' ? '/api/company/add' : '/api/prospect/add';

  const generateRandomDotNumber = async () => {
    const generatedDOTNumber = '00' + Math.random().toString().slice(2,8);

    try {

      let res = await Axios({

        method: 'POST',
        url: `/api/user/dot?dotNumber=${generatedDOTNumber}`

      });

      
      if (res.data.error === 'Cannot find dot number in database') {
        setDOTNumber(generatedDOTNumber);
        return;
      } else {
        generateRandomDotNumber();
      };

    }
    catch (err){
      setLoading(false);
    }

  };

  async function getDataDOTNumber(){

    setLoading(true);

    if (ownDOTNumber) {
      await generateRandomDotNumber();
      setLoading(false);
      setStep(false);
      return;
    };
  
    if (dotNumber === '' || dotNumber === '0'){

      setLoading(false);
      setValidDOT(false);
      return false;

    };

    try {

      let res = await Axios({

        method: 'POST',
        url: `/api/user/search?dotNumber=${dotNumber}`

      });

      if (res.data === 'The dotNumber exists') {
        setExistDOT(false);
      } else {
        let dotResponse = await Axios({
  
          method: 'POST',
          url: `/api/user/dot?dotNumber=${dotNumber}`
  
        });
  
        if (dotResponse.data.error === 'Cannot find dot number in database') {
          // finish loading
          setLoading(false);
          setStep(false);
          return;
        };
  
        setModalData(dotResponse.data.generalDot);
  
        setStep(false);
      };

      // finish loading
      setLoading(false);

    }
    catch (err){
      setLoading(false);
    }
  };

  const now = new Date();
  const date = {

    year: now.getFullYear(),
    month: now.getMonth()+1,
    day: now.getDate()

  };

  const today = date.year + '/' + date.month + '/' + date.day;

  const data = {
    account_id: {
      type: 'hidden',
      value: user.account_id
    },
    userName: {
      type: 'hidden',
      value: user.name
    },
    user_id: {
      type: 'hidden',
      value: user.accounts[0].user_id
    },
    editedField: {
      type: 'hidden',
      value: `Company ${modalData.legalName} Created`
    },
    dotNumber: {
      type: 'hidden',
      value: dotNumber
    },
    clientSince: {
      type: 'hidden',
      value: today 
    },
    address: {
      type: 'hidden',
      value: modalData.phyStreet 
    },
    phyCity: {
      type: 'hidden',
      value: modalData.phyCity 
    },
    phyState: {
      type: 'hidden',
      value: modalData.phyState 
    },
    phyZip: {
      type: 'hidden',
      value: modalData.phyZip 
    },
    legalName: {
      id: 'legalName',
      label: 'Company Name',
      value: modalData.legalName,
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: true,
    },
    dbaName: {
      id: 'dbaName',
      label: 'DBA Name',
      value: modalData.dbaName,
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]'
    },
    companyRep1: {
      id: 'companyRep1',
      label: 'Owner Name',
      value: modalData.dbaName,
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: props.type === 'Company' ? true : false,
    },
    telephone: {
      for: 'telephone',
      label: 'Phone',
      value: modalData.telephone,
      type: 'phone',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: props.type === 'Company' ? true : false,
    },
    emailAddress: {
      id: 'emailAddress',
      label: 'Email',
      value: modalData.emailAddress,
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: props.type === 'Company' ? true : false,
    },
    status: {
      type: 'hidden',
      value: 'active'
    }
  };

  return (
    <>
      {step ?
        <form className='flex flex-wrap justify-between'>
          <NumberInput
            id='dotNumber'
            name= 'dotNumber'
            label= 'DOT Number'
            value= { dotNumber }
            className= 'w-full'
            containerClass= 'w-full'
            valid= { validDOT }
            onChange= { (name, value) => setDOTNumber(value) }
            readonly={ ownDOTNumber }
          />

          { existDOT === false &&
            <Error message={ 'DOT Number already exists' }/> }

          <Fieldset
            id='ownDOTNumber'
            options={['No DOT #']}
            type='checkbox'
            class='w-full font-bold'
            className='w-full text-lg font-bold mt-4 whitespace-nowrap'
            onChange= { e => setOwnDOTNumber(!ownDOTNumber) }
          />
          <div className='flex justify-end w-full mt-4'>
            <Button 
              color={ props.destructive ? 'green' : 'red' } 
              outline 
              text='Cancel' 
              className='w-full inline-block mr-[2%] last:mr-0'
              action={ props.cancel } 
            />
    
            <Button
              color={ props.destructive ? 'red' : 'bluesign' }
              loading={ loading }
              text='Next'
              action={ getDataDOTNumber }
              className='w-full inline-block mr-[2%] last:mr-0'
            />
          </div>
        </form>
      :
        <Form
          method='POST'
          url= { addCompanyURL }
          data={ data }
          buttonText='Save'
          buttonClass='flex justify-end w-full mt-4'
          className='flex flex-wrap justify-between'
          cancel={ e => context.modal.hide(true) }
          succesText= { true }
        />
      }
    </>
  )

};

export default AddCompanyForm;
