import { Text, View, Image } from "@react-pdf/renderer";
import Logo from "./logo/acordlogo.png";
function Header() {
  function getCurrentDate() {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  }
  
  const currentDate = getCurrentDate();

  return (
    <View
      style={{
        width: "95%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#000000",
        marginTop: "15px"
      }}
    >
      <Image src={Logo} alt="logo" style={{ width: "10%" }} />
      <Text style={{ fontFamily: "Helvetica-Bold" }}>
        CERTIFICATE OF LIABILITY INSURANCE
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "9px",
          alignItems: "center",
          borderTop: "1.5px solid black",
          paddingHorizontal: "12px",
          alignSelf: "flex-end",
          paddingTop: "3px",
          borderRight: "1.5px solid black",
          borderLeft: "1.5px solid black"
        }}
      >
        <Text>Date {currentDate}</Text>
      </View>
    </View>
  );
}
export default Header;
