import { useState } from "react";
import { Button, Card, Search, useAPI } from "components/lib";

const CompanySearch = () => {
  const [filter, setFilter] = useState([]);
  const companyData = useAPI(`/api/user/getCompaniesWithSubmission`);

  function search(term) {
    let rowsToShow = [];

    Object.values(companyData.data).forEach((company) => {
      if (
        company.submissionNumbers
          .join(", ")
          ?.toString()
          .toLowerCase()
          .includes(term.toLowerCase())
      ) {
        rowsToShow.push(company);
      }
    });

    setFilter(rowsToShow);
  }

  const saveCompanyLocalStorage = (company) => {
    const saveCompany = {
      "dot_#": company.dotNumber,
      company_name: company.legalName,
      owner_name: company.companyRep1,
      phone: company.telephone,
      email: company.emailAddress,
      type: "customer",
    };
    localStorage.setItem("company", JSON.stringify(saveCompany));
  };

  return (
    <Card>
      {companyData.data && <Search className="" callback={search} />}
      {filter.length > 0 ? (
        <div className="mt-6">
          <div className="flex justify-between outline-0 capitalize font-semibold p-5 pt-0 border-b border-dotted border-slate-300">
            <p className="w-1/12 text-left">DOT_#</p>
            <p className="w-3/12 text-center">company name</p>
            <p className="w-3/12 text-center">owner name</p>
            <p className="w-3/12 text-center">Submission Number</p>
            <span className="w-2/12" />
          </div>

          <div>
            {filter.map((company) => (
              <div
                className="w-full flex justify-between items-center p-5 border-b border-solid border-slate-100"
                key={company.dotNumber}
              >
                <p className="w-1/12 text-left">{company.dotNumber}</p>
                <p className="w-3/12 text-center">{company.legalName}</p>
                <p className="w-3/12 text-center">{company.companyRep1}</p>
                <p className="w-3/12 text-center">
                  {company.submissionNumbers.join(", ")}
                </p>
                <Button
                  text="View Company"
                  small
                  className="w-2/12 text-white bg-blue-700 rounded"
                  icon="arrow-right"
                  alignRight
                  goto={"/customers/company"}
                  action={() => saveCompanyLocalStorage(company)}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className="w-full mb-2 p-5 border-b border-solid border-slate-100">
          {companyData.data
            ? `Search a company by submission number. There ${
                Object.keys(companyData.data)?.length === 1
                  ? "is 1 company"
                  : `are ${Object.keys(companyData.data).length} companies`
              }`
            : "Loading Companies"}
        </p>
      )}
    </Card>
  );
};

export default CompanySearch;
