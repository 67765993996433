/***
 *
 *   MODAL
 *   Display an overlay modal anywhere in your application by calling
 *   context.modal.show() with an object containing the following props
 *
 *   PROPS
 *   title: (optional)
 *   text: message to the user (optional)
 *   form: a form object (optional: see form docs for more information)
 *   url: destination to send the form
 *   method: HTTP post type
 *   buttonText – text for the confirm button
 *
 **********/

import { useContext } from "react";
import { ViewContext, Card, Form, Link } from "components/lib";
import { CSSTransition } from "react-transition-group";
import "./modal.scss";
import VehiclesForm from "components/form/vehicles/vehiclesForm";
import AddCompanyForm from "components/form/company/addCompany";
import AddVehicleForm from "components/form/vehicles/addVehicle";
import RequestForm from "components/form/request/request";
import AddVehicleRunQuote from "components/form/vehicles/addVechicleRunQuote";
import AddTrailerRunQuote from "components/form/vehicles/addTrailerRunQuote";
import AddDriverRunQuote from "components/form/drivers/addDriverRunQuote";
import ModalRunQuote from "components/form/quote/modalRunQuote";
import EditTrailer from "components/form/vehicles/editTrailer";
import AddTrailerForm from "components/form/vehicles/addTrailer";
import AddCertificateModal from "components/form/certificate/addCertificateModal";
import RefreshCertificateModal from "components/form/certificate/refreshCertificateModal";
import AddDocumentForm from "components/form/document/addDocument";

export function Modal(props) {
  const context = useContext(ViewContext);

  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        onClick={(e) =>
          e.target === e.currentTarget && context.modal.hide(true)
        }
      >
        <div className="modal-content">
          <Card title={props.title}>
            {props.text && <p className="text-center text-lg">{props.text}</p>}

            {props.secondText && (
              <p className="text-center font-bold">{props.secondText}</p>
            )}

            {props.imgLinks && (
              <div className="flex justify-around">
                {props.imgLinks.map(
                  (link) =>
                    link.url && (
                      <Link
                        url={link.url}
                        text={link.text}
                        className={link.linkClassName}
                        key={link.url}
                      />
                    )
                )}
              </div>
            )}

            {props.form && (
              <Form
                method={props.method}
                url={props.url}
                data={props.form}
                destructive={props.destructive}
                buttonText={props.buttonText}
                buttonClass={props.buttonClass}
                className={props.className}
                cancel={(e) => context.modal.hide(true)}
              />
            )}

            {props.addCompany && (
              <AddCompanyForm
                cancel={(e) => context.modal.hide(true)}
                type={props.addCompany}
              />
            )}

            {props.addVehicle && (
              <AddVehicleForm cancel={(e) => context.modal.hide(true)} />
            )}

            {props.addTrailer && (
              <AddTrailerForm cancel={(e) => context.modal.hide(true)} />
            )}

            {props.addVehicleRunQuote && (
              <AddVehicleRunQuote
                cancel={(e) => context.modal.hide(true)}
                progressive={props.progressive}
              />
            )}

            {props.addTrailerRunQuote && (
              <AddTrailerRunQuote
                cancel={(e) => context.modal.hide(true)}
                progressive={props.progressive}
              />
            )}

            {props.addDriverRunQuote && (
              <AddDriverRunQuote
                cancel={(e) => context.modal.hide(true)}
                progressive={props.progressive}
              />
            )}

            {props.vehiclesForm && (
              <VehiclesForm data={props.vehiclesForm.data} />
            )}

            {props.editTrailer && <EditTrailer data={props.editTrailer.data} />}

            {props.requestForm && (
              <RequestForm
                data={props.requestForm.data}
                requestsData={props.requestForm.requestsData}
                setRequestsData={props.requestForm.setRequestsData}
              />
            )}

            {props.modalRunQuote && (
              <ModalRunQuote clientType={props.clientType} />
            )}

            {props.addCertificateModal && <AddCertificateModal submissionsNumbers={props.addCertificateModal.submissionsNumbers} />}
            
            {props.refreshCertificateModal && <RefreshCertificateModal data={props.refreshCertificateModal.data} />}

            {props.addDocument && <AddDocumentForm url={props.addDocument.url} client={props.addDocument.client} />}

          </Card>
        </div>
      </div>
    </CSSTransition>
  );
}
