import { useContext, useState } from 'react';
import { ViewContext, Button, Form, TextInput } from 'components/lib';
import Axios from 'axios';

const AddVehicleRunQuote = (props) => {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem('company'));
  const [loading, setLoading] = useState(false);
  const [validVIN, setValidVIN] = useState(true);
  const [VIN, setVIN] = useState('');
  const classOptions = [
    {"value": "1", "label": "Class 1: 0–6,000 pounds (0–2,722 kg)"},
    {"value": "2a", "label": "Class 2a: 6,001–8,500 pounds (2,722–3,856 kg)"},
    {"value": "2b", "label": "Class 2b: 8,501–10,000 pounds (3,856–4,536 kg)"},
    {"value": "3", "label": "Class 3: 10,001–14,000 pounds (4,536–6,350 kg)"},
    {"value": "4", "label": "Class 4: 14,001–16,000 pounds (6,351–7,257 kg)"},
    {"value": "5", "label": "Class 5: 16,001–19,500 pounds (7,258–8,845 kg)"},
    {"value": "6", "label": "Class 6 : 19,501–26,000 pounds (8,846–11,793 kg)"},
    {"value": "7", "label": "Class 7: 26,001–33,000 pounds (11,794–14,969 kg)"},
    {"value": "8", "label": "Class 8: 33,001 pounds (14,969 kg) +"}
  ];
  const bodyTypeOptions = [
    {"value": "boom_truck", "label": "Boom Truck"},
    {"value": "box_truck", "label": "Box Truck"},
    {"value": "bucket_truck", "label": "Bucket Truck"},
    {"value": "bus", "label": "Bus"},
    {"value": "catering_truck", "label": "Catering Truck"},
    {"value": "cement_truck", "label": "Cement Truck"},
    {"value": "delivery_truck", "label": "Delivery Truck"},
    {"value": "delivery_van", "label": "Delivery Van"},
    {"value": "dump_truck", "label": "Dump Truck"},
    {"value": "fire_truck", "label": "Fire Truck"},
    {"value": "flatbed_tow_truck", "label": "Flatbed Tow Truck"},
    {"value": "flatbed_truck", "label": "Flatbed Truck"},
    {"value": "food_truck", "label": "Food Truck"},
    {"value": "fuel_tank_truck", "label": "Fuel Tank Truck"},
    {"value": "garbage_truck", "label": "Garbage Truck"},
    {"value": "landscape_truck", "label": "Landscape Truck"},
    {"value": "logging_truck", "label": "Logging Truck"},
    {"value": "milk_tanker_truck", "label": "Milk Tanker Truck"},
    {"value": "mini_van", "label": "Mini Van"},
    {"value": "moving_truck", "label": "Moving Truck"},
    {"value": "pick_up", "label": "Pickup"},
    {"value": "on_hook_tow_truck", "label": "On Hook Tow Truck"},
    {"value": "rack_truck", "label": "Rack Truck"},
    {"value": "snow_plow_truck", "label": "Snow Plow Truck"},
    {"value": "stake_truck", "label": "Stake Truck"},
    {"value": "step_van", "label": "Step Van"},
    {"value": "suv", "label": "SUV"},
    {"value": "tanker_truck", "label": "Tanker Truck"},
    {"value": "tractor", "label": "Tractor"},
    {"value": "utility_van", "label": "Utility Van"}
  ];
  const [modalData, setModalData] = useState({
    userName: {
      type: 'hidden',
      value: user.name
    },
    user_id: {
      type: 'hidden',
      value: user.accounts[0].user_id
    },
    client: {
      type: 'hidden',
      value: company.type || 'customer'
    },
    editedField: {
      type: 'hidden',
      value: `Vehicle ${VIN} Created`
    },
    dotNumber: {
      type: 'hidden',
      value: company['dot_#'],
    },
    vin: {
      type: 'hidden',
      value: VIN
    },
    typeOfVehicle: {
      type: 'hidden',
      value: 'vehicle'
    },
    year: {
      id: 'year',
      label: 'Year',
      type: 'number',
      class: 'w-full',
      containerClass: 'w-[32%]',
      required: true,
    },
    make: {
      id: 'make',
      label: 'Make of manufacturer',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[32%]',
      required: true,
    },
    model: {
      id: 'model',
      label: 'Model',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[32%]',
      required: true,
    },
    value: {
      for: 'value',
      label: 'Value',
      type: 'money',
      class: 'w-full',
      containerClass: 'w-full',
      required: true,
    },
    classKey: {
      id: 'classKey',
      label: 'Class',
      type: 'select',
      options: classOptions,
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: true,
    },
    bodyTypeKey: {
      id: 'bodyTypeKey',
      label: 'Body type',
      type: 'select',
      options: bodyTypeOptions,
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: true,
    },
    vehicleZip: {
      id: 'vehicleZip',
      label: 'ZIP code where the vehicle is located (Progressive Only)',
      type: props.progressive ? 'text' : null,
      class: 'w-full',
      containerClass: 'w-full',
      required: props.progressive ? true : false,
    },
    farthestDistance: {
      id: 'farthestDistance',
      label: 'Farthest one-way distance this vehicle typically travels (Progressive Only)',
      type: props.progressive ? 'select' : null,
      options: [
        { value: 'option1', label: 'option1'},
        { value: 'option2', label: 'option2'},
        { value: 'option3', label: 'option3'},
        { value: 'option4', label: 'option4'}
      ],
      class: 'w-full',
      containerClass: 'w-full',
    },
    loanVehicle: {
      id: 'loanVehicle',
      label: 'Is there a loan/lease on this vehicle? (Progressive Only)',
      type: props.progressive ? 'radio' : null,
      options: ['Yes - Loan', 'Yes - Lease', 'No'],
      cols: 1,
      class: 'w-full',
      containerClass: 'w-full',
    },
    collisionCoverage: {
      id: 'collisionCoverage',
      label: 'Does the customer need comprehensive or collision coverage to protect this vehicle in an accident or loss? (Progressive Only)',
      type: props.progressive ? 'switch' : null,
      labelRight: true,
      class: 'w-full',
      containerClass: 'w-full',
    },
    deductibleType: {
      type: 'hidden',
      value: 'FollowsPolicy',
    },
    deductible: {
      type: 'hidden',
      value: '5000',
    },
    status: {
      type: 'hidden',
      value: 'active'
    }
  });
  const addVehicleURL = '/api/vehicle/salesAdd';

  async function getDataVIN(){

    setLoading(true);
  
    if (VIN === '' || VIN === '0'){

      setLoading(false);
      setValidVIN(false)
      return false;

    };

    try {

      let res = await Axios.get(

        `https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${VIN}?format=json`

      );

      if (res.data.error) {
        setLoading(false);
        return;
      };

      const year = res.data.Results?.filter(item => item.Variable === 'Model Year')[0].Value || '';
      const make = res.data.Results?.filter(item => item.Variable === 'Make')[0].Value || '';
      const model = res.data.Results?.filter(item => item.Variable === 'Model')[0].Value || '';
      
      const regex = /Class (\d+[a-zA-Z]?):/;
      let classKey = '';
      const classValue = res.data.Results?.filter(item => item.Variable === 'Gross Vehicle Weight Rating From')?.[0]?.Value?.match(regex);
      if (classValue) {
        classKey = classValue[1];
      }

      setModalData((prevState)=> {
        return({
          ...prevState,
          vin: {
            type: 'hidden',
            value: VIN
          },
          year: {
            id: 'year',
            label: 'Year',
            type: 'number',
            class: 'w-full',
            containerClass: 'w-[32%]',
            required: true,
            value: year
          },
          make: {
            id: 'make',
            label: 'Make of manufacturer',
            type: 'text',
            class: 'w-full',
            containerClass: 'w-[32%]',
            required: true,
            value: make
          },
          model: {
            id: 'model',
            label: 'Model',
            type: 'text',
            class: 'w-full',
            containerClass: 'w-[32%]',
            required: true,
            value: model
          },
          classKey: {
            id: 'classKey',
            label: 'Class',
            type: 'select',
            options: classOptions,
            class: 'w-full',
            containerClass: 'w-[49%]',
            required: true,
            value: classKey
          },
        });
      });

      // finish loading
      setLoading(false);

    }
    catch (err){
      setLoading(false);
    }
  };

  const handleVehicleChange = (inputName) => {
    setModalData((prevState)=> {
      return({
        ...prevState,
        vin: {
          type: 'hidden',
          value: VIN
        },
        [inputName.input]: {
          ...prevState[inputName.input],
          value: inputName.value
        },
      });
    });
  };

  return (
    <>
      <div className='flex justify-between'>
        <TextInput
          id='vin'
          name= 'vin'
          label= 'Vehicle identification number (VIN)'
          value= { VIN }
          className= 'w-full'
          containerClass= 'w-[75%]'
          valid= { validVIN }
          onChange= { (name, value) => setVIN(value) }
        />
        <Button
          color='bluesign'
          small
          className='mt-7'
          text='Add Vehicle'
          action={ getDataVIN }
          loading={ loading }
        />
      </div>
      <Form
        data={ modalData }
        url={ addVehicleURL }
        method='POST'
        buttonText='Save'
        buttonClass='flex justify-end w-full mt-4'
        className='flex flex-wrap justify-between'
        cancel={ e => context.modal.hide(true) }
        updateOnChange
        onChange={ handleVehicleChange }
        callback={ res => {
          context.modal.hide(false, res);
        }}
      />
    </>
  )

};

export default AddVehicleRunQuote;
