/***
 *
 *   Run Quote page
 *
 **********/

import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  DateInput,
  EmailInput,
  Fieldset,
  NumberInput,
  PhoneInput,
  Select,
  Switch,
  TextInput,
  Card,
  ViewContext,
  useAPI,
  SearchSelect,
  Loader,
} from "components/lib";
import { MoneyInput } from "components/form/input/money";
import usStates from "../data/short-us-states.json";
import longUsStates from "../data/us-states.json";
import axios from "axios";
import SelectReact from "react-select";

const progressive = false;

export function RunQuote(props) {
  const location = useLocation();
  const { state } = location;
  const context = useContext(ViewContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [emptyInput, setEmptyInput] = useState("");
  const [data, setData] = useState({
    commodityArray: [],
    vehicles: [],
    trailers: [],
    drivers: [],
  });
  const page = state?.data;

  const user = JSON.parse(localStorage.getItem("user"));
  const company = JSON.parse(localStorage.getItem("company"));

  const companyData = useAPI(
    `/api/${
      company.type === "customer" ? "company" : "prospect"
    }/getAllInfo?dotNumber=${company["dot_#"]}`
  );

  const onChange = (name, value) => {
    if (name !== "sameBusinessAddress") {
      setData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
      return;
    }
    if (value.length) {
      const copyData = { ...data };
      setData((prevState) => {
        return {
          ...prevState,
          [name]: value,
          garageStreet: copyData.mailingStreet,
          garageStreet2: copyData.mailingStreet2,
          garageCity: copyData.mailingCity,
          garageState: copyData.mailingState,
          garageZip: copyData.mailingZip,
        };
      });
    } else {
      setData((prevState) => {
        return {
          ...prevState,
          [name]: value,
          garageStreet: "",
          garageStreet2: "",
          garageCity: "",
          garageState: "",
          garageZip: "",
        };
      });
    }
  };

  useEffect(() => {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${month}/${day}/${year}`;

    const retailAgent = {
      label: "Joe Agentzzz",
      value: {
        FirstName: "Joe",
        LastName: "Agentzzz",
        Phone: "5555555555",
        Email: "Joe@mail.com",
        AgencyName: "Trucking",
        Street: "10 Main Street",
        City: "Chicago",
        State: "IL",
        Zip: "90201",
      },
    };

    function transformUserObject(originalUser) {
      const transformedUser = {
        label:
          (originalUser?.FirstName ? " " + originalUser.FirstName : "") +
          (originalUser?.LastName ? " " + originalUser.LastName : ""),
        value: {
          FirstName: originalUser.FirstName || "",
          LastName: originalUser.LastName || "",
          Phone: originalUser.Phone || "",
          Email: originalUser.Email || "",
          AgencyName: originalUser.AgencyName || "Trucking",
          Street: originalUser.Street || "",
          City: originalUser.City || "",
          State: originalUser.State || "",
          Zip: originalUser.Zip || "",
        },
      };

      return transformedUser;
    }

    function sumValues(arr) {
      if (!arr || arr.length === 0) {
        return 0;
      }
      return arr.reduce((acc, curr) => {
        const value = parseFloat(curr.value) || 0;
        return acc + value;
      }, 0);
    }

    if (page === "CW_Quote") {
      if (companyData.data?.Companies) {
        setData({
          ownerName: companyData.data?.Companies.companyRep1,
          dbaName: companyData.data?.Companies.dbaName,
          dotNumber: companyData.data?.Companies.dotNumber,
          emailAddress: companyData.data?.Companies.emailAddress,
          legalName: companyData.data?.Companies.legalName,
          phone: companyData.data?.Companies.telephone || "",
          effectiveDate: formattedDate,
          insuranceContactFirstName:
            companyData.data?.Companies.companyRep1.split(" ")[0],
          insuranceContactLastName:
            companyData.data?.Companies.companyRep1.split(" ")[1],
          insuranceContactEmail: companyData.data?.Companies.emailAddress,
          insuranceContactPhone: companyData.data?.Companies.telephone || "",
          mailingStreet: companyData.data?.Companies.address,
          mailingStreet2: companyData.data?.Companies.address2,
          mailingCity: companyData.data?.Companies.phyCity,
          mailingState: companyData.data?.Companies.phyState,
          mailingZip: companyData.data?.Companies.phyZip,
          commodityArray: [
            {
              commodityKey: "",
              commodityPercentage: "",
              commodityId: 1,
            },
          ],
          nbrOfTrucks: companyData.data?.Companies?.vehicles?.length || "0",
          valueOfTrucks: sumValues(companyData.data?.Companies?.vehicles),
          nbrOfTrailers: companyData.data?.Companies?.trailers?.length || "0",
          valueOfTrailers: sumValues(companyData.data?.Companies?.trailers),
          trailers: companyData.data?.Companies.trailers || [],
          vehicles: companyData.data?.Companies.vehicles || [],
          drivers: companyData.data?.Companies.drivers || [],
          producer: retailAgent,
          yearsInBusiness: companyData.data?.Companies.yearsInBusiness,
        });
      }

      if (companyData.data?.Prospects) {
        setData({
          ownerName: companyData.data?.Prospects.companyRep1,
          dbaName: companyData.data?.Prospects.dbaName,
          dotNumber: companyData.data?.Prospects.dotNumber,
          emailAddress: companyData.data?.Prospects.emailAddress,
          legalName: companyData.data?.Prospects.legalName,
          phone: companyData.data?.Prospects.telephone || "",
          effectiveDate: formattedDate,
          insuranceContactFirstName: companyData.data?.Prospects.companyRep1
            ? companyData.data.Prospects.companyRep1.split(" ")[0]
            : "",
          insuranceContactLastName: companyData.data?.Prospects.companyRep1
            ? companyData.data?.Prospects.companyRep1.split(" ")[1]
            : "",
          insuranceContactEmail: companyData.data?.Prospects.emailAddress,
          insuranceContactPhone: companyData.data?.Prospects.telephone || "",
          mailingStreet: companyData.data?.Prospects.address,
          mailingStreet2: companyData.data?.Prospects.address2,
          mailingCity: companyData.data?.Prospects.phyCity,
          mailingState: companyData.data?.Prospects.phyState,
          mailingZip: companyData.data?.Prospects.phyZip,
          commodityArray: [
            {
              commodityKey: "",
              commodityPercentage: "",
              commodityId: 1,
            },
          ],
          nbrOfTrucks: companyData.data?.Prospects?.vehicles?.length || "0",
          valueOfTrucks: sumValues(companyData.data?.Prospects?.vehicles),
          nbrOfTrailers: companyData.data?.Prospects?.trailers?.length || "0",
          valueOfTrailers: sumValues(companyData.data?.Prospects?.trailers),
          trailers: companyData.data?.Prospects.trailers || [],
          vehicles: companyData.data?.Prospects.vehicles || [],
          drivers: companyData.data?.Prospects.drivers || [],
          producer: retailAgent,
          yearsInBusiness: companyData.data?.Prospects.yearsInBusiness,
        });
      }
    } else if (page?.myQuote) {
      if (!page.myQuote.commodityArray.length) {
        page.myQuote.commodityArray.push({
          commodityKey: "",
          commodityPercentage: "",
          commodityId: 1,
        });
      }
      setData({
        ...page.myQuote,
        effectiveDate: formattedDate,
        optAlPip:
          page.myQuote.optAlPip === "Y"
            ? ["Personal Injury Protection (PIP)"]
            : [],
        optAlUm:
          page.myQuote.optAlUm === "Y"
            ? ["Uninsured Motorists/Property Damage"]
            : [],
        requestAl:
          page.myQuote.requestAl === "Y" ? ["Auto Liability (AL)"] : [],
        producer: transformUserObject(page.myQuote.producer),
        commodityArray: page.myQuote.commodityArray,
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [companyData, page]);

  const formatDate = (originalDate) => {
    const date = new Date(originalDate);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const handleSubmit = async () => {
    try {
      setEmptyInput("");
      setsubmitLoading(true);
      const formattedDrivers = data.drivers.map((driver) => {
        const formattedDriver = {
          firstName: "",
          lastName: "",
          licenseState: "",
          licenseNumber: "",
          dateOfBirth: "",
          dateOfHire: "",
          yearsExperience: "",
          eligibility: "Covered",
          accidents_total: "0",
          violations_total: "0",
          suspensions_total: "0",
        };

        if (driver.fullName) {
          const [firstName, lastName] = driver.fullName.split(" ");
          formattedDriver.firstName = firstName;
          formattedDriver.lastName = lastName || "lastName";
        } else {
          formattedDriver.firstName = driver.firstName || "";
          formattedDriver.lastName = driver.lastName || "lastName";
        }

        const getLicenseState = longUsStates.find(
          (state) => state.value === driver.licenseState
        );

        if (getLicenseState) {
          formattedDriver.licenseState = getLicenseState.label;
        }

        formattedDriver.licenseNumber = driver.licenseNumber || "";
        formattedDriver.dateOfBirth = driver.dateOfBirth
          ? formatDate(driver.dateOfBirth)
          : "";
        formattedDriver.dateOfHire = driver.dateOfHire
          ? formatDate(driver.dateOfHire)
          : "";
        formattedDriver.yearsExperience = driver.yearsExperience || "";

        if (driver.accidents_total !== undefined) {
          formattedDriver.accidents_total = driver.accidents_total;
        }

        if (driver.violations_total !== undefined) {
          formattedDriver.violations_total = driver.violations_total;
        }

        if (driver.suspensions_total !== undefined) {
          formattedDriver.suspensions_total = driver.suspensions_total;
        }

        return formattedDriver;
      });

      const commoditiesCopy = data.commodityArray.map((commodity) => {
        const { commodityId, ...rest } = commodity;
        return rest;
      });

      const formData = {
        dotNumber: company["dot_#"],
        user_id: user.accounts[0].user_id,
        userName: user.name,
        editedField: `Quote Submited`,
        client: company.type,
        coverage: {
          requestAl: data.requestAl?.length > 0 ? "Y" : "N",
          optAlPip: data.optAlPip?.length > 0 ? "Y" : "N",
          optAlUm: data.optAlUm?.length > 0 ? "Y" : "N",
          requestApd: data.generalLiability?.includes(
            "Automobile Physical Damage (APD)"
          )
            ? "Y"
            : "N",
          requestMtc: data.generalLiability?.includes("Motor Truck Cargo (MTC)")
            ? "Y"
            : "N",
          requestTgl: data.generalLiability?.includes(
            "Truckers General Liability (TGL)"
          )
            ? "Y"
            : "N",
          requestNtl: data.generalLiability?.includes(
            "Non-Trucking Liability (NTL)"
          )
            ? "Y"
            : "N",
          effectiveDate: data.effectiveDate,
        },
        insuredInformation: {
          ownerName: data.ownerName,
          dotNumber: company["dot_#"],
          email: data.insuranceContactEmail,
          legalName: data.legalName,
          dbaName: data.dbaName?.length ? data.dbaName : " ",
          yearsInBusiness: data.yearsInBusiness,
          monthsInBusiness:
            data.monthsInBusiness?.length > 0 ? data.monthsInBusiness : "0",
          insuranceContactFirstName: data.insuranceContactFirstName,
          insuranceContactLastName: data.insuranceContactLastName,
          insuranceContactPhone: data.insuranceContactPhone,
          insuranceContactEmail: data.insuranceContactEmail,
        },
        limits: {
          trailerInterchange:
            data.trailerInterchangeLimit !== "0" &&
            data.trailerInterchangeLimit !== undefined
              ? "Y"
              : "N",
          trailerInterchangeLimit:
            data.trailerInterchangeLimit !== "0" &&
            data.trailerInterchangeLimit !== undefined
              ? data.trailerInterchangeLimit
              : "0",
          limitTowingStorage: data.limitTowingStorage,
          nbrOfTrucks: data.nbrOfTrucks,
          valueOfTrucks: data.valueOfTrucks,
          nbrOfTrailers: data.nbrOfTrailers,
          valueOfTrailers: data.valueOfTrailers,
          limitAutoLiability: data.limitAutoLiability,
          mtcLimit: data.mtcLimit,
        },
        operations: {
          opsLocal: data.operationDescription?.includes("Local trucker")
            ? "Y"
            : "N",
          opsIntermediate: data.operationDescription?.includes("Intermediate")
            ? "Y"
            : "N",
          opsLongHaul: data.operationDescription?.includes("Long haul trucker")
            ? "Y"
            : "N",
          opsIntermodalPort: data.operationDescription?.includes(
            "Intermodal or port"
          )
            ? "Y"
            : "N",
          opsDumpTruckOther: data.operationDescription?.includes(
            "Dump trucks (Other)"
          )
            ? "Y"
            : "N",
          opsDumpTruckSandGravel: data.operationDescription?.includes(
            "Dump trucks (sand and gravel)"
          )
            ? "Y"
            : "N",
          opsEndDumper: data.operationDescription?.includes("End dumper units")
            ? "Y"
            : "N",
          opsLogging: data.operationDescription?.includes("Logging")
            ? "Y"
            : "N",
          opsRefrigirated: data.operationDescription?.includes(
            "refrigerated trailer"
          )
            ? "Y"
            : "N",
          opsHotShot: data.operationDescription?.includes(
            "Hot shot (TGL and NTL not available for  class 3 vehicles)"
          )
            ? "Y"
            : "N",
          opsOversizedOvernight: data.operationDescription?.includes(
            "Oversized or overweight"
          )
            ? "Y"
            : "N",
          opsAutomobileHauler: data.operationDescription?.includes(
            "Automobile hauler [Excluded for cargo and NTL]"
          )
            ? "Y"
            : "N",
          opsHouseholdGoods: data.operationDescription?.includes(
            "Household goods mover"
          )
            ? "Y"
            : "N",
          opsTanker: data.operationDescription?.includes("Tankers") ? "Y" : "N",
          UIIAintermodal: data.operationDescription?.includes(
            "UIIA - Intermodal"
          )
            ? "Y"
            : "N",
          ineligibleOperations: data.ineligibleOperations ? "Y" : "N",
          filingsAlFederal: data.filingsAlFederal ? "Y" : "N",
          filingsAlState: data.filingsAlState ? "Y" : "N",
          priorInsuranceCancelledNonrenewed:
            data.priorInsuranceCancelledNonrenewed ? "Y" : "N",
        },
        garageAddress: {
          garageStreet: data.garageStreet,
          garageCity: data.garageCity,
          garageState: data.garageState,
          garageZip: data.garageZip,
          garageCounty: "string",
          garageCountry: "string",
        },
        mailingAddress: {
          mailingStreet: data.mailingStreet,
          mailingCity: data.mailingCity,
          mailingState: data.mailingState,
          mailingZip: data.mailingZip,
          mailingCounty: "string",
          mailingCountry: "string",
        },
        shippingAddress: {
          shippingStreet: data.mailingStreet,
          shippingCity: data.mailingCity,
          shippingState: data.mailingState,
          shippingZip: data.mailingZip,
          shippingCounty: "string",
          shippingCountry: "string",
        },
        radius: {
          radius0_50: data.radius0_50?.length ? data.radius0_50 : "0",
          radius51_200: data.radius51_200?.length ? data.radius51_200 : "0",
          radius201_500: data.radius201_500?.length ? data.radius201_500 : "0",
          radius501: data.radius501?.length ? data.radius501 : "0",
        },
        commoditiesRefrigeration: data.commoditiesRefrigeration ? "Y" : "N",
        commodities: commoditiesCopy,
        vehicles: data.vehicles,
        trailers: data.trailers,
        drivers: formattedDrivers,
        losses: {
          1: {
            lossConfirmed: "Y",
            lossAlCount: data.lossAlCount23,
            lossAlPaid: data.lossAlPaid23,
            lossApdCount: data.lossApdCount23,
            lossApdPaid: data.lossApdPaid23,
            lossMtcCount: data.lossMtcCount23,
            lossMtcPaid: data.lossMtcPaid23,
            lossTglCount: data.lossTglCount23,
            lossTglPaid: data.lossTglPaid23,
            lossNtlCount: data.lossNtlCount23,
            lossNtlPaid: data.lossNtlPaid23,
          },
          2: {
            lossConfirmed: "Y",
            lossAlCount: data.lossAlCount22,
            lossAlPaid: data.lossAlPaid22,
            lossApdCount: data.lossApdCount22,
            lossApdPaid: data.lossApdPaid22,
            lossMtcCount: data.lossMtcCount22,
            lossMtcPaid: data.lossMtcPaid22,
            lossTglCount: data.lossTglCount22,
            lossTglPaid: data.lossTglPaid22,
            lossNtlCount: data.lossNtlCount22,
            lossNtlPaid: data.lossNtlPaid22,
          },
          3: {
            lossConfirmed: "Y",
            lossAlCount: data.lossAlCount21,
            lossAlPaid: data.lossAlPaid21,
            lossApdCount: data.lossApdCount21,
            lossApdPaid: data.lossApdPaid21,
            lossMtcCount: data.lossMtcCount21,
            lossMtcPaid: data.lossMtcPaid21,
            lossTglCount: data.lossTglCount21,
            lossTglPaid: data.lossTglPaid21,
            lossNtlCount: data.lossNtlCount21,
            lossNtlPaid: data.lossNtlPaid21,
          },
        },
        retailAgent: {
          FirstName: data.producer.value.FirstName,
          LastName: data.producer.value.LastName,
          Phone: data.producer.value?.Phone || "",
          Email: data.producer.value.Email,
          AgencyName: data.producer.value.AgencyName,
          Street: data.producer.value.Street,
          City: data.producer.value.City,
          State: data.producer.value.State,
          Zip: data.producer.value.Zip,
        },
      };

      // Check that all values are not empty in formData
      const emptyFields = [];
      Object.entries(formData).forEach(([key, value]) => {
        if (typeof value === "object") {
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            if (innerValue === "" || innerValue === undefined) {
              emptyFields.push(`${key}.${innerKey}`);
            }
          });
        } else if (value === "" || value === undefined) {
          emptyFields.push(key);
        }
      });
      if (emptyFields.length > 0) {
        const modifiedText = emptyFields
          .join(", ")
          .replace(
            /(insuredInformation\.|limits\.|garageAddress\.|mailingAddress\.|shippingAddress\.)/g,
            ""
          );
        setEmptyInput(`The following fields are empty: ${modifiedText}`);
        setsubmitLoading(false);
        return;
      }

      const response = await axios.post("/api/company/quote", formData);

      if (response.data?.error) {
        function extractText(response) {
          const textArray = [];

          function extractTextRecursive(obj) {
            if (typeof obj === "string") {
              textArray.push(obj);
            } else if (typeof obj === "object") {
              for (const key in obj) {
                if (typeof obj[key] === "string") {
                  textArray.push(obj[key]);
                } else if (Array.isArray(obj[key])) {
                  obj[key].forEach((item) => extractTextRecursive(item));
                } else if (typeof obj[key] === "object") {
                  extractTextRecursive(obj[key]);
                }
              }
            }
          }

          extractTextRecursive(response);

          const combinedText = textArray.join(" ");

          return combinedText;
        }

        context.modal.show({
          title: "Quote Declined",
          text: extractText(response.data),
        });
      } else {
        navigate("/review", { state: { data: response.data?.Quote } });
      }

      setsubmitLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setsubmitLoading(false);
    }
  };

  const saveQuote = async () => {
    try {
      const postData = {
        _id: data._id,
        dotNumber: company["dot_#"],
        user_id: user.accounts[0].user_id,
        userName: user.name,
        editedField: `Quote Saved`,
        client: company.type,
        vehicles: data.vehicles,
        trailers: data.trailers,
        drivers: data.drivers,
        quote: {
          coverage: {
            requestAl: data.requestAl?.length > 0 ? "Y" : "N",
            optAlPip: data.optAlPip?.length > 0 ? "Y" : "N",
            optAlUm: data.optAlUm?.length > 0 ? "Y" : "N",
            requestApd: data.generalLiability?.includes(
              "Automobile Physical Damage (APD)"
            )
              ? "Y"
              : "N",
            requestMtc: data.generalLiability?.includes(
              "Motor Truck Cargo (MTC)"
            )
              ? "Y"
              : "N",
            requestTgl: data.generalLiability?.includes(
              "Truckers General Liability (TGL)"
            )
              ? "Y"
              : "N",
            requestNtl: data.generalLiability?.includes(
              "Non-Trucking Liability (NTL)"
            )
              ? "Y"
              : "N",
            effectiveDate: data.effectiveDate,
          },
          insuredInformation: {
            ownerName: data.ownerName,
            dotNumber: company["dot_#"],
            email: data.emailAddress,
            legalName: data.legalName,
            dbaName: data.dbaName,
            yearsInBusiness: data.yearsInBusiness,
            monthsInBusiness: data.monthsInBusiness,
            insuranceContactFirstName: data.insuranceContactFirstName,
            insuranceContactLastName: data.insuranceContactLastName,
            insuranceContactPhone: data.insuranceContactPhone,
            insuranceContactEmail: data.insuranceContactEmail,
          },
          limits: {
            trailerInterchange:
              data.trailerInterchangeLimit !== "0" &&
              data.trailerInterchangeLimit !== undefined
                ? "Y"
                : "N",
            trailerInterchangeLimit:
              data.trailerInterchangeLimit !== "0" &&
              data.trailerInterchangeLimit !== undefined
                ? data.trailerInterchangeLimit
                : "0",
            limitTowingStorage: data.limitTowingStorage,
            nbrOfTrucks: data.nbrOfTrucks,
            valueOfTrucks: data.valueOfTrucks,
            nbrOfTrailers: data.nbrOfTrailers,
            valueOfTrailers: data.valueOfTrailers,
            limitAutoLiability: data.limitAutoLiability,
            mtcLimit: data.mtcLimit,
          },
          operations: {
            opsLocal: data.operationDescription?.includes("Local trucker")
              ? "Y"
              : "N",
            opsIntermediate: data.operationDescription?.includes("Intermediate")
              ? "Y"
              : "N",
            opsLongHaul: data.operationDescription?.includes(
              "Long haul trucker"
            )
              ? "Y"
              : "N",
            opsIntermodalPort: data.operationDescription?.includes(
              "Intermodal or port"
            )
              ? "Y"
              : "N",
            opsDumpTruckOther: data.operationDescription?.includes(
              "Dump trucks (Other)"
            )
              ? "Y"
              : "N",
            opsDumpTruckSandGravel: data.operationDescription?.includes(
              "Dump trucks (sand and gravel)"
            )
              ? "Y"
              : "N",
            opsEndDumper: data.operationDescription?.includes(
              "End dumper units"
            )
              ? "Y"
              : "N",
            opsLogging: data.operationDescription?.includes("Logging")
              ? "Y"
              : "N",
            opsRefrigirated: data.operationDescription?.includes(
              "refrigerated trailer"
            )
              ? "Y"
              : "N",
            opsHotShot: data.operationDescription?.includes(
              "Hot shot (TGL and NTL not available for  class 3 vehicles)"
            )
              ? "Y"
              : "N",
            opsOversizedOvernight: data.operationDescription?.includes(
              "Oversized or overweight"
            )
              ? "Y"
              : "N",
            opsAutomobileHauler: data.operationDescription?.includes(
              "Automobile hauler [Excluded for cargo and NTL]"
            )
              ? "Y"
              : "N",
            opsHouseholdGoods: data.operationDescription?.includes(
              "Household goods mover"
            )
              ? "Y"
              : "N",
            opsTanker: data.operationDescription?.includes("Tankers")
              ? "Y"
              : "N",
            UIIAintermodal: data.operationDescription?.includes(
              "UIIA - Intermodal"
            )
              ? "Y"
              : "N",
            ineligibleOperations: data.ineligibleOperations ? "Y" : "N",
            filingsAlFederal: data.filingsAlFederal ? "Y" : "N",
            filingsAlState: data.filingsAlState ? "Y" : "N",
            priorInsuranceCancelledNonrenewed:
              data.priorInsuranceCancelledNonrenewed ? "Y" : "N",
          },
          garageAddress: {
            garageStreet: data.garageStreet,
            garageCity: data.garageCity,
            garageState: data.garageState,
            garageZip: data.garageZip,
            garageCounty: "",
            garageCountry: "",
          },
          mailingAddress: {
            mailingStreet: data.mailingStreet,
            mailingCity: data.mailingCity,
            mailingState: data.mailingState,
            mailingZip: data.mailingZip,
            mailingCounty: "",
            mailingCountry: "",
          },
          shippingAddress: {
            shippingStreet: data.mailingStreet,
            shippingCity: data.mailingCity,
            shippingState: data.mailingState,
            shippingZip: data.mailingZip,
            shippingCounty: "",
            shippingCountry: "",
          },
          radius: {
            radius0_50: data.radius0_50,
            radius51_200: data.radius51_200,
            radius201_500: data.radius201_500,
            radius501: data.radius501,
          },
          commoditiesRefrigeration: data.commoditiesRefrigeration ? "Y" : "N",
          commodities: data.commodityArray,
          losses: {
            1: {
              lossConfirmed: data.lossConfirmed23 ? "Y" : "N",
              lossAlCount: data.lossAlCount23,
              lossAlPaid: data.lossAlPaid23,
              lossApdCount: data.lossApdCount23,
              lossApdPaid: data.lossApdPaid23,
              lossMtcCount: data.lossMtcCount23,
              lossMtcPaid: data.lossMtcPaid23,
              lossTglCount: data.lossTglCount23,
              lossTglPaid: data.lossTglPaid23,
              lossNtlCount: data.lossNtlCount23,
              lossNtlPaid: data.lossNtlPaid23,
            },
            2: {
              lossConfirmed: data.lossConfirmed22 ? "Y" : "N",
              lossAlCount: data.lossAlCount22,
              lossAlPaid: data.lossAlPaid22,
              lossApdCount: data.lossApdCount22,
              lossApdPaid: data.lossApdPaid22,
              lossMtcCount: data.lossMtcCount22,
              lossMtcPaid: data.lossMtcPaid22,
              lossTglCount: data.lossTglCount22,
              lossTglPaid: data.lossTglPaid22,
              lossNtlCount: data.lossNtlCount22,
              lossNtlPaid: data.lossNtlPaid22,
            },
            3: {
              lossConfirmed: data.lossConfirmed21 ? "Y" : "N",
              lossAlCount: data.lossAlCount21,
              lossAlPaid: data.lossAlPaid21,
              lossApdCount: data.lossApdCount21,
              lossApdPaid: data.lossApdPaid21,
              lossMtcCount: data.lossMtcCount21,
              lossMtcPaid: data.lossMtcPaid21,
              lossTglCount: data.lossTglCount21,
              lossTglPaid: data.lossTglPaid21,
              lossNtlCount: data.lossNtlCount21,
              lossNtlPaid: data.lossNtlPaid21,
            },
          },
          retailAgent: {
            FirstName: data.producer.value.FirstName,
            LastName: data.producer.value.LastName,
            Phone: data.producer.value?.Phone || "",
            Email: data.producer.value.Email,
            AgencyName: data.producer.value.AgencyName,
            Street: data.producer.value.Street,
            City: data.producer.value.City,
            State: data.producer.value.State,
            Zip: data.producer.value.Zip,
          },
        },
      };
      const response = await axios.post(
        `/api/${
          company.type === "customer" ? "company" : "prospect"
        }/saveQuote`,
        postData
      );
      if (response?.data?.Quote?._id) {
        setData((prevState) => {
          return {
            ...prevState,
            _id: response?.data?.Quote?._id,
          };
        });
      }
      context.notification.show("Quote Saved", "success", true);
    } catch (error) {
      console.error(error);
      context.notification.show("There was a problem", "error", true);
    }
  };

  if (loading) {
    return <Loader loading="new loading" />;
  }

  return (
    <>
      <div>
        <Producer data={data} setData={setData} />
        <Coverages onChange={onChange} data={data} />
        <Insured onChange={onChange} data={data} />
        <Limits onChange={onChange} data={data} />
        <Operations onChange={onChange} data={data} />
        <Radius onChange={onChange} data={data} />
        <Cargo onChange={onChange} data={data} setData={setData} />
        <Vehicles data={data} setData={setData} />
        <Trailers data={data} setData={setData} />
        <Drivers data={data} setData={setData} />
        <Losses onChange={onChange} data={data} />
        {emptyInput.length > 0 && (
          <p className="relative block text-red-600 text-lg text-center py-2">
            {emptyInput}
          </p>
        )}
        <div className="flex gap-4">
          <Button
            text="Save Quote"
            color="bluesign"
            className="w-full mt-4"
            action={saveQuote}
          />
          <Button
            text="Submit Quote"
            color="bluesign"
            className="w-full mt-4"
            action={handleSubmit}
            loading={submitLoading}
          />
        </div>
      </div>
      <Button
        className="fixed bottom-30 right-3 !z-[999] pr-10 pl-4 py-4 text-white rounded"
        text="Save"
        color="green"
        action={saveQuote}
        icon="save"
        alignRight
        iconColor="white"
        iconButton={true}
      />
    </>
  );
}

function Producer(props) {
  const sales = useAPI(`/api/user/getAllSales`)?.data || [];
  const retailAgent = {
    label: "Joe Agentzzz",
    value: {
      FirstName: "Joe",
      LastName: "Agentzzz",
      Phone: "5555555555",
      Email: "Joe@mail.com",
      AgencyName: "Trucking",
      Street: "10 Main Street",
      City: "Chicago",
      State: "IL",
      Zip: "90201",
    },
  };

  function transformUserObject(originalUser) {
    const transformedUser = {
      label: originalUser.name,
      value: {
        FirstName: originalUser.name?.split(" ")[0] || "",
        LastName: originalUser.name?.split(" ")[1] || "",
        Phone: originalUser.phone ? originalUser.phone.replace(/-/g, "") : "",
        Email: originalUser.email,
        AgencyName: "Trucking",
        Street: "10 Main Street",
        City: "Chicago",
        State: "IL",
        Zip: "90201",
      },
    };

    return transformedUser;
  }

  const transformedUsers = sales.map(transformUserObject);

  const salesOPtions = [retailAgent, ...transformedUsers];

  const data = props.data;
  const setData = props.setData;
  const handleSelectChange = (selectedOption) => {
    setData((prevState) => {
      return {
        ...prevState,
        producer: selectedOption,
      };
    });
  };

  return (
    <Card id="3">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Producer</h2>
      </header>
      <SelectReact
        options={salesOPtions}
        value={data.producer}
        onChange={handleSelectChange}
        name="producer"
        styles={{
          control: (styles) => ({ ...styles, padding: ".75rem" }),
        }}
      />
    </Card>
  );
}

function Coverages(props) {
  const data = props.data;
  const onChange = props.onChange;

  return (
    <Card className="mb-4" id="1">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300">
        <h2 className="capitalize text-xl font-semibold pt-4">
          Coverages (Select coverages to quote)
        </h2>
      </header>
      <div className="flex">
        <Fieldset
          name="requestAl"
          type="checkbox"
          options={["Auto Liability (AL)"]}
          cols={1}
          default={data.requestAl}
          className="font-bold w-[32%]"
          onChange={onChange}
        />
        {data.requestAl?.[0] === "Auto Liability (AL)" && (
          <>
            <Fieldset
              name="optAlPip"
              type="checkbox"
              options={["Personal Injury Protection (PIP)"]}
              cols={1}
              default={data.optAlPip}
              className="font-bold w-[32%]"
              onChange={onChange}
            />
            <Fieldset
              name="optAlUm"
              type="checkbox"
              options={["Uninsured Motorists/Property Damage"]}
              cols={1}
              default={data.optAlUm}
              className="font-bold w-[32%]"
              onChange={onChange}
            />
          </>
        )}
      </div>
      <Fieldset
        name="generalLiability"
        type="checkbox"
        options={[
          "Automobile Physical Damage (APD)",
          "Motor Truck Cargo (MTC)",
          "Truckers General Liability (TGL)",
          "Non-Trucking Liability (NTL)",
        ]}
        cols={1}
        default={data.generalLiability}
        className="font-bold"
        onChange={onChange}
      />
      <p className="m-0">
        <strong>Policy Effective Date</strong>
      </p>
      <DateInput
        name="effectiveDate"
        className="w-full"
        onChange={onChange}
        calendarPosition="top"
        value={data.effectiveDate}
      />
      {progressive && (
        <>
          <Switch
            name="progressiveCommercialAuto"
            label="Is the customer currently insured with Progressive Commercial Auto? (Progressive Only)"
            labelRight
            className="my-4"
            labelClassName="font-bold"
            onChange={onChange}
            default={data.progressiveCommercialAuto}
          />
          <div className="border rounded p-4 mt-2">
            <p className="text-xl">
              <strong>Coverages applied to all vehicles</strong>
            </p>
            <div className="flex flex-wrap justify-between w-full gap-4 mt-4">
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Bodily injury and property damage liability (Progressive
                    Only)
                  </strong>
                </p>
                <Select
                  name="bodilyInjuryDamage"
                  id="bodilyInjuryDamage"
                  value={data.bodilyInjuryDamage}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Uninsured Motorist property damage (Progressive Only)
                  </strong>
                </p>
                <Select
                  name="uninsuredMotoristDamage"
                  id="uninsuredMotoristDamage"
                  value={data.uninsuredMotoristDamage}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Uninsured/underinsured motorist bodily injury (Progressive
                    Only)
                  </strong>
                </p>
                <Select
                  name="underinsuredMotoristBodilyInjury"
                  id="underinsuredMotoristBodilyInjury"
                  value={data.underinsuredMotoristBodilyInjury}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Personal injury protection (Progressive Only)</strong>
                </p>
                <Select
                  name="personalInjuryProtectionProgressive"
                  value={data.personalInjuryProtection}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="border rounded p-4 mt-2">
            <p className="text-xl">
              <strong>Coverages for the vehicles</strong>
            </p>
            <div className="flex flex-wrap justify-between w-full gap-4 mt-4">
              <div className="w-[32%]">
                <p className="m-0">
                  <strong>Comprehensive (Progressive Only)</strong>
                </p>
                <Select
                  name="Comprehensive"
                  id="Comprehensive"
                  value={data.Comprehensive}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
              <div className="w-[32%]">
                <p className="m-0">
                  <strong>Collision (Progressive Only)</strong>
                </p>
                <Select
                  name="Collision"
                  id="Collision"
                  value={data.Collision}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
              <div className="w-[32%]">
                <p className="m-0">
                  <strong>Medical payments (Progressive Only)</strong>
                </p>
                <Select
                  name="medicalPayments"
                  id="medicalPayments"
                  value={data.medicalPayments}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Rental reimbursement (Progressive Only)</strong>
                </p>
                <Select
                  name="rentalReimbursement"
                  id="rentalReimbursement"
                  value={data.rentalReimbursement}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Rental with downtime (Progressive Only)</strong>
                </p>
                <Select
                  name="rentalWithDowntime"
                  id="rentalWithDowntime"
                  value={data.rentalWithDowntime}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Roadside Assistance (Progressive Only)</strong>
                </p>
                <Select
                  name="roadsideAssistance"
                  id="roadsideAssistance"
                  value={data.roadsideAssistance}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Fire & Theft w/ combined additional coverage (Progressive
                    Only)
                  </strong>
                </p>
                <Select
                  name="fireTheftCoverage"
                  id="fireTheftCoverage"
                  value={data.fireTheftCoverage}
                  options={[
                    { value: "option1", label: "option1" },
                    { value: "option2", label: "option2" },
                    { value: "option3", label: "option3" },
                    { value: "option4", label: "option4" },
                  ]}
                  containerClass="m-0"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <>
            <Fieldset
              type="radio"
              options={["$5,000 or Less", "More than $5,000"]}
              label="How much did the customer spend in renting, hiring, or borrowing vehicles last year, including the cost to hire drivers not listed on the policy? (Progressive Only)"
              name="lastYearCustomerSpend"
              onChange={onChange}
              cols={1}
            />
            <Switch
              name="nonOwnedNotListedVehiclesBusiness"
              label="Are non-owned vehicles which are not listed on the policy used in the business? (Progressive Only)"
              labelRight
              labelClassName="font-bold"
              onChange={onChange}
              default={data.nonOwnedNotListedVehiclesBusiness}
            />
            <Switch
              name="interchangeAgreementRequiringCoverage"
              label="Does the customer have an interchange agreement requiring trailer interchange coverage? (Progressive Only)"
              labelRight
              labelClassName="font-bold"
              onChange={onChange}
              default={data.interchangeAgreementRequiringCoverage}
            />
            <Switch
              name="furnishCopyTheInterchangeAgreement"
              label="Does the customer agree to furnish a copy of the interchange agreement upon request, including in the event of a claim? (Progressive Only)"
              labelRight
              labelClassName="font-bold"
              onChange={onChange}
              default={data.furnishCopyTheInterchangeAgreement}
            />
            <div className="w-[49%]">
              <p className="m-0">
                <strong>Number of non-owned trailers (Progressive Only)</strong>
              </p>
              <Select
                name="nonOwnedTrailers"
                id="nonOwnedTrailers"
                value={data.nonOwnedTrailers}
                options={[
                  { value: "option1", label: "option1" },
                  { value: "option2", label: "option2" },
                  { value: "option3", label: "option3" },
                  { value: "option4", label: "option4" },
                ]}
                containerClass="m-0"
                onChange={onChange}
              />
            </div>
            <div className="w-[49%]">
              <p className="m-0">
                <strong>Type of non-owned trailers (Progressive Only)</strong>
              </p>
              <Select
                name="nonOwnedTrailersType"
                id="nonOwnedTrailersType"
                value={data.nonOwnedTrailersType}
                options={[
                  { value: "option1", label: "option1" },
                  { value: "option2", label: "option2" },
                  { value: "option3", label: "option3" },
                  { value: "option4", label: "option4" },
                ]}
                containerClass="m-0"
                onChange={onChange}
              />
            </div>
          </>
        </>
      )}
    </Card>
  );
}

function Insured(props) {
  const data = props.data;
  const onChange = props.onChange;
  const context = useContext(ViewContext);

  const aditionalInsuredInfo = () => {
    context.modal.show(
      {
        title: "Additional insured info",
        form: {
          aditionalInsuredInfoName: {
            id: "aditionalInsuredInfoName",
            label: "Name",
            type: "text",
            class: "w-full",
            containerClass: "w-full",
            required: true,
          },
          aditionalInsuredInfoAddress: {
            id: "aditionalInsuredInfoAddress",
            label: "Address",
            type: "text",
            class: "w-full",
            containerClass: "w-full",
            required: true,
          },
          aditionalInsuredInfoCity: {
            id: "aditionalInsuredInfoCity",
            label: "City",
            type: "text",
            class: "w-full",
            containerClass: "w-[49%]",
            required: true,
          },
          aditionalInsuredInfoState: {
            label: "State",
            type: "select",
            options: usStates,
            class: "w-full",
            containerClass: "w-[22%]",
            required: true,
          },
          aditionalInsuredInfoZIP: {
            id: "aditionalInsuredInfoZIP",
            label: "ZIP",
            type: "text",
            class: "w-full",
            containerClass: "w-[22%]",
            required: true,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Save",
        buttonClass: "flex justify-end w-full mt-4",
      },
      (res) => {
        console.log(res);
      }
    );
  };

  return (
    <Card className="mb-4" id="2">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Insured</h2>
        {progressive && (
          <Button
            color="bluesign"
            size={16}
            text="Add additional insured"
            className="p-2 text-white rounded"
            action={aditionalInsuredInfo}
          />
        )}
      </header>
      {progressive && (
        <>
          <Fieldset
            type="radio"
            options={[
              "The customer will not have a DOT number (Progressive Only)",
              "The customer has applied/will apply for a DOT number (Progressive Only)",
            ]}
            name="apllyDOTNumber"
            cols={1}
            onChange={onChange}
          />
        </>
      )}
      <div className="flex justify-between mb-8">
        <div className="w-[22%]">
          <p className="m-0">
            <strong>DOT Number</strong>
          </p>
          <NumberInput
            name="dotNumber"
            id="dotNumber"
            className="w-full"
            onChange={onChange}
            value={data.dotNumber}
          />
        </div>
        <div className="w-[22%]">
          <p className="m-0">
            <strong>Insured Company Name</strong>
          </p>
          <TextInput
            id="legalName"
            name="legalName"
            className="w-full"
            onChange={onChange}
            value={data.legalName}
          />
        </div>
        <div className="w-[22%]">
          <p className="m-0">
            <strong>Doing business as “DBA”</strong>
          </p>
          <TextInput
            name="dbaName"
            id="dbaName"
            className="w-full"
            onChange={onChange}
            value={data.dbaName}
          />
        </div>
        <div className="w-[22%]">
          <p className="m-0">
            <strong>Owner’s Name</strong>
          </p>
          <TextInput
            name="ownerName"
            id="ownerName"
            className="w-full"
            onChange={onChange}
            value={data.ownerName}
          />
        </div>
      </div>
      <div className="border rounded p-4 mt-2 mb-8">
        <p className="text-xl">
          <strong>Mailing address</strong>
        </p>
        <div className="flex justify-between w-full">
          <div className="w-[28%]">
            <p className="m-0">
              <strong>Address</strong>
            </p>
            <TextInput
              name="mailingStreet"
              id="mailingStreet"
              className="w-full"
              onChange={onChange}
              value={data.mailingStreet}
            />
          </div>
          <div className="w-[28%]">
            <p className="m-0">
              <strong>Address Line 2</strong>
            </p>
            <TextInput
              name="mailingStreet2"
              id="mailingStreet2"
              className="w-full"
              onChange={onChange}
              value={data.mailingStreet2}
            />
          </div>
          <div className="w-[18%]">
            <p className="m-0">
              <strong>City</strong>
            </p>
            <TextInput
              name="mailingCity"
              id="mailingCity"
              className="w-full"
              onChange={onChange}
              value={data.mailingCity}
            />
          </div>
          <div className="w-[10%]">
            <p className="m-0">
              <strong>State</strong>
            </p>
            <Select
              name="mailingState"
              id="mailingState"
              value={data.mailingState}
              options={usStates}
              containerClass="m-0"
              onChange={onChange}
            />
          </div>
          <div className="w-[10%]">
            <p className="m-0">
              <strong>ZIP</strong>
            </p>
            <TextInput
              name="mailingZip"
              id="mailingZip"
              className="w-full"
              onChange={onChange}
              value={data.mailingZip}
            />
          </div>
        </div>
        <Fieldset
          options={["Business garaging address is same as mailing address"]}
          name="sameBusinessAddress"
          type="checkbox"
          className="mt-4 flex items-center font-bold whitespace-nowrap"
          onChange={onChange}
          value={data.sameBusinessAddress}
        />
      </div>
      <div className="border rounded p-4 mb-8">
        <p className="text-xl">
          <strong>Business garaging address</strong>
        </p>
        <div className="flex justify-between w-full">
          <div className="w-[28%]">
            <p className="m-0">
              <strong>Address</strong>
            </p>
            <TextInput
              name="garageStreet"
              id="garageStreet"
              className="w-full"
              onChange={onChange}
              value={data.garageStreet}
            />
          </div>
          <div className="w-[28%]">
            <p className="m-0">
              <strong>Address Line 2</strong>
            </p>
            <TextInput
              name="garageStreet2"
              id="garageStreet2"
              className="w-full"
              onChange={onChange}
              value={data.garageStreet2}
            />
          </div>
          <div className="w-[18%]">
            <p className="m-0">
              <strong>City</strong>
            </p>
            <TextInput
              name="garageCity"
              id="garageCity"
              className="w-full"
              onChange={onChange}
              value={data.garageCity}
            />
          </div>
          <div className="w-[10%]">
            <p className="m-0">
              <strong>State</strong>
            </p>
            <Select
              name="garageState"
              id="garageState"
              options={usStates}
              onChange={onChange}
              value={data.garageState}
            />
          </div>
          <div className="w-[10%]">
            <p className="m-0">
              <strong>ZIP</strong>
            </p>
            <TextInput
              name="garageZip"
              id="garageZip"
              className="w-full"
              onChange={onChange}
              value={data.garageZip}
            />
          </div>
        </div>
      </div>
      {progressive && (
        <>
          <Fieldset
            type="radio"
            options={[
              "Individual / Sole proprietor",
              "Partnership",
              "Corporation or LCC / Non-Profit",
            ]}
            label="How is the customer's business structured? (Progressive Only)"
            name="businessStructured"
            onChange={onChange}
            className="font-bold"
            cols={1}
          />
          <div className="flex justify-between w-full">
            <div className="w-[49%]">
              <p className="m-0">
                <strong>
                  Keyword describing your business (Progressive Only)
                </strong>
              </p>
              <TextInput
                name="describingYourBusiness"
                id="describingYourBusiness"
                className="w-full"
                onChange={onChange}
                value={data.describingYourBusiness}
              />
            </div>
            <div className="w-[49%]">
              <p className="m-0">
                <strong>
                  business tax information: employer identification number
                  (Progressive Only)
                </strong>
              </p>
              <TextInput
                name="employerIdentificationNumber"
                id="employerIdentificationNumber"
                className="w-full"
                onChange={onChange}
                value={data.employerIdentificationNumber}
              />
            </div>
          </div>
          <Switch
            name="customerCurrentlyInsured"
            label="Is the customer currently insured? (Progressive Only)"
            labelRight
            className="my-4"
            labelClassName="font-bold"
            onChange={onChange}
            default={data.customerCurrentlyInsured}
          />
          <Switch
            name="customerContinuousCoverage"
            label="Has the customer had continuous coverage? (at least 1 year) (Progressive Only)"
            labelRight
            className="my-4"
            labelClassName="font-bold"
            onChange={onChange}
            default={data.customerContinuousCoverage}
          />
          <p className="m-0">
            <strong>
              Current bodily injury liability limit (Progressive Only)
            </strong>
          </p>
          <TextInput
            name="currentBodilyInjuryLiabilityLimit"
            id="currentBodilyInjuryLiabilityLimit"
            className="w-full"
            onChange={onChange}
            value={data.currentBodilyInjuryLiabilityLimit}
          />
          <p className="m-0">
            <strong>Current policy expiration date (Progressive Only)</strong>
          </p>
          <TextInput
            name="currentPolicyExpirationDate"
            id="currentPolicyExpirationDate"
            className="w-full"
            onChange={onChange}
            value={data.currentPolicyExpirationDate}
          />
          <Fieldset
            type="radio"
            options={["General liability", "Business owner's policy", "none"]}
            label="Does the customer have other coverages for the business? (Progressive Only)"
            name="businessStructured"
            onChange={onChange}
            className="font-bold"
            cols={1}
          />
          <div className="flex justify-between w-full">
            <div className="w-[49%]">
              <p className="m-0">
                <strong>
                  Number of named additional insureds (Progressive Only)
                </strong>
              </p>
              <TextInput
                name="numberNamedAdditionalInsureds"
                id="numberNamedAdditionalInsureds"
                className="w-full"
                onChange={onChange}
                value={data.numberNamedAdditionalInsureds}
              />
            </div>
            <div className="w-[49%]">
              <p className="m-0">
                <strong>
                  Number of named waivers of subrogation holders (Progressive
                  Only)
                </strong>
              </p>
              <TextInput
                name="numberNamedWaiversSubrogationHolders"
                id="numberNamedWaiversSubrogationHolders"
                className="w-full"
                onChange={onChange}
                value={data.numberNamedWaiversSubrogationHolders}
              />
            </div>
          </div>
          <Switch
            name="blanketAdditionalInsuredEndorsementNeededByContract"
            label="Is a blanket additional insured endorsement needed by contract? (Progressive Only)"
            labelRight
            className="my-4"
            labelClassName="font-bold"
            onChange={onChange}
            default={data.blanketAdditionalInsuredEndorsementNeededByContract}
          />
          <Switch
            name="blanketWaiverSubrogationEndorsementNeededContract"
            label="Is a blanket waiver of subrogation endorsement needed by contract? (Progressive Only)"
            labelRight
            className="my-4"
            labelClassName="font-bold"
            onChange={onChange}
            default={data.blanketWaiverSubrogationEndorsementNeededContract}
          />
          <Switch
            name="filingsRequired"
            label="Are state or federal filings required? (Progressive Only)"
            labelRight
            className="my-4"
            labelClassName="font-bold"
            onChange={onChange}
            default={data.filingsRequired}
          />
        </>
      )}
      <p className="border-b pb-4 text-xl">
        <strong>Insured Contact Info</strong>
      </p>
      <div className="flex justify-between flex-wrap w-full mt-4">
        <div className="w-[49%]">
          <p className="m-0">
            <strong>First name</strong>
          </p>
          <TextInput
            name="insuranceContactFirstName"
            id="insuranceContactFirstName"
            className="w-full"
            onChange={onChange}
            value={data.insuranceContactFirstName}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Last name</strong>
          </p>
          <TextInput
            name="insuranceContactLastName"
            id="insuranceContactLastName"
            className="w-full"
            onChange={onChange}
            value={data.insuranceContactLastName}
          />
        </div>
        <div className={progressive ? "w-[32%]" : "w-[49%]"}>
          <p className="m-0">
            <strong>Email address</strong>
          </p>
          <EmailInput
            name="insuranceContactEmail"
            id="insuranceContactEmail"
            className="w-full"
            onChange={onChange}
            value={data.insuranceContactEmail}
          />
        </div>
        <div className={progressive ? "w-[32%]" : "w-[49%]"}>
          <p className="m-0">
            <strong>Phone</strong>
          </p>
          <PhoneInput
            name="insuranceContactPhone"
            for="insuranceContactPhone"
            className="w-full"
            onChange={onChange}
            value={data.insuranceContactPhone}
          />
        </div>
        {progressive && (
          <>
            <div className="w-[32%]">
              <p className="m-0">
                <strong>Date of birth (Progressive Only)</strong>
              </p>
              <DateInput
                name="insuredContactBirth"
                className="w-full"
                onChange={onChange}
                value={data.insuredContactBirth}
              />
            </div>
            <div className="w-full">
              <p className="m-0">
                <strong>Home address (Progressive Only)</strong>
              </p>
              <TextInput
                name="insuredContacHomeAddress"
                id="insuredContacHomeAddress"
                className="w-full"
                onChange={onChange}
                value={data.insuredContacHomeAddress}
              />
            </div>
          </>
        )}
      </div>
    </Card>
  );
}

function Limits(props) {
  const data = props.data;
  const onChange = props.onChange;

  return (
    <Card className="mb-6" id="4">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300">
        <h2 className="capitalize text-xl font-semibold pt-4">Limits</h2>
      </header>
      <div className="flex justify-between flex-wrap w-full gap-4">
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Number of trucks</strong>
          </p>
          <NumberInput
            name="nbrOfTrucks"
            className="w-full"
            onChange={onChange}
            value={data.nbrOfTrucks}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Total value of trucks</strong>
          </p>
          <MoneyInput
            name="valueOfTrucks"
            className="w-full"
            onChange={onChange}
            value={data.valueOfTrucks}
          />
          <p className="text-sm">
            What would the above vehicles sell for today?
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Number of trailers</strong>
          </p>
          <NumberInput
            name="nbrOfTrailers"
            className="w-full"
            onChange={onChange}
            value={data.nbrOfTrailers}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Total value of trailers</strong>
          </p>
          <MoneyInput
            name="valueOfTrailers"
            className="w-full"
            onChange={onChange}
            value={data.valueOfTrailers}
          />
          <p className="text-sm">
            what would the above trailers sell for today?
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Towing, Labor and Storage Limit</strong>
          </p>
          <Select
            name="limitTowingStorage"
            options={[
              { value: "25000", label: "$ 25,000" },
              { value: "20000", label: "$ 20,000" },
              { value: "15000", label: "$ 15,000" },
              { value: "10000", label: "$ 10,000" },
              { value: "7500", label: "$ 7,500" },
              { value: "5000", label: "$ 5,000" },
              { value: "2500", label: "$ 2,500" },
            ]}
            onChange={onChange}
            value={data.limitTowingStorage}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Trailer Interchange Limit</strong>
          </p>
          <Select
            name="trailerInterchangeLimit"
            options={[
              { value: "0", label: "No, Excluded" },
              { value: "20000", label: "$ 20,000" },
              { value: "25000", label: "$ 25,000" },
              { value: "30000", label: "$ 30,000" },
              { value: "35000", label: "$ 35,000" },
              { value: "40000", label: "$ 40,000" },
              { value: "45000", label: "$ 45,000" },
              { value: "50000", label: "$ 50,000" },
              { value: "55000", label: "$ 55,000" },
              { value: "60000", label: "$ 60,000" },
              { value: "65000", label: "$ 65,000" },
              { value: "70000", label: "$ 70,000" },
              { value: "75000", label: "$ 75,000" },
              { value: "80000", label: "$ 80,000" },
              { value: "85000", label: "$ 85,000" },
              { value: "90000", label: "$ 90,000" },
              { value: "95000", label: "$ 95,000" },
              { value: "100000", label: "$ 100,000" },
            ]}
            default="0"
            onChange={onChange}
            value={data.trailerInterchangeLimit}
          />
          <p className="text-sm">
            This coverage requieres a signed interchange agreement
          </p>
        </div>
      </div>
      <p className="mt-4">
        <strong>Auto Liability Limit</strong>
      </p>
      <Select
        name="limitAutoLiability"
        options={[
          { value: "1000000", label: "$ 1,000,000" },
          { value: "750000", label: "$ 750,000" },
        ]}
        className="mb-8"
        onChange={onChange}
        value={data.limitAutoLiability}
      />
    </Card>
  );
}

function Operations(props) {
  const data = props.data;
  const onChange = props.onChange;

  return (
    <Card className="mb-6" id="5">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex items-center pt-4">
        <h2 className="capitalize text-xl font-semibold w-1/2">Operations</h2>
      </header>
      <Switch
        name="filingsAlFederal"
        label="Do you require federal filings for auto liability?"
        labelRight
        labelClassName="font-bold"
        className="my-8"
        onChange={onChange}
        default={data.filingsAlFederal}
      />
      <Switch
        name="filingsAlState"
        label="Do you require state filings for auto liability"
        labelRight
        labelClassName="font-bold"
        className="my-4"
        onChange={onChange}
        default={data.filingsAlState}
      />
      <Switch
        name="priorInsuranceCancelledNonrenewed"
        label="Has your prior insurance ever been cancelled or non-renewed by any insurer in the past 3 years (for any reasons NOT related to payment of premium)?"
        labelRight
        labelClassName="font-bold w-[85%]"
        className="my-4"
        onChange={onChange}
        default={data.priorInsuranceCancelledNonrenewed}
      />
      <div className="w-full my-4">
        <p className="m-0">
          <strong>Time operating in your name</strong>
        </p>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Years</strong>
          </p>
          <TextInput
            name="yearsInBusiness"
            className="w-full"
            onChange={onChange}
            value={data.yearsInBusiness}
          />
        </div>
        <div className="w-[49%] mt-4">
          <p className="m-0">
            <strong>Months</strong>
          </p>
          <TextInput
            name="monthsInBusiness"
            className="w-full"
            onChange={onChange}
            value={data.monthsInBusiness}
          />
        </div>
      </div>
      <p className="text-xl underline mt-4">
        <strong>Best description of your operation</strong>
      </p>
      <p>Choose any that apply:</p>
      <Fieldset
        name="operationDescription"
        type="checkbox"
        cols={3}
        options={[
          "Local trucker",
          "Intermediate",
          "Long haul trucker",
          "Intermodal or port",
          "Household goods mover",
          "Tankers",
          "UIIA - Intermodal",
          "Dump trucks (Other)",
          "Dump trucks (sand and gravel)",
          "End dumper units",
          "Logging",
          "refrigerated trailer",
          "Oversized or overweight",
          "Automobile hauler [Excluded for cargo and NTL]",
          "Hot shot (TGL and NTL not available for  class 3 vehicles)",
        ]}
        className="font-bold pl-4"
        onChange={onChange}
        default={data.operationDescription}
      />
      <p className="text-red-600 font-bold my-4">
        SEE QUOTE AND POLICY FORM FOR COMPLETE LIST OF EXCLUSIONS
      </p>
      <Switch
        name="ineligibleOperations"
        label="Does insured conduct any of the following ineligible operations:"
        labelRight
        labelClassName="font-bold w-[85%]"
        className="mb-2"
        onChange={onChange}
        default={data.ineligibleOperations}
      />
      <div className="flex justify-between w-full mt-4">
        <ul className="pl-4 list-disc w-[32%]">
          <li>Ambulance, EMT, Mobile Blood Banks</li>
          <li>Armored Cars</li>
          <li>Arms, Ammunition, Fireworks</li>
          <li>Boat Haulers</li>
          <li>Automobile Dismantiers</li>
          <li>Boom Trucks</li>
          <li>Brokerage</li>
          <li>Cannabis Haulers</li>
          <li>Carníivais, Circus</li>
          <li>Cement Mixers, Pumpers</li>
          <li>Coal Haulers</li>
          <li>Contractors, Contractor Equipment Mobile Equipment</li>
          <li>Cotton Haulers</li>
          <li>
            Courier Services, magazines, news delivery, parcel, film delivery
          </li>
        </ul>
        <ul className="pl-4 list-disc w-[32%]">
          <li>Drive Away, Transporter Plates</li>
          <li>Driving Schools</li>
          <li>Dump Trucks S&G</li>
          <li>Egg Haulers</li>
          <li>Fast food delivery, including restaurant take-out delivery</li>
          <li>Final Delivery</li>
          <li>Freight Brokerage</li>
          <li>Garbage/Refuse</li>
          <li>Hazmat, Nuclear, Biological</li>
          <li>Household Good Movers</li>
          <li>Livestock Transporters</li>
          <li>Logging Operations</li>
          <li>Milk Haulers</li>
          <li>Mobile cranes, Vehicles with buckets</li>
        </ul>
        <ul className="list-disc w-[32%]">
          <li>Mobile Home Toters</li>
          <li>Mobile Home Toters, Building Movers</li>
          <li>Municipal</li>
          <li>Operating outside of US</li>
          <li>Operations using double/triple trailers</li>
          <li>Oversized/Overweight haulers requiring pilot cars</li>
          <li>Owned Units, Rented or Leased to Others</li>
          <li>Private Passenger Vehícies</li>
          <li>
            Public Livery: Buses, taxis, black car, ride share, shuttle, day
            care, church
          </li>
          <li>Rental Leasing, Demo Vehícies</li>
          <li>Salvage, Scrap Metal</li>
          <li>Steel Dealers</li>
          <li>Tankers Hauling Flammable Liquids</li>
          <li>Towing/Repossession,</li>
          <li>Vendor Trucks</li>
        </ul>
      </div>
      {progressive && (
        <>
          <Switch
            name="vehiclesOwnedByBusiness"
            label="Are all listed vehicles owned by the business? (Progressive Only)"
            labelRight
            labelClassName="font-bold w-[85%]"
            className="mb-2"
            onChange={onChange}
            default={data.vehiclesOwnedByBusiness}
          />
          <Switch
            name="vehiclesHaulCoiledSteel"
            label="Are any listed vehicles used to haul any coiled steel? (Progressive Only)"
            labelRight
            labelClassName="font-bold w-[85%]"
            className="mb-2"
            onChange={onChange}
            default={data.vehiclesHaulCoiledSteel}
          />
          <Switch
            name="vehiclesTransportPassengersFromCentersToSchool"
            label="Are any listed vehicles used to transport passengers from centers to school? (Progressive Only)"
            labelRight
            labelClassName="font-bold w-[85%]"
            className="mb-2"
            onChange={onChange}
            default={data.vehiclesTransportPassengersFromCentersToSchool}
          />
          <Switch
            name="vehiclesTransportPassengersBetweenHomeAndSchool"
            label="Are any listed vehicles used to transport children between home and school? (Progressive Only)"
            labelRight
            labelClassName="font-bold w-[85%]"
            className="mb-2"
            onChange={onChange}
            default={data.vehiclesTransportPassengersBetweenHomeAndSchool}
          />
          <Switch
            name="vehiclesToProvideSightseeingTours"
            label="Are any listed vehicles used to provide sightseeing tours? (Progressive Only)"
            labelRight
            labelClassName="font-bold w-[85%]"
            className="mb-2"
            onChange={onChange}
            default={data.vehiclesToProvideSightseeingTours}
          />
          <Switch
            name="vehiclesToTransportSeasonalFarmWorkers"
            label="Are any listed vehicles used to transport seasonal farm workers? (Progressive Only)"
            labelRight
            labelClassName="font-bold w-[85%]"
            className="mb-2"
            onChange={onChange}
            default={data.vehiclesToTransportSeasonalFarmWorkers}
          />
        </>
      )}
    </Card>
  );
}

function Radius(props) {
  const data = props.data;
  const onChange = props.onChange;

  return (
    <Card id="6">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300">
        <h2 className="capitalize text-xl font-semibold pt-4">
          Radius (The sum of all Radius must be equal to 100)
        </h2>
      </header>
      <div className="flex justify-between flex-wrap w-full">
        <div className="w-[22%]">
          <p className="m-0">
            <strong>Under 50 Miles</strong>
          </p>
          <NumberInput
            name="radius0_50"
            className="w-full"
            onChange={onChange}
            value={data.radius0_50}
          />
          <p className="text-sm">% of hauls up to 50 miles</p>
        </div>
        <div className="w-[22%]">
          <p className="m-0">
            <strong>51 - 200 Miles</strong>
          </p>
          <NumberInput
            name="radius51_200"
            className="w-full"
            onChange={onChange}
            value={data.radius51_200}
          />
          <p className="text-sm">% of hauls 51 - 200 miles</p>
        </div>
        <div className="w-[22%]">
          <p className="m-0">
            <strong>201 - 500 Miles</strong>
          </p>
          <NumberInput
            name="radius201_500"
            className="w-full"
            onChange={onChange}
            value={data.radius201_500}
          />
          <p className="text-sm">% of hauls 201 - 500 miles</p>
        </div>
        <div className="w-[22%]">
          <p className="m-0">
            <strong>500+ Miles</strong>
          </p>
          <NumberInput
            name="radius501"
            className="w-full"
            onChange={onChange}
            value={data.radius501}
          />
          <p className="text-sm">% of hauls over 500 miles</p>
        </div>
      </div>
    </Card>
  );
}

function Cargo(props) {
  const data = props.data;
  const setData = props.setData;
  const onChange = props.onChange;

  const commodityOptions = [
    { value: "agricultural_equipment", label: "Agricultural Equipment" },
    {
      value: "alcoholic_beverages",
      label: "Alcoholic beverages (except beer and wine)",
    },
    { value: "appliances", label: "Appliances (except TV and stereos)" },
    {
      value: "arms_ammunition_fireworks",
      label: "Arms, ammunition, fireworks",
    },
    {
      value: "automobile_parts_and_accessories",
      label: "Automobile parts and accessories",
    },
    {
      value: "automobiles_5_vehicles_or_less",
      label: "Automobiles (5 vehicles or less)",
    },
    {
      value: "automobiles_6_vehicles_or_more",
      label: "Automobiles (6+ vehicles)",
    },
    { value: "beer_and_wine", label: "Beer and wine" },
    { value: "beverages", label: "Beverages (nonalcoholic)" },
    { value: "building_materials", label: "Building materials" },
    { value: "bulk_or_bagged_nuts", label: "Bulk or Bagged Nuts" },
    { value: "cameras_and_film", label: "Cameras and film" },
    { value: "cannabis_cbd", label: "Cannabis, CBD" },
    { value: "canned_goods", label: "Canned goods" },
    { value: "cement_sand_or_gravel", label: "Cement, sand, or gravel" },
    {
      value: "chemicals_packaged_or_bulk",
      label: "Chemicals (packaged or bulk)",
    },
    { value: "china_and_ceramics", label: "China and ceramics" },
    { value: "cigarettes_and_cigars", label: "Cigarettes and cigars" },
    { value: "clothing", label: "Clothing" },
    { value: "coal", label: "Coal" },
    { value: "computers", label: "Computers" },
    {
      value: "contractors_heavy_equipment",
      label: "Contractors heavy equipment",
    },
    { value: "cosmetics_and_perfume", label: "Cosmetics and perfume" },
    { value: "cotton", label: "Cotton" },
    { value: "courier_documents", label: "Courier Documents" },
    { value: "cranes", label: "Cranes" },
    { value: "currency", label: "Currency" },
    { value: "dairy_products", label: "Dairy products" },
    {
      value: "dangerous_articles_explosives_corrosives_flamma",
      label: "Dangerous articles, explosives, corrosives, flamma...",
    },
    { value: "department_store_goods", label: "Department Store Goods" },
    { value: "documents", label: "Documents" },
    { value: "drugs", label: "Drugs (except narcotics)" },
    { value: "dry_goods", label: "Dry goods" },
    { value: "eggs_shell", label: "Eggs (shell)" },
    {
      value: "electrical_supplies_and_fixtures",
      label: "Electrical supplies and fixtures",
    },
    { value: "empty_trailers", label: "Empty Trailers" },
    { value: "farm_products", label: "Farm products" },
    { value: "fertilizer", label: "Fertilizer" },
    { value: "fine_arts", label: "Fine arts" },
    { value: "food_products", label: "Food products" },
    { value: "fracking", label: "Fracking" },
    { value: "frozen_or_refrigerated", label: "Frozen or refrigerated" },
    { value: "furniture", label: "Furniture" },
    { value: "furs", label: "Furs" },
    { value: "general_merchandise", label: "General merchandise" },
    { value: "glassware", label: "Glassware" },
    { value: "grain_hay_feed", label: "Grain, hay, feed" },
    { value: "hanging_meat", label: "Hanging Meat" },
    { value: "hardware_and_paint", label: "Hardware and paint" },
    { value: "hazmat", label: "Hazmat" },
    { value: "household_effects", label: "Household Effects" },
    {
      value: "household_goods",
      label: "Household Goods (Residential Delivery)",
    },
    { value: "intermodal_containers", label: "Intermodal Containers" },
    { value: "jewelry", label: "Jewelry" },
    {
      value: "ladies_and_mens_dress_apparel",
      label: "Ladies and mens dress apparel",
    },
    { value: "leather_goods", label: "Leather goods (except shoes)" },
    { value: "liquid_haulers", label: "Liquid haulers (bulk nonflammable)" },
    {
      value: "livestock_and_live_poultry",
      label: "Livestock and live poultry",
    },
    { value: "logs", label: "Logs" },
    { value: "lumber", label: "Lumber" },
    { value: "machinery_light", label: "Machinery (light)" },
    {
      value: "machinery_and_heavy_equipment",
      label: "Machinery and heavy equipment",
    },
    { value: "meat", label: "Meat" },
    { value: "metal_and steel", label: "Metal and steel" },
    { value: "mobile_homes", label: "Mobile Homes" },
    { value: "narcotics", label: "Narcotics" },
    { value: "non-ferrous_metal", label: "Non-Ferrous Metal" },
    {
      value: "nursery-plants_trees_flowers",
      label: "Nursery-Plants, Trees, Flowers",
    },
    { value: "office_equipment", label: "Office equipment" },
    { value: "paper_and_paper_products", label: "Paper and paper products" },
    { value: "people", label: "People" },
    {
      value: "petroleum_products",
      label: "Petroleum products (under 140 degrees flashpoint)",
    },
    { value: "pipe_cable_and_wire", label: "Pipe, cable, and wire" },
    { value: "plumbing_supplies", label: "Plumbing supplies" },
    {
      value: "plastic_and_plastic_products",
      label: "Plastic and plastic products",
    },
    { value: "poultry", label: "Poultry (dressed)" },
    { value: "power_tools", label: "Power tools" },
    { value: "precious_metals", label: "Precious metals" },
    { value: "precious_stones", label: "Precious stones" },
    { value: "produce", label: "Produce" },
    {
      value: "recreational_vehicles_boats_rvs",
      label: "Recreational Vehicles, boats, RVS",
    },
    { value: "refuse_garbage", label: "Refuse, Garbage" },
    {
      value: "rugs_and_carpets_oriental",
      label: "Rugs and carpets (Oriental)",
    },
    {
      value: "rugs_and_carpets",
      label: "Rugs and carpets (other than Oriental)",
    },
    { value: "scrap_metal", label: "Scrap Metal" },
    { value: "seafood_canned", label: "Seafood Canned" },
    { value: "seafood_fresh", label: "Seafood Fresh" },
    { value: "seafood_frozen", label: "Seafood Frozen" },
    { value: "seafood_shellfish", label: "Seafood Shellfish" },
    { value: "seafood", label: "Seafood" },
    { value: "shoes", label: "Shoes" },
    { value: "specialized_heavy_haulers", label: "Specialized heavy haulers" },
    { value: "sporting_goods_and_toys", label: "Sporting goods and toys" },
    { value: "textiles", label: "Textiles" },
    { value: "tires_and_tubes", label: "Tires and tubes" },
    { value: "tobacco", label: "Tobacco" },
    {
      value: "tv_radios_and_stereo_equipment",
      label: "TV, radios, and stereo equipment",
    },
    { value: "video_equipment_and_tapes", label: "Video equipment and tapes" },
  ];

  const addCommodity = () => {
    const newCommodity = {
      commodityKey: "",
      commodityPercentage: "",
      commodityId: data.commodityArray.length + 1,
    };
    setData((prevState) => {
      return {
        ...prevState,
        commodityArray: [...data.commodityArray, newCommodity],
      };
    });
  };

  const removeCommmodity = (id) => {
    setData((prevState) => {
      return {
        ...prevState,
        commodityArray: data.commodityArray.filter(
          (item) => item.commodityId !== id
        ),
      };
    });
  };

  const handleInputChange = (name, value) => {
    const commodityName = name.split("_")[0];
    const commodityIndex = name.split("_")[1];
    setData((prevState) => {
      const newItems = [...prevState.commodityArray];
      newItems[commodityIndex] = {
        ...newItems[commodityIndex],
        [commodityName]: value.value ? value.value : value,
      };
      return {
        ...prevState,
        commodityArray: newItems,
      };
    });
  };

  return (
    <Card className="mb-6" id="7">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">
          Cargo (The sum of all Commodities must be equal to 100)
        </h2>
        <Button
          text="Add Commodity"
          className="text-sm px-8"
          color="bluesign"
          action={addCommodity}
        />
      </header>
      <div className="w-full mb-4">
        <p className="m-0">
          <strong>Limit-Motor Truck Cargo</strong>
        </p>
        <Select
          name="mtcLimit"
          id="mtcLimit"
          value={data.mtcLimit}
          options={[
            { value: "0", label: "No, Excluded" },
            { value: "100000", label: "$ 100,000" },
            { value: "125000", label: "$ 125,000" },
            { value: "150000", label: "$ 150,000" },
            { value: "175000", label: "$ 175,000" },
            { value: "200000", label: "$ 200,000" },
            { value: "250000", label: "$ 250,000" },
          ]}
          containerClass="m-0"
          onChange={onChange}
        />
      </div>
      {data.commodityArray.map((commodity, index) => (
        <div
          className="flex w-full justify-between flex-wrap"
          key={commodity.commodityId || commodity._id}
        >
          <div className="w-[49%]">
            <div className="flex w-full">
              <p className="m-0">
                <strong>Commodity</strong>
              </p>
              <Button
                text="(remove)"
                size={12}
                buttonClassName="ml-4 text-red-600 font-bold"
                action={() => removeCommmodity(commodity.commodityId)}
              />
            </div>
            <SearchSelect
              defaultValue={commodityOptions.find(
                (option) => option.value === commodity.commodityKey
              )}
              className="w-full"
              name={`commodityKey_${index}`}
              options={commodityOptions}
              onChange={handleInputChange}
              minChars={0}
            />
          </div>
          <div className="w-[49%]">
            <p className="m-0">
              <strong>Percent</strong>
            </p>
            <NumberInput
              className="w-full"
              name={`commodityPercentage_${index}`}
              value={commodity.commodityPercentage}
              onChange={handleInputChange}
            />
          </div>
        </div>
      ))}
      <Switch
        name="commoditiesRefrigeration"
        label="Refrigerated goods hauled"
        labelRight
        labelClassName="font-bold"
        className="my-4"
        onChange={onChange}
        default={data.commoditiesRefrigeration}
      />
    </Card>
  );
}

function Vehicles(props) {
  const data = props.data;
  const setData = props.setData;
  const context = useContext(ViewContext);

  const addVehicle = () => {
    context.modal.show(
      {
        title: "Vehicle",
        addVehicleRunQuote: true,
        progressive: progressive,
      },
      (form, res) => {
        if (res.Vehicle) {
          const arrayTrailer = res.Vehicle.vehicles.filter(
            (object) => object.typeOfVehicle === "trailer"
          );
          const arrayVehicles = res.Vehicle.vehicles.filter(
            (object) => object.typeOfVehicle !== "trailer"
          );
          setData({
            ...data,
            trailers: arrayTrailer,
            vehicles: arrayVehicles,
          });
        }
      }
    );
  };

  const removeVehicle = (vin) => {
    setData((prevState) => {
      return {
        ...prevState,
        vehicles: data.vehicles.filter((item) => item.vin !== vin),
      };
    });
  };

  const handleInputChange = (name, value) => {
    const vehicleName = name.split("_")[0];
    const vehicleIndex = name.split("_")[1];
    setData((prevState) => {
      const newItems = [...prevState.vehicles];
      newItems[vehicleIndex] = {
        ...newItems[vehicleIndex],
        [vehicleName]: value,
      };
      return {
        ...prevState,
        vehicles: newItems,
      };
    });
  };

  return (
    <Card className="mb-6" id="9">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Vehicles</h2>
        <div className="flex gap-4">
          <Button
            color="bluesign"
            className="text-sm"
            text="Add Vehicle"
            action={addVehicle}
          />
        </div>
      </header>
      {data.vehicles.length > 0 && (
        <div>
          {data.vehicles.map((vehicle, index) => (
            <div className="flex items-end gap-4 w-full" key={vehicle.vin}>
              <p className="mb-4 font-bold">{index + 1}</p>
              <NumberInput
                id={`vehicle_${index}`}
                label="Year"
                name={`year_${index}`}
                value={vehicle.year}
                containerClass="w-[10%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <TextInput
                label="Make"
                name={`make_${index}`}
                value={vehicle.make}
                containerClass="w-[18%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <TextInput
                label="Model"
                name={`model_${index}`}
                value={vehicle.model}
                containerClass="w-[18%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <TextInput
                label="VIN"
                id={`vehicleVIN_${index}`}
                value={vehicle.vin}
                containerClass="w-[18%]"
                className="w-full text-sm"
                readonly
              />
              <MoneyInput
                id={`vehicle_value_${index}`}
                label="Value"
                name={`value_${index}`}
                value={vehicle.value}
                containerClass="w-[18%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <Button
                icon="minus-square"
                iconColor="white"
                className="bg-[#3056d3] !w-10 h-10 rounded-md mb-2 ml-auto mr-4"
                action={() => removeVehicle(vehicle.vin)}
              />
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function Trailers(props) {
  const data = props.data;
  const setData = props.setData;
  const context = useContext(ViewContext);

  const addTrailer = () => {
    context.modal.show(
      {
        title: "Trailer",
        addTrailerRunQuote: true,
        progressive: progressive,
      },
      (form, res) => {
        if (res.Vehicle) {
          const arrayTrailer = res.Vehicle.vehicles.filter(
            (object) => object.typeOfVehicle === "trailer"
          );
          const arrayVehicles = res.Vehicle.vehicles.filter(
            (object) => object.typeOfVehicle !== "trailer"
          );
          setData({
            ...data,
            trailers: arrayTrailer,
            vehicles: arrayVehicles,
          });
        }
      }
    );
  };

  const removeTrailer = (vin) => {
    setData((prevState) => {
      return {
        ...prevState,
        trailers: data.trailers.filter((item) => item.vin !== vin),
      };
    });
  };

  const handleInputChange = (name, value) => {
    const vehicleName = name.split("_")[0];
    const vehicleIndex = name.split("_")[1];
    setData((prevState) => {
      const newItems = [...prevState.trailers];
      newItems[vehicleIndex] = {
        ...newItems[vehicleIndex],
        [vehicleName]: value,
      };
      return {
        ...prevState,
        trailers: newItems,
      };
    });
  };

  return (
    <Card className="mb-6" id="10">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Trailers</h2>
        <div className="flex gap-4">
          <Button
            color="bluesign"
            className="text-sm"
            text="Add Trailer"
            action={addTrailer}
          />
        </div>
      </header>
      {data.trailers.length > 0 && (
        <div>
          {data.trailers.map((vehicle, index) => (
            <div className="flex items-end gap-4 w-full" key={vehicle.vin}>
              <p className="mb-4 font-bold">{index + 1}</p>
              <NumberInput
                id={`trailer_${index}`}
                label="Year"
                name={`year_${index}`}
                value={vehicle.year}
                containerClass="w-[10%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <TextInput
                label="Make"
                name={`make_${index}`}
                id={`make_${index}`}
                value={vehicle.make}
                containerClass="w-[18%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <TextInput
                label="Model"
                id={`model_${index}`}
                name={`model_${index}`}
                value={vehicle.model}
                containerClass="w-[18%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <TextInput
                label="VIN"
                id={`trailerVIN_${index}`}
                value={vehicle.vin}
                containerClass="w-[18%]"
                className="w-full text-sm"
                readonly
              />
              <MoneyInput
                id={`trailer_value_${index}`}
                label="Value"
                name={`value_${index}`}
                value={vehicle.value}
                containerClass="w-[18%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <Button
                icon="minus-square"
                iconColor="white"
                className="bg-[#3056d3] !w-10 h-10 rounded-md mb-2 ml-auto mr-4"
                action={() => removeTrailer(vehicle.vin)}
              />
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function Drivers(props) {
  const data = props.data;
  const setData = props.setData;
  const context = useContext(ViewContext);

  const addDriver = () => {
    context.modal.show(
      {
        title: "Drivers",
        addDriverRunQuote: true,
        progressive: progressive,
      },
      (form, res) => {
        if (res.Driver) {
          setData({
            ...data,
            drivers: res.Driver.drivers,
          });
        }
      }
    );
  };

  const removeDriver = (id) => {
    setData((prevState) => {
      return {
        ...prevState,
        drivers: data.drivers.filter((item) => item.driverId !== id),
      };
    });
  };

  const handleInputChange = (name, value) => {
    const driverName = name.split("_")[0];
    const driverIndex = name.split("_")[1];
    setData((prevState) => {
      const newItems = [...prevState.drivers];
      newItems[driverIndex] = {
        ...newItems[driverIndex],
        [driverName]: value,
      };
      return {
        ...prevState,
        drivers: newItems,
      };
    });
  };

  return (
    <Card className="mb-6" id="11">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Drivers</h2>
        <div className="flex gap-4">
          <Button
            color="bluesign"
            className="text-sm"
            text="Add Driver"
            action={addDriver}
          />
        </div>
      </header>
      {data.drivers.length > 0 && (
        <div>
          {data.drivers.map((driver, index) => (
            <div className="flex items-end gap-4 w-full" key={driver.driverId}>
              <p className="mb-4 font-bold">{index + 1}</p>
              <TextInput
                label="Birth"
                value={driver.dateOfBirth}
                containerClass="w-[18%]"
                className="w-full text-sm"
                readonly
              />
              <TextInput
                label="First Name"
                id={`firstName_${index}`}
                name={`firstName_${index}`}
                value={driver.firstName}
                containerClass="w-[11%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <TextInput
                label="Last Name"
                id={`lastName_${index}`}
                name={`lastName_${index}`}
                value={driver.lastName}
                containerClass="w-[11%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <TextInput
                label="License"
                id={`licenseNumber_${index}`}
                name={`licenseNumber_${index}`}
                value={driver.licenseNumber}
                containerClass="w-[22%]"
                className="w-full text-sm"
                onChange={handleInputChange}
              />
              <Select
                label="State"
                name={`licenseState_${index}`}
                options={longUsStates}
                value={driver.licenseState}
                containerClass="w-[18%]"
                className="w-full text-sm mb-1"
                onChange={handleInputChange}
              />
              <Button
                icon="minus-square"
                iconColor="white"
                className="bg-[#3056d3] !w-10 h-10 rounded-md mb-2 ml-auto mr-4"
                action={() => removeDriver(driver.driverId)}
              />
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function Losses(props) {
  const data = props.data;
  const onChange = props.onChange;

  const thisYear = new Date().getFullYear();

  return (
    <Card id="12">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300">
        <h2 className="capitalize text-xl font-semibold pt-4">Losses</h2>
      </header>
      <p className="text-blue-500 text-xl">
        <strong>
          {thisYear - 1} - {thisYear}
        </strong>
      </p>
      <p>Physical damage, cargo</p>
      <div className="flex justify-between flex-wrap w-full">
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Auto liability: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossAlCount23"
            id="lossAlCount23"
            className="w-full"
            onChange={onChange}
            value={data.lossAlCount23}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0 whitespace-nowrap">
            <strong>Auto liability: Losses paid (Open or reserved)</strong>
          </p>
          <MoneyInput
            name="lossAlPaid23"
            id="lossAlPaid23"
            className="w-full"
            onChange={onChange}
            value={data.lossAlPaid23}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Physical damage: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossApdCount23"
            id="lossApdCount23"
            className="w-full"
            onChange={onChange}
            value={data.lossApdCount23}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Physical damage: Losses paid (Open or reserved)</strong>
          </p>
          <MoneyInput
            name="lossApdPaid23"
            id="lossApdPaid23"
            className="w-full"
            onChange={onChange}
            value={data.lossApdPaid23}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Cargo: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossMtcCount23"
            id="lossMtcCount23"
            className="w-full"
            onChange={onChange}
            value={data.lossMtcCount23}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Cargo: Losses paid (Open or reserved)</strong>
          </p>
          <MoneyInput
            name="lossMtcPaid23"
            id="lossMtcPaid23"
            className="w-full"
            onChange={onChange}
            value={data.lossMtcPaid23}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Loss count (Open or closed)
            </strong>
          </p>
          <NumberInput
            name="lossTglCount23"
            id="lossTglCount23"
            className="w-full"
            onChange={onChange}
            value={data.lossTglCount23}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Losses paid (Open or reserved)
            </strong>
          </p>
          <MoneyInput
            name="lossTglPaid23"
            id="lossTglPaid23"
            className="w-full"
            onChange={onChange}
            value={data.lossTglPaid23}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Non-Trucking Liability: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossNtlCount23"
            id="lossNtlCount23"
            className="w-full"
            onChange={onChange}
            value={data.lossNtlCount23}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Non-Trucking Liability: Losses paid (Open or reserved)
            </strong>
          </p>
          <MoneyInput
            name="lossNtlPaid23"
            id="lossNtlPaid23"
            className="w-full"
            onChange={onChange}
            value={data.lossNtlPaid23}
          />
        </div>
      </div>
      <p className="text-blue-500 text-xl mt-4">
        <strong>
          {thisYear - 2} - {thisYear - 1}
        </strong>
      </p>
      <p>Physical damage, cargo</p>
      <div className="flex justify-between flex-wrap w-full">
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Auto liability: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossAlCount22"
            id="lossAlCount22"
            className="w-full"
            onChange={onChange}
            value={data.lossAlCount22}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0 whitespace-nowrap">
            <strong>Auto liability: Losses paid (Open or reserved)</strong>
          </p>
          <MoneyInput
            name="lossAlPaid22"
            id="lossAlPaid22"
            className="w-full"
            onChange={onChange}
            value={data.lossAlPaid22}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Physical damage: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossApdCount22"
            id="lossApdCount22"
            className="w-full"
            onChange={onChange}
            value={data.lossApdCount22}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Physical damage: Losses paid (Open or reserved)</strong>
          </p>
          <MoneyInput
            name="lossApdPaid22"
            id="lossApdPaid22"
            className="w-full"
            onChange={onChange}
            value={data.lossApdPaid22}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Cargo: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossMtcCount22"
            id="lossMtcCount22"
            className="w-full"
            onChange={onChange}
            value={data.lossMtcCount22}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Cargo: Losses paid (Open or reserved)</strong>
          </p>
          <MoneyInput
            name="lossMtcPaid22"
            id="lossMtcPaid22"
            className="w-full"
            onChange={onChange}
            value={data.lossMtcPaid22}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Loss count (Open or closed)
            </strong>
          </p>
          <NumberInput
            name="lossTglCount22"
            id="lossTglCount22"
            className="w-full"
            onChange={onChange}
            value={data.lossTglCount22}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Losses paid (Open or reserved)
            </strong>
          </p>
          <MoneyInput
            name="lossTglPaid22"
            id="lossTglPaid22"
            className="w-full"
            onChange={onChange}
            value={data.lossTglPaid22}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Non-Trucking Liability: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossNtlCount22"
            id="lossNtlCount22"
            className="w-full"
            onChange={onChange}
            value={data.lossNtlCount22}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Non-Trucking Liability: Losses paid (Open or reserved)
            </strong>
          </p>
          <MoneyInput
            name="lossNtlPaid22"
            id="lossNtlPaid22"
            className="w-full"
            onChange={onChange}
            value={data.lossNtlPaid22}
          />
        </div>
      </div>
      <p className="text-blue-500 text-xl mt-4">
        <strong>
          {thisYear - 3} - {thisYear - 2}
        </strong>
      </p>
      <p>Physical damage, cargo</p>
      <div className="flex justify-between flex-wrap w-full">
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Auto liability: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossAlCount21"
            id="lossAlCount21"
            className="w-full"
            onChange={onChange}
            value={data.lossAlCount21}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0 whitespace-nowrap">
            <strong>Auto liability: Losses paid (Open or reserved)</strong>
          </p>
          <MoneyInput
            name="lossAlPaid21"
            id="lossAlPaid21"
            className="w-full"
            onChange={onChange}
            value={data.lossAlPaid21}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Physical damage: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossApdCount21"
            id="lossApdCount21"
            className="w-full"
            onChange={onChange}
            value={data.lossApdCount21}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Physical damage: Losses paid (Open or reserved)</strong>
          </p>
          <MoneyInput
            name="lossApdPaid21"
            id="lossApdPaid21"
            className="w-full"
            onChange={onChange}
            value={data.lossApdPaid21}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Cargo: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossMtcCount21"
            id="lossMtcCount21"
            className="w-full"
            onChange={onChange}
            value={data.lossMtcCount21}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Cargo: Losses paid (Open or reserved)</strong>
          </p>
          <MoneyInput
            name="lossMtcPaid21"
            id="lossMtcPaid21"
            className="w-full"
            onChange={onChange}
            value={data.lossMtcPaid21}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Loss count (Open or closed)
            </strong>
          </p>
          <NumberInput
            name="lossTglCount21"
            id="lossTglCount21"
            className="w-full"
            onChange={onChange}
            value={data.lossTglCount21}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Losses paid (Open or reserved)
            </strong>
          </p>
          <MoneyInput
            name="lossTglPaid21"
            id="lossTglPaid21"
            className="w-full"
            onChange={onChange}
            value={data.lossTglPaid21}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Non-Trucking Liability: Loss count (Open or closed)</strong>
          </p>
          <NumberInput
            name="lossNtlCount21"
            id="lossNtlCount21"
            className="w-full"
            onChange={onChange}
            value={data.lossNtlCount21}
          />
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Non-Trucking Liability: Losses paid (Open or reserved)
            </strong>
          </p>
          <MoneyInput
            name="lossNtlPaid21"
            id="lossNtlPaid21"
            className="w-full"
            onChange={onChange}
            value={data.lossNtlPaid21}
          />
        </div>
      </div>
    </Card>
  );
}
