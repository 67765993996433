/***
*
*   USERS
*   Enables an admin to manage the users in their application
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { ViewContext, Card, AccountNav, Table, Button, Animate, TitleRow,
  useAPI, usePermissions, Event } from 'components/lib';

export function Users(props){

  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const data = useAPI('/api/account/users');
  const user = useAPI('/api/user');
  const companiesData = useAPI('/api/user/getCompanies');
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);

  function invite(){

    context.modal.show({
      title: 'Add User',
      form: {
        name: {
          id: 'name',
          label: 'Name',
          type: 'text',
          class: 'w-full',
          containerClass: 'w-full',
          required: true,
        },
        phone: {
          for: 'phone',
          label: 'Phone',
          type: 'phone',
          class: 'w-full',
          containerClass: 'w-[49%]',
          required: true,
        },
        email: {
          id: 'email',
          label: 'Email',
          type: 'text',
          class: 'w-full',
          containerClass: 'w-[49%]',
          required: true,
        },
        permission: {
          label: 'Type',
          type: 'select',
          containerClass: 'w-[49%]',
          default: 'customer',
          value: 'customer',
          options: permissions?.data?.list?.filter(x => x.value !== 'owner' && x.value !== 'user' && x.value !== 'developer')
        },
        companyName: {
          id: 'companyName',
          label: 'Company Name',
          type: 'select',
          options: companies,
          class: 'w-full',
          containerClass: 'w-[49%]',
          readonly: true
        }
      },
      className:'flex flex-wrap justify-between',
      buttonText: 'Send Invite',
      buttonClass: 'flex justify-end w-full mt-4',
      url: '/api/invite',
      method: 'POST',

      }, (form, res) => {

        // add the invited user to the
        if (res.length){

          const state = [...users];

          res.forEach(invite => {
            if (!state.find(x => x.id === invite.id)){
              state.push({

                id: invite.id,
                name: form.name.value,
                email: invite.email,
                phone: form.phone.value || '',
                companyName: invite.companyName,
                date_created: invite.date_sent,
                type: form.permission.value || 'user',
                status: 'Invited',
                actions: {

                  invite: resendInvite,
                  delete: deleteInvite

                },
              });
            }
          });

          Event('invited_user');
          setUsers(state);

        }
    });
  }

  function editUser(data, callback){

    context.modal.show({
      title: 'Update User',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        },
        name: {
          label: 'Name',
          value: data.name || '',
          type: 'text',
          class: 'w-full',
          containerClass: 'w-full',
          required: true,
        },
        phone: {
          label: 'Phone',
          value: data.phone || '',
          type: 'phone',
          class: 'w-full',
          containerClass: 'w-[49%]',
          required: true,
        },
        email: {
          label: 'Email',
          value: data.email || '',
          type: 'text',
          class: 'w-full',
          containerClass: 'w-[49%]',
          required: true,
        },
        permission: {
          label: 'Permission',
          containerClass: 'w-[49%]',
          type: data.type === 'owner' ? null : 'select',
          options: permissions?.data?.list?.filter(x => x.value !== 'owner' && x.value !== 'user' && x.value !== 'developer'),
          default: data.type,
          value: data.type
        },
        companyName: {
          label: 'Company Name',
          type: 'select',
          options: companies,
          class: 'w-full',
          containerClass: 'w-[49%]',
          readonly: true
        }
      },
      className:'flex flex-wrap justify-between',
      buttonText: 'Save',
      buttonClass: 'flex justify-end w-full mt-4',
      url: '/api/user',
      method: 'PATCH'

    }, (res) => {

      context.notification.show(data.name + ' was updated', 'success', true);
      callback(res);

    });
  }

  function deleteUser(data, callback){

    context.modal.show({
      title: 'Remove User',
      form: {},
      buttonText: 'Remove',
      buttonClass: 'flex justify-center col-span-2 mt-10',
      text: `Are you sure you want to remove ${data.name}? They will lose access to the platform`,
      url: `/api/user/${data.id}`,
      method: 'DELETE',
      destructive: true

    }, () => {

      context.notification.show(data.name + ' was deleted', 'success', true);
      callback();

    });
  }

  function deleteInvite(data, callback){
    
    context.modal.show({
      title: 'Delete Invite',
      form: {},
      buttonText: 'Delete Invite',
      buttonClass: 'flex justify-center mt-10',
      text: `Are you sure you want to delete the invite for ${data.email}?`,
      url: `/api/invite/${data.id}`,
      method: 'DELETE',
      destructive: true

    }, () => {

      let state = [...users];
      state.splice(state.findIndex(x => x.id === data.id), 1);
      context.notification.show(`${data.email}'s invite was deleted`, 'success', true);
      setUsers(state);

    });
  }

  async function resendInvite(data){
    try {

      await axios({ 
        
        url: '/api/invite',
        method: 'post',
        data: { email: data.email }
      
      });
      
      context.notification.show(`Invite re-sent to ${data.email}`, 'success', true);

    }
    catch(err){

      context.handleError(err);

    }
  }
  
  useEffect(() => {

    // format the user list
    let list = [];

    if (data?.data?.users?.length){
      list = data.data.users.map(x => {
        return {

          id: x.id,
          name: x.name,
          email: x.email,
          phone: x.phone,
          date_created: x.date_created,
          type: x.permission,
          status: 'Registered'

        }
      })
    }

    if (data?.data?.invites?.length){
      data.data.invites.forEach(x => {
        list.push({

          id: x.id,
          name: x.name || '',
          email: x.email,
          phone: x.phone,
          date_created: x.date_sent,
          type: 'invited',
          status: 'invited',

        });
      });
    }

    setUsers(list);

    let companiesList = [];

    if (companiesData?.data?.length){
      companiesList = companiesData.data.map(x => {
        return {

          value: x.dotNumber,
          label: x.legalName

        }
      })
    }

    setCompanies(companiesList);

  }, [data, companiesData]);

  // attach the per row actions for invites
  if (users.length){
    users.forEach(u => {
      if (u.status === 'Invited'){
        u.actions = {

          invite: resendInvite,
          delete: deleteInvite

        }
      }
    })
  }

  return (
    <Fragment>

      <AccountNav />
      <Animate>

        <TitleRow title='Manage Users'>
          { ['owner', 'admin', 'developer'].includes(user?.data?.permission) &&
            <Button small text='Add User' icon='plus-circle' className='bg-blue-700 text-white rounded' action={ invite }/>
          }
        </TitleRow>

        <Card>
          <Table
            search
            className='restrict-width'
            data={ users }
            loading={ data.loading }
            show={['email', 'name', 'last_login', 'phone', 'type']}
            badge={{ col: 'type', color: 'blue', condition: [

              { value: 'admin', color: 'green' },
              { value: 'owner', color: 'blue' },
              { value: 'invited', color: 'red' }

            ]}}
            actions={{
              edit: user?.data?.permission !== 'customer' && editUser,
              delete: user?.data?.permission !== 'customer' && deleteUser,
              email: true }}
          />
        </Card>

      </Animate>
    </Fragment>
  );
}
