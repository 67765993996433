import { Text, View, Image } from "@react-pdf/renderer";
import Signature from "./signature/Feaster-Signature-Small.png"

function Holder({customerHolder}) {
  return (
    <View style={{ width: "95%", height: "130px" }}>
      <View
        style={{
          fontSize: "8px",
          fontFamily: "Helvetica-Bold",
          flexDirection: "row",
          padding: "2px"
        }}
      >
        <Text style={{ textAlign: "left" }}>CERTIFICATE HOLDER</Text>
        <Text style={{ textAlign: "center", flex: "1" }}>CANCELLATION</Text>
      </View>
      <View
        style={{
          border: "1.5px solid black",
          height: "80px"
        }}
      >
        <View style={{ flexDirection: "row", height: "100%" }}>
          <View
            style={{
              width: "50%",
              borderRight: "1.5px solid black",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Text
              style={{ width: "75%", textAlign: "center", fontSize: "8px" }}
            >
              {customerHolder}
            </Text>
          </View>
          <View style={{ width: "50%" }}>
            <Text
              style={{
                fontSize: "7px",
                fontFamily: "Helvetica-Bold",
                padding: "2px",
                borderBottom: "1px solid black"
              }}
            >
              SHOULD ANY OF THE ABOVE DESCRIBED POLICIES BE CANCELLED BEFORE THE
              EXPIRATION DATE THEREOF, NOTICE WILL BE DELIVERED IN ACCORDANCE
              WITH THE POLICY PROVISIONS
            </Text>
            <Text
              style={{
                fontSize: "6px",
                fontFamily: "Helvetica-Bold",
                padding: "2px"
              }}
            >
              AUTHORIZED REPRESENTATIVE
            </Text>
            <Image src={Signature} alt="logo" style={{ width: "40%", marginLeft: "120px" }} />
          </View>
        </View>
      </View>

      <View
        style={{
          fontSize: "8px",
          fontFamily: "Helvetica-Bold",
          padding: "3px"
        }}
      >
        <Text style={{ textAlign: "left" }}>ACORD 25 (2010/05)</Text>
        <Text style={{ textAlign: "right", marginTop: "-9px" }}>
          1988-2010 ACORD CORPORATION. All rights reserved.
        </Text>
      </View>
      <Text
        style={{
          textAlign: "center",
          fontSize: "8px",
          fontFamily: "Helvetica-Bold",
          paddingTop: "-1px"
        }}
      >
        The ACORD name and logo are registered marks of ACORD
      </Text>
    </View>
  );
}
export default Holder;
