/***
 *
 *   Company Notes Page
 *
 **********/

import { useContext, React, useState, useEffect } from "react";
import { Card, Table, Animate, useAPI, TitleRow, Button, ViewContext } from "components/lib";
import { CompanyNav } from "components/nav/company/companyNav";

export function Notes() {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const company = JSON.parse(localStorage.getItem("company"));
  const [notes, setNotes] = useState([]);

  const notesData = useAPI(
    `/api/company/myNotes?dotNumber=${company ? company["dot_#"] : ""}`
  );

  useEffect(() => {
    if (notesData.data?.Notes?.length > 0){
      setNotes(
        notesData.data.Notes.map(x => {
          return {
            note: x.noteBody,
            created: x.date ? x.date : "No date",
            user: x.userName
          }
        })
      )
    }
  }, [notesData]);

  const addNote = () => {

    context.modal.show(
      {
        title: "Add Note",
        form: {
          userName: {
            type: "hidden",
            value: user.name,
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          editedField: {
            type: "hidden",
            value: "Driver Created",
          },
          dotNumber: {
            type: "hidden",
            value: company["dot_#"],
          },
          noteBody: {
            id: "noteBody",
            label: "Notes",
            type: "textarea",
            class: "w-full",
            containerClass: "w-full mb-4",
            required: true,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Save",
        buttonClass: "flex justify-end w-full",
        url: "/api/company/addNote",
        method: "POST",
      },
      (form, res) => {
        if(res.Notes) {
          const note = {
  
            note: res.Notes.noteBody,
            created: res.Notes.date,
            user: res.Notes.userName
  
          }
          setNotes([...notes, note])
        }
      }
    );
  };

  return (
    <>
      <CompanyNav />

      <Animate type="pop">
        <TitleRow>
          {user.permission !== "customer" && (
            <>
              <Button
                small
                text="Add Note"
                icon="plus-circle"
                className="bg-blue-700 text-white rounded mr-4"
                action={addNote}
              />
            </>
          )}
        </TitleRow>

        <Card>
          <Table loading={notesData.loading} data={notes}
            sort="created" />
        </Card>
      </Animate>
    </>
  );
}
