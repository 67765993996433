import { Label, Error, Icon, ClassHelper } from "components/lib";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import moment from "moment";
import InputStyle from "../input.tailwind.js";
import Style from "./date.module.scss";

export function DateInput(props) {
  function normalizeDate(dateString) {
    if (!dateString) {
      return "";
    }

    const delimiter = dateString.includes("/") ? "/" : "-";

    const formats = [
      `MM${delimiter}DD${delimiter}YYYY`,
      `DD${delimiter}MM${delimiter}YYYY`,
      `YYYY${delimiter}DD${delimiter}MM`,
      "YYYY-MM-DDTHH:mm:ss.SSSZ",
    ];

    for (const format of formats) {
      const parsedDate = moment(dateString, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format("MM/DD/YYYY");
      }
    }

    return "";
  }

  // init default value
  const defaultValue = normalizeDate(props.value);
  let date = "";

  if (defaultValue && defaultValue.indexOf("/") && defaultValue.length >= 10) {
    date = defaultValue.split("/");

    date = {
      month: parseInt(date[0]),
      day: parseInt(date[1]),
      year: parseInt(date[2]),
    };
  } else {
    date = "";
  }

  function formatDateString(d) {
    return `${d.month < 10 ? "0" + d.month : d.month}/${
      d.day < 10 ? "0" + d.day : d.day
    }/${d.year}`;
  }

  function changeDatePicker(date) {
    let valid;

    if (props.required)
      valid = date.month && date.day && date.year ? true : false;

    props.onChange(props.name, formatDateString(date), valid);
  }

  const dateStyle = ClassHelper(InputStyle, {
    textbox: false,
    textboxDate: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
  });

  function customInput({ ref }) {
    return (
      <input
        ref={ref}
        value={props.value?.split("T")[0] || ""}
        placeholder={props.placeholder || "12/31/2000"}
        className={dateStyle}
        onChange={(e) =>
          props.onChange?.(props.name, e.target.value, undefined)
        }
      />
    );
  }

  return (
    <div className={InputStyle.input + " " + props.containerClass}>
      {props.label && <Label text={props.label} required={props.required} />}

      <DatePicker
        value={date}
        onChange={changeDatePicker}
        minimumDate={props.min}
        maximumDate={props.max}
        colorPrimary="#73B0F4"
        wrapperClassName={Style.date}
        renderInput={customInput}
        calendarPopperPosition={
          props.calendarPosition ? props.calendarPosition : "bottom"
        }
      ></DatePicker>

      {props.valid === false && (
        <Error message={props.errorMessage || "Please select a date"} />
      )}

      {props.valid === true && (
        <Icon
          image="check"
          color="#8CC57D"
          className={InputStyle.successIcon}
          size={16}
        />
      )}
    </div>
  );
}
