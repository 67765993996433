import CompanySearch from "views/companySearch";
import { ActivityLog } from "views/customers/company/activity";
import { Certificate } from "views/customers/company/certificates";
import { Company } from "views/customers/company/company";
import { Documents } from "views/customers/company/documents";
import { Drivers } from "views/customers/company/drivers";
import { Notes } from "views/customers/company/notes";
import { Policies } from "views/customers/company/policies/policies";
import { Policy } from "views/customers/company/policies/policy";
import { Quotes } from "views/customers/company/quotes";
import { Trailers } from "views/customers/company/trailers";
import { Vehicles } from "views/customers/company/vehicles";
import { Customers } from "views/customers/customers";
import { ReviewQuote } from "views/customers/reviewQuote";
import { RunQuote } from "views/customers/runQuote";
import { Summary } from "views/customers/summary";
import { Help } from "views/dashboard/help";
import { OnboardingView } from "views/onboarding/onboarding";
import { ProspectActivityLog } from "views/prospects/company/activity";
import { ProspectCompany } from "views/prospects/company/company";
import { ProspectDocuments } from "views/prospects/company/documents";
import { ProspectDrivers } from "views/prospects/company/drivers";
import { ProspectNotes } from "views/prospects/company/notes";
import { ProspectQuotes } from "views/prospects/company/quotes";
import { ProspectTrailers } from "views/prospects/company/trailers";
import { ProspectVehicles } from "views/prospects/company/vehicles";
import { Prospects } from "views/prospects/prospects";
import Requests from "views/requests/requests";

const Routes = [
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "customer",
    title: "Welcome",
  },
  {
    path: "/help",
    view: Help,
    layout: "app",
    permission: "customer",
    title: "Get Help",
  },
  {
    path: "/requests",
    view: Requests,
    layout: "app",
    permission: "sales",
    title: "Requests",
  },
  {
    path: "/prospects",
    view: Prospects,
    layout: "app",
    permission: "sales",
    title: "Quoted Prospects",
  },
  {
    path: "/prospects/company",
    view: ProspectCompany,
    layout: "app",
    permission: "customer",
    title: `Prospects Page - Company`,
  },
  {
    path: "/prospects/documents",
    view: ProspectDocuments,
    layout: "app",
    permission: "customer",
    title: "Prospects Page - Documents",
  },
  {
    path: "/prospects/drivers",
    view: ProspectDrivers,
    layout: "app",
    permission: "customer",
    title: "Prospects Page - Drivers",
  },
  {
    path: "/prospects/vehicles",
    view: ProspectVehicles,
    layout: "app",
    permission: "customer",
    title: "Prospects Page - Vehicles",
  },
  {
    path: "/prospects/trailers",
    view: ProspectTrailers,
    layout: "app",
    permission: "customer",
    title: "Customers Page - Trailers",
  },
  {
    path: "/prospects/activity",
    view: ProspectActivityLog,
    layout: "app",
    permission: "customer",
    title: "Prospects Page - Activity Log",
  },
  {
    path: "/prospects/notes",
    view: ProspectNotes,
    layout: "app",
    permission: "customer",
    title: "Prospects Page - Company Notes",
  },
  {
    path: "/prospects/runquote",
    view: RunQuote,
    layout: "app",
    permission: "customer",
    title: "Full Quote",
  },
  {
    path: "/prospects/quotes",
    view: ProspectQuotes,
    layout: "app",
    permission: "customer",
    title: "Quotes",
  },
  {
    path: "/prospects/summary",
    view: Summary,
    layout: "app",
    permission: "customer",
    title: "Summary",
  },
  {
    path: "/customers",
    view: Customers,
    layout: "app",
    permission: "sales",
    title: "Customers",
  },
  {
    path: "/customers/company",
    view: Company,
    layout: "app",
    permission: "customer",
    title: `Customers Page - Company`,
  },
  {
    path: "/customers/policies",
    view: Policies,
    layout: "app",
    permission: "customer",
    title: "Customers Page - Policies",
  },
  {
    path: "/customers/policies/:policy",
    view: Policy,
    layout: "app",
    permission: "customer",
    title: "Customers Page - Policy",
  },
  {
    path: "/customers/documents",
    view: Documents,
    layout: "app",
    permission: "customer",
    title: "Customers Page - Documents",
  },
  {
    path: "/customers/drivers",
    view: Drivers,
    layout: "app",
    permission: "customer",
    title: "Customers Page - Drivers",
  },
  {
    path: "/customers/vehicles",
    view: Vehicles,
    layout: "app",
    permission: "customer",
    title: "Customers Page - Vehicles",
  },
  {
    path: "/customers/trailers",
    view: Trailers,
    layout: "app",
    permission: "customer",
    title: "Customers Page - Trailers",
  },
  {
    path: "/customers/activity",
    view: ActivityLog,
    layout: "app",
    permission: "customer",
    title: "Customers Page - Activity Log",
  },
  {
    path: "/customers/notes",
    view: Notes,
    layout: "app",
    permission: "customer",
    title: "Customers Page - Company Notes",
  },
  {
    path: "/customers/runquote",
    view: RunQuote,
    layout: "app",
    permission: "customer",
    title: "Full Quote",
  },
  {
    path: "/customers/quotes",
    view: Quotes,
    layout: "app",
    permission: "customer",
    title: "Quotes",
  },
  {
    path: "/customers/summary",
    view: Summary,
    layout: "app",
    permission: "customer",
    title: "Summary",
  },
  {
    path: "/customers/certificate",
    layout: "app",
    view: Certificate,
    permission: "customer",
    title: "Certificates",
  },
  {
    path: "/review",
    view: ReviewQuote,
    layout: "app",
    permission: "customer",
    title: "Full Quote",
  },
  {
    path: "/searchcompany",
    view: CompanySearch,
    layout: "app",
    permission: "customer",
    title: "Search Company",
  },
];

export default Routes;
