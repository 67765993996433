/***
 *
 *   COMPANY NAV
 *   Company sub nav that renders different options based
 *   on user permissions. Shows billing & user admin to only account owners
 *
 **********/

import React, { useContext } from "react";
import { ViewContext, Button } from "components/lib";
import { CompanySubNav } from "./company";

export function CompanyNav(props) {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const company = JSON.parse(localStorage.getItem("company"));
  const clientType = company?.type === "prospect" ? "prospects" : "customers";

  const itemsArray =
    company?.type === "prospect"
      ? [
          { label: "Company", link: `/${clientType}/company` },
          { label: "Documents", link: `/${clientType}/documents` },
          { label: "Drivers", link: `/${clientType}/drivers` },
          { label: "Vehicles", link: `/${clientType}/vehicles` },
          { label: "Trailers", link: `/${clientType}/trailers` },
          { label: "Quotes", link: `/${clientType}/quotes` },
          { label: "Activity Log", link: `/${clientType}/activity` },
          { label: "Notes", link: `/${clientType}/notes` },
        ]
      : [
          { label: "Company", link: `/${clientType}/company` },
          { label: "Policies", link: `/${clientType}/policies` },
          { label: "Documents", link: `/${clientType}/documents` },
          { label: "Drivers", link: `/${clientType}/drivers` },
          { label: "Vehicles", link: `/${clientType}/vehicles` },
          { label: "Trailers", link: `/${clientType}/trailers` },
          { label: "Quotes", link: `/${clientType}/quotes` },
          { label: "Certificates", link: `/${clientType}/certificate` },
          { label: "Activity Log", link: `/${clientType}/activity` },
          { label: "Notes", link: `/${clientType}/notes` },
        ];

  const runQuote = () => {
    context.modal.show({
      title: "Select Quote",
      modalRunQuote: true,
      clientType: clientType,
    });
  };

  const saferRedirect = () => {
    window.open(
      `https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${company["dot_#"]}`,
      "_blank"
    );
  };

  const fmcsaRedirect = () => {
    window.open(
      `https://ai.fmcsa.dot.gov/SMS/Carrier/${company["dot_#"]}/Overview.aspx`,
      "_blank"
    );
  };

  return (
    <div className="flex justify-between items-center mb-4 -mt-8 -ml-5 -mr-6 pr-4 bg-[#fafafa]">
      <CompanySubNav items={itemsArray} />
      <div className="ml-auto">
        {["master", "owner", "admin", "sales"].includes(user.permission) && (
          <div className="flex flex-wrap gap-2">
            <Button
              small
              text="SAFER"
              color="bluesign"
              className="text-white rounded whitespace-nowrap"
              action={saferRedirect}
            />
            <Button
              small
              text="FMCSA"
              color="bluesign"
              className="text-white rounded whitespace-nowrap"
              action={fmcsaRedirect}
            />
            <Button
              small
              text="Run Quote"
              icon="clipboard"
              color="bluesign"
              className="text-white rounded whitespace-nowrap"
              action={runQuote}
            />
          </div>
        )}
      </div>
    </div>
  );
}
