import { Text, View } from "@react-pdf/renderer";
import FirstRow from "./firstRow";
import SecondRow from "./secondRow";
import ThirdRow from "./thirdRow";
import FourthRow from "./fourthRow";
import FifthRow from "./fifthRow";
import SixthRow from "./SixthRow";

function PartTwo({ coverages, carrierLetters }) {
  return (
    <View style={{ width: "95%" }}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: "8px",
          color: "#000000",
          marginTop: "2px",
          fontFamily: "Helvetica-Bold",
          fontSize: "9px"
        }}
      >
        <Text>COVERAGES</Text>
        <Text>CERTIFICATE NUMBER:</Text>
        <View
          style={{
            alignSelf: "flex-end"
          }}
        >
          <Text>REVISION NUMBER:</Text>
        </View>
      </View>
      <View style={{ borderTop: "1.5px solid black", borderLeft: "1.5px solid black", borderRight: "1.5px solid black" }}>
        <Text
          style={{
            fontSize: "7px",
            paddingHorizontal: "6px",
            paddingVertical: "2px",
            lineHeight: "1.25",
            borderBottom: "1px solid black"
          }}
        >
          THIS IS TO CERTIFY THAT POLICIES OF INSURANCE LISTED BELOW HAVE BEEN
          ISSUED TO THE INSURED NAME ABOVE THE POLICY PERIOD INDICATED. NOT
          WITHSTANDING ANY REQIUREMENT, THEM OR CONDITION OF ANY CONTRACT OR
          OTHER DOCUMENT WITH RESPECT TO WHICH THIS CERTIFICATE MAY BE ISSUED OR
          MAY PERTAIN, THE INSURANCE AFFORDED BY THE POLICIES DESCRIBED HEREIN
          IS SUBJECT TO ALL THE TERMS, EXCLUSIONS AND CONDITIONS OF SUCH
          POLICIES. LIMITS SHOWN MAY HAVE BEEN REDUCED BY PAID CLAIMS.
        </Text>
        <View
          style={{
            height: "300px",
            fontSize: "6px",
            fontFamily: "Helvetica-Bold"
          }}
        >
          <FirstRow general={coverages?.tgl} letter={carrierLetters.tgl} />
          <SecondRow automobile={coverages?.al} letter={carrierLetters.al} />
          <ThirdRow />
          <FourthRow />
          <FifthRow motor={coverages?.mtc} letter={carrierLetters.mtc} />
          <SixthRow physical={coverages?.apd} letter={carrierLetters.apd} />
        </View>
      </View>
    </View>
  );
}
export default PartTwo;
