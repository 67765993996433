import { Error, Label } from 'components/lib.js';
import React, { useState } from 'react'
import Style from './../input.tailwind.js';
import WindowedSelect from "react-windowed-select";

export const SearchSelect = (props) => {

  const error = props.errorMessage || 'Please enter a value';
  const minChars = props.minChars || 0;
  const messages = {
    typingRequired: `Type at least ${minChars} characters`,
    noDataFound: `No data found`,
  }

  const [options, setOptions] = useState(minChars === 0 ? props.options : []);
  const [emptyMessage, setEmptyMessage] = useState(messages.typingRequired);

  const onInputChange = (inputText)=>{
    if(inputText.length >= minChars) {
      const optionsFiltered = props.options.filter(option => option.label.toLowerCase().includes(inputText.toLowerCase()));
      setOptions(optionsFiltered);
      if(optionsFiltered.length === 0);
      setEmptyMessage(messages.noDataFound);
    } else {
      minChars !== 0 && setEmptyMessage(messages.typingRequired);
    }
  }

  function change(e){

    let value = e ? e.value : 'unselected';
    let valid = undefined;

    // validate
    valid = props.required && value === 'unselected' ? false : true;
    props.onChange(props.name, value, valid);
    props.callback && props.callback(value);

  }
  
  return (
    <div className={ Style.input  + " " + props.containerClass }>

      { props.label && 
        <Label
          text={ props.label }
          required={ props.required }
          for={ props.name }
        /> }
      <WindowedSelect
        defaultValue={props.defaultValue}
        className='mb-8'
        options={options}
        onChange={ e => change(e) }
        onInputChange={onInputChange}
        noOptionsMessage={() => emptyMessage}
        isOptionDisabled={(option) => option.value === undefined}
        id={ props.name }
        maxMenuHeight={props.maxMenuHeight || 200}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            padding: '.4rem',
          }),
        }}
      />
      
      { props.valid === false &&
        <Error message={ error }/> }
    </div>
  )
}
