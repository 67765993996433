import { Text, Document, Page, View } from "@react-pdf/renderer";
import Header from "./components/header";
import PartOne from "./components/partOne";
import PartTwo from "./components/partTwo/partTwo";
import Desription from "./components/description";
import Holder from "./components/holder";

function Certificate({ data, customerHolder, remark }) {

  let letterIndex = 0;
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const carrierLetters = {
    tgl: "",
    al: "",
    mtc: "",
    apd: "",
  };

  function extractCarrierInfo(coverages) {
    const order = ["tgl", "al", "mtc", "apd"];
    const result = [];

    for (const key of order) {
      const carrierInfo = coverages[key];
      if (carrierInfo) {
        const { carrier, carrier_naic } = carrierInfo;
        result.push({ carrier, carrier_naic });
        carrierLetters[key] = alphabet[letterIndex++];
      }
    }

    return result;
  }
  const extractedInfo = extractCarrierInfo(data.coverages);

  return (
    <Document title="Certificate of Insurance">
      <Page size="A4">
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Header />
          <PartOne
            insured={data.insuredInformation}
            carrierInfo={extractedInfo}
          />
          <PartTwo coverages={data.coverages} carrierLetters={carrierLetters} />
          <Desription vehicles={data.vehicles} remark={remark} drivers={data.drivers} />
          <Holder customerHolder={customerHolder} />
        </View>
      </Page>
      {(data.vehicles?.length ?? 0) + (data.drivers?.length ?? 0) > 5 && (data.vehicles?.length ?? 0) + (data.drivers?.length ?? 0) < 61 &&
        <Page size="A4">
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Header />
            <Text
              style={{
                marginTop: "20px",
              }}
            />
            <Desription vehicles={data.vehicles} remark={remark} drivers={data.drivers} secondDescription />
          </View>
        </Page>
      }
      {(data.vehicles?.length ?? 0) + (data.drivers?.length ?? 0) > 60 &&
      <>
        <Page size="A4">
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Header />
            <Text
              style={{
                marginTop: "20px",
              }}
            />
            <Desription vehicles={data.vehicles} remark={remark} secondDescription />
          </View>
        </Page>
        <Page size="A4">
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Header />
            <Text
              style={{
                marginTop: "20px",
              }}
            />
            <Desription remark={remark} drivers={data.drivers} secondDescription />
          </View>
        </Page>
      </>
      }
    </Document>
  );
}
export default Certificate;
