/***
 *
 *   Policies Page
 *
 **********/

import { React, Fragment } from "react";
import {
  Card,
  // TitleRow,
  // Button,
  // ViewContext,
  TabView,
  Animate,
  Table,
  useAPI
} from "components/lib";
import { CompanyNav } from "components/nav/company/companyNav";

export function Policies(props) {
  // const context = useContext(ViewContext);
  // const user = JSON.parse(localStorage.getItem("user"));
  const company = JSON.parse(localStorage.getItem("company"));
  const policy = useAPI(`/api/policy/get/${company ? company["dot_#"] : ""}`);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  const transformedCoverages = (coverages) => {
    const nameMap = {
      al: "Auto Liability",
      apd: "Auto Physical Damage",
      mtc: "Motor Truck Cargo",
      ntl: "Non Trucking Liability",
      gl: "General Liability"
    };

    return Object.keys(coverages).map((key) => {
      const coverage = coverages[key];
      const name = nameMap[key] || key.toUpperCase();
      return {
        name,
        limit: coverage.limit.toString(),
        premium: parseFloat(coverage.premium).toFixed(2),
        policy_number: coverage.policy_number || "",
        effective_date: formatDate(coverage.policy_effective_date),
        expiration_date: formatDate(coverage.policy_expiration_date),
        carrier: coverage.carrier,
        carrier_NAIC: coverage.carrier_naic.toString(),
        deductible: coverage.deductible ? coverage.deductible.toString() : "0"
      };
    });
  };

  function transformVehicles(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    const transformedData = data.map((item) => {
      return {
        ...item,
        VIN: item.vin,
        vin: undefined,
        loss_payee: undefined,
        body_type: item.body_type
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase())
      };
    });

    return transformedData;
  }

  function transformPolicyData(policy, company) {
    const transformedData = policy.map((item) => {
      const {
        coverages,
        documents,
        premiumFinanceDetails,
        insuredInformation,
        broker
      } = item.result.data || {};

      const coveragesData =
        coverages && typeof coverages === "object" ? coverages : {};

      const coverageKeys = Object.keys(coveragesData);
      const coverageKeysString = coverageKeys.join(", ").toUpperCase();

      const totalPremium = coverageKeys
        .reduce((total, key) => {
          return (
            total +
            (coveragesData[key].premium
              ? parseFloat(coveragesData[key].premium)
              : 0)
          );
        }, 0)
        .toLocaleString("en-US");

      const firstEffectiveDate = coverageKeys.reduce((date, key) => {
        if (!date && coveragesData[key]?.policy_effective_date) {
          return coveragesData[key].policy_effective_date;
        }
        return date;
      }, null);

      const policyData = {
        effective_date: firstEffectiveDate,
        carrier: "Cover Whale",
        policy_number:
          item.result.data?.submissionStatus?.submission_number || "no_number",
        premium: totalPremium,
        downPayment: 
          premiumFinanceDetails?.downPayment
            ? premiumFinanceDetails.downPayment
            : 0,
        policy_Type: coverageKeysString,
        legalName: insuredInformation
          ? insuredInformation.legal_name || ""
          : "No Name",
        dotNumber: company["dot_#"],
        agent: `${broker ? broker.first_name || "" : ""} ${
          broker ? broker.last_name || "" : ""
        }`,
        coverages: transformedCoverages(coveragesData),
        vehicles: transformVehicles(item.result.data?.vehicles),
        drivers: [],
        // drivers: item.result.data.drivers || [],
        documents,
      };

      return policyData;
    });

    return transformedData;
  }

  let boundData = [];
  let pendingData = [];
  let canceledData = [];
  let expiredData = [];

  if (policy.data?.Bound?.length > 0) {
    boundData = transformPolicyData(policy.data.Bound, company);
  }

  if (policy.data?.["Pending Cancelation"]?.length > 0) {
    pendingData = transformPolicyData(
      policy.data["Pending Cancelation"],
      company
    );
  }

  if (policy.data?.Canceled?.length > 0) {
    canceledData = transformPolicyData(policy.data.Canceled, company);
  }

  if (policy.data?.Expired?.length > 0) {
    expiredData = transformPolicyData(policy.data.Expired, company);
  }

  // const addPolicy = () => {
  //   context.modal.show(
  //     {
  //       form: {
  //         userName: {
  //           type: "hidden",
  //           value: user.name,
  //         },
  //         user_id: {
  //           type: "hidden",
  //           value: user.accounts[0].user_id,
  //         },
  //         policyCompany: {
  //           type: "hidden",
  //           value: "policyCompany",
  //         },
  //         editedField: {
  //           type: "hidden",
  //           value: "Policy Created",
  //         },
  //         policyTitle: {
  //           title: "Add Policy",
  //           type: "header",
  //           class: "w-full",
  //         },
  //         policyDate: {
  //           label: "Effective Date",
  //           type: "date",
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-[22%]",
  //         },
  //         policyTerm: {
  //           id: "policyTerm",
  //           label: "Term Length",
  //           type: "text",
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-[22%]",
  //         },
  //         policyCarrier: {
  //           id: "policyCarrier",
  //           label: "Broker / Carrier",
  //           type: "text",
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-[48%]",
  //         },
  //         policyNumber: {
  //           label: "Policy Number",
  //           type: "number",
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-[22%]",
  //         },
  //         policyPremium: {
  //           label: "Premium",
  //           type: "money",
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-[22%]",
  //         },
  //         policyDownPayment: {
  //           id: "policyDownPayment",
  //           label: "Down-payment",
  //           type: "money",
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-[22%]",
  //         },
  //         policyType: {
  //           label: "Policy Type",
  //           type: "select",
  //           default: "policy1",
  //           value: "policy1",
  //           options: [
  //             { value: "policy1", label: "policy1" },
  //             { value: "policy2", label: "policy2" },
  //           ],
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-[22%]",
  //         },
  //         policyLeadSource: {
  //           id: "policyLeadSource",
  //           label: "Lead Source",
  //           type: "text",
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-full",
  //         },
  //         policyVehiclesBreak: {
  //           title: "Vehicles",
  //           type: "header",
  //           containerClass: "w-full",
  //           class: "w-full text-xs mb-0",
  //         },
  //         policyVehiclesArray: {
  //           type: "vehicles",
  //         },
  //         policyCoveragesBreak: {
  //           title: "Coverages",
  //           type: "header",
  //           class: "w-full text-xs mb-0",
  //         },
  //         policyCoverageType: {
  //           label: "Coverage Type",
  //           type: "select",
  //           default: "Coverage1",
  //           value: "Coverage1",
  //           options: [
  //             { value: "Coverage1", label: "Coverage1" },
  //             { value: "Coverage2", label: "Coverage2" },
  //           ],
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-[75%]",
  //         },
  //         policyCoverageLimit: {
  //           label: "Coverage Limit",
  //           type: "select",
  //           default: "Limit1",
  //           value: "Limit1",
  //           options: [
  //             { value: "Limit1", label: "Limit 1" },
  //             { value: "Limit2", label: "Limit 2" },
  //           ],
  //           class: "w-full text-xs mb-0",
  //           containerClass: "w-[22%]",
  //         },
  //       },
  //       className: "flex flex-wrap justify-between",
  //       buttonText: "Save",
  //       buttonClass: "flex justify-end w-full mt-4",
  //       url: "/api/something",
  //       method: "POST",
  //     },
  //     (form, res) => {
  //       //   console.log(res);
  //     }
  //   );
  // };

  return (
    <Fragment>
      <CompanyNav />

      <Animate type="pop">
        {/* <TitleRow>
          {!["customer", "sales"].includes(user.permission) && (
            <>
              <Button
                small
                text="Add Policy"
                icon="plus-circle"
                className="bg-blue-700 text-white rounded mr-4"
                action={addPolicy}
              />
            </>
          )}
        </TitleRow> */}

        <TabView
          name="policy"
          labels={[
            `Active (${boundData.length})`,
            `Pending Cancelation (${pendingData.length})`,
            `Canceled (${canceledData.length})`,
            `Expired (${expiredData.length})`
          ]}
        >
          <Card className="pb-14">
            <Table
              loading={policy.loading}
              data={boundData}
              show={[
                "effective_date",
                "policy_number",
                "carrier",
                "premium",
                "policy_Type"
              ]}
              actions={{
                viewPolicy: {
                  col: "policy_number"
                }
              }}
              paginate
              paginateLimit="5"
            ></Table>
          </Card>

          <Card className="pb-14">
            <Table
              loading={policy.loading}
              data={pendingData}
              show={[
                "effective_date",
                "policy_number",
                "carrier",
                "premium",
                "policy_Type"
              ]}
              actions={{
                viewPolicy: {
                  col: "policy_number"
                }
              }}
              paginate
              paginateLimit="5"
            ></Table>
          </Card>

          <Card className="pb-14">
            <Table
              loading={policy.loading}
              data={canceledData}
              show={[
                "effective_date",
                "policy_number",
                "carrier",
                "premium",
                "policy_Type"
              ]}
              actions={{
                viewPolicy: {
                  col: "policy_number"
                }
              }}
              paginate
              paginateLimit="5"
            ></Table>
          </Card>

          <Card className="pb-14">
            <Table
              loading={policy.loading}
              data={expiredData}
              show={[
                "effective_date",
                "policy_number",
                "carrier",
                "premium",
                "policy_Type"
              ]}
              actions={{
                viewPolicy: {
                  col: "policy_number"
                }
              }}
              paginate
              paginateLimit="5"
            ></Table>
          </Card>
        </TabView>
      </Animate>
    </Fragment>
  );
}
