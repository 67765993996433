import { useState, useRef } from 'react';
import { Icon, Button, ClassHelper } from 'components/lib';
import { Label } from '../label/label';
import { Error } from '../error/error';
import Style from '../file/file.tailwind.js';

const DriversFilesForm = (props) => {

  const fileInputRef1 = useRef();
  const fileInputRef2 = useRef();
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [valid, setValid] = useState(undefined);
  const [error, setError] = useState(props.errorMessage || 'Please select a file');

  function validate1(files){

    let fileStore1 = {};

    // check for max files
    if ((files.length + (props.value?.length || 0)) > 1){

      setValid(false);
      setError(`Maximum of ${1} file`);
      return false;

    }
    else {

      // check files exist
      if (files.length){
        for (let i = 0; i < files.length; i++){

          const file = files[i];
          const type = file.type.substring(file.type.indexOf('/')+1).toString();

          // validate file type
          if (!['jpg', 'png', 'jpeg'].includes(type)){

            setValid(false);
            setError('.' + file.type.substring(file.type.indexOf('/')+1) + ' files are not allowed');

          }
          
          else {

            // store the file in form store
            setValid(true);
            fileStore1 = { 
              
              name: file.name, 
              data: file, 
              url: URL.createObjectURL(file), 
              size: file.size, 
              type: file.type 
             
            };
            setImage1({ 
              
              name: file.name, 
              data: file, 
              url: URL.createObjectURL(file), 
              size: file.size, 
              type: file.type 
             
            });
          }
        }

        props.onChange('photos', {name: 'photos', value: [fileStore1, image2.length === 0 ? undefined : image2]}, valid);

      }
    }
  }

  function validate2(files){

    let fileStore2 = {};

    // check for max files
    if ((files.length + (props.value?.length || 0)) > 1){

      setValid(false);
      setError(`Maximum of ${1} file`);
      return false;

    }
    else {

      // check files exist
      if (files.length){
        for (let i = 0; i < files.length; i++){

          const file = files[i];
          const type = file.type.substring(file.type.indexOf('/')+1).toString();

          // validate file type
          if (!['jpg', 'png', 'jpeg'].includes(type)){

            setValid(false);
            setError('.' + file.type.substring(file.type.indexOf('/')+1) + ' files are not allowed');

          }
          
          else {

            // store the file in form store
            setValid(true);
            fileStore2 ={ 
              
              name: file.name, 
              data: file, 
              url: URL.createObjectURL(file), 
              size: file.size, 
              type: file.type 
             
            };
            setImage2({ 
              
              name: file.name, 
              data: file, 
              url: URL.createObjectURL(file), 
              size: file.size, 
              type: file.type 
             
            });
          }
        }

        props.onChange('photos', {name: 'photos', value: [image1.length === 0 ? undefined : image1, fileStore2]}, valid);

      }
    }
  }

  function deleteFile1(file){

    fileInputRef1.current.value = '';

    props.onChange(props.name, [{ 
              
      name: file.name, 
      size: file.size, 
      type: file.type 
     
    }], true);
  }

  function deleteFile2(file){

    fileInputRef2.current.value = '';

    props.onChange(props.name, [{ 
              
      name: file.name, 
      size: file.size, 
      type: file.type 
     
    }], true);
  }

  function onDrag(e, state){

    e.preventDefault();
    e.stopPropagation();
    setDragging(state);

  }

  const fileStyle = ClassHelper(Style, {      

     dropzone: true,
     dragging: dragging,
     error: valid === false ? true : false,
     success: props.value?.length && valid === true

  });

  return(
    <>
      <div className={ Style.file + ' w-[49%] mt-4' }>

        <Label
          text="Driver's License Front Picture"
          required={ props.required }
          for={ props.name }
        />

        <div className={ fileStyle }

          onClick={ e => fileInputRef1.current.click() }
          onDragOver={ e => onDrag(e, true) }
          onDragEnter={ e => onDrag(e, true) }
          onDragLeave={ e => onDrag(e, false) }
          onDrop={ e => { 
            
            onDrag(e, false)
            validate1(e.dataTransfer.files)
            
          }}>

          { /* fallback for old browsers */}
          <input 
            type='file' 
            className={ Style.legacyInput }
            files={ props.value?.value?.[0] }
            ref={ fileInputRef1 } 
            onChange={ e => {

              validate1(fileInputRef1.current.files)

          }}/>

          <div className={ Style.label }>

            <Icon image={ props.value?.value?.[0] ? 'check' : 'upload' } className={ Style.labelIcon } color='darkblue'/>

            { props.value?.value?.[0] ?
              <FileList1 files={ props.value.value[0] } delete={ deleteFile1 }/> :
              <div className={ Style.labelText }>Drag & Drop file or browse</div>
            }

          </div>
        </div>
          
        { valid === false && <Error message={ error }/> }

      </div>
      <div className={ Style.file + ' w-[49%] mt-4' }>

        <Label
          text="Driver's License Back Picture"
          required={ props.required }
          for={ props.name }
        />

        <div className={ fileStyle }

          onClick={ e => fileInputRef2.current.click() }
          onDragOver={ e => onDrag(e, true) }
          onDragEnter={ e => onDrag(e, true) }
          onDragLeave={ e => onDrag(e, false) }
          onDrop={ e => { 
            
            onDrag(e, false)
            validate2(e.dataTransfer.files)
            
          }}>

          { /* fallback for old browsers */}
          <input 
            type='file' 
            className={ Style.legacyInput }
            files={ props.value?.[1] }
            ref={ fileInputRef2 } 
            onChange={ e => {

              validate2(fileInputRef2.current.files)

          }}/>

          <div className={ Style.label }>

            <Icon image={ props.value?.value?.[1] ? 'check' : 'upload' } className={ Style.labelIcon } color='darkblue'/>

            { props.value?.value?.[1] ?
              <FileList2 files={ props.value.value[1] } delete={ deleteFile2 }/> :
              <div className={ Style.labelText }>Drag & Drop file or browse</div>
            }

          </div>
        </div>
          
        { valid === false && <Error message={ error }/> }

      </div>
    </>
  );
}

function FileList1(props){

  if (!props.files)
    return false;

  return (
    <div>
      <div className={ Style.fileListItem }>
        <span>{ props.files.name }</span>

        <Button 
          icon='x' 
          color='#d95565' 
          size={ 13 } 
          className={ Style.fileListButton }
          action={ e => props.delete(props.files) }
        />
      </div>
    </div>
  )
};

function FileList2(props){

  if (!props.files)
    return false;

  return (
    <div>
      <div className={ Style.fileListItem }>
        <span>{ props.files.name }</span>

        <Button 
          icon='x' 
          color='#d95565' 
          size={ 13 } 
          className={ Style.fileListButton }
          action={ e => props.delete(props.files) }
        />
      </div>
    </div>
  )
};

export default DriversFilesForm;
