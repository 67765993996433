import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, Loader, useAPI } from "components/lib";

export function ReviewQuote() {
  const location = useLocation();
  const { state } = location;
  const { data } = location.state || {};

  const {
    al,
    apd,
    mtc,
    ntl,
    tgl,
    submission_number
  } = data?.quote.data?.coverages || {};
  
  const autoLiability = al?.totalCost || al?.premium || 0;
  const physicalDamage = apd?.totalCost || apd?.premium || 0;
  const motorTruckCargo = mtc?.totalCost || mtc?.premium || 0;
  const nonTruckingLiability = ntl?.totalCost || ntl?.premium || 0;
  const generalLiability = tgl?.totalCost || tgl?.premium || 0;
  
  const totalCost =
    autoLiability * 1 +
    physicalDamage * 1 +
    motorTruckCargo * 1 +
    nonTruckingLiability * 1 +
    generalLiability * 1;
  
  const submissionNumber = data?.submission_number || submission_number || "No submission number";

  const submissionData = useAPI(`/api/policy/${submissionNumber}`);

  function formatKey(key) {
    const words = key.split(/(?=[A-Z])|_/);
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const formattedPremiumFinanceDetailsArray = submissionData.data?.Policy?.data
    ?.premiumFinanceDetails
    ? Object.entries(submissionData.data.Policy.data.premiumFinanceDetails).map(
        ([key, value]) => ({
          key: key === "effectiveAPR" ? "Effective APR" : formatKey(key),
          value: key === "effectiveAPR" ? `${value} %` : value,
        })
      )
    : [];

  const pdfArray = submissionData.data?.Policy?.data?.documents?.new_business
    ? Object.entries(
        submissionData.data?.Policy?.data?.documents?.new_business
      ).map(([key, value]) => ({
        name: key.replace(/_/g, " ").toUpperCase().replace("PDF", ""),
        url: value,
      }))
    : [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDownload = (pdfLink) => {
    const link = document.createElement("a");
    link.href = pdfLink;
    link.target = "_blank";
    link.download = "documentName.pdf";

    link.setAttribute("download", "true");

    link.click();
  };

  function formatPrice(price) {
    if (price === undefined || price === null) {
      return "";
    }

    if (
      typeof price === "string" &&
      !isNaN(parseFloat(price)) &&
      price.trim().slice(-1) !== "%"
    ) {
      price = parseFloat(price);
    }

    if (typeof price === "number" && !isNaN(price)) {
      return price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      return price.toUpperCase();
    }
  };

  const quoteRedirect = () => {
    window.open(
      `https://app.coverwhale.com/transportation/${submissionNumber}/0`,
      "_blank"
    );
  };

  if (!state || !state.data) {
    return <div>No data available</div>;
  }

  return (
    <Card className="mb-6 w-1/2 mx-auto flex flex-col items-center">
      {submissionNumber !== "No submission number" &&
        <Button
          text="View in CoverWhale"
          color="bluesign"
          className="text-white rounded whitespace-nowrap absolute -right-60"
          action={quoteRedirect}
        />
      }
      <h2 className="capitalize text-xl text-center font-semibold pt-4">
        Total Premium
      </h2>
      <h3 className="text-8xl text-center text-black font-bold">
        {formatPrice(totalCost)}
      </h3>
      <div className="border rounded p-4 mt-4 border-slate-300 w-full">
        <div className="flex justify-between font-bold pb-4 pt-2 border-b border-slate-300">
          <p>Coverages</p>
          <p>Premium</p>
        </div>
        {autoLiability !== 0 && (
          <div className="flex justify-between py-4 border-b border-slate-300">
            <p>Auto Liability</p>
            <p>{formatPrice(autoLiability)}</p>
          </div>
        )}
        {physicalDamage !== 0 && (
          <div className="flex justify-between py-4 border-b border-slate-300">
            <p>Physical Damage</p>
            <p>{formatPrice(physicalDamage)}</p>
          </div>
        )}
        {motorTruckCargo !== 0 && (
          <div className="flex justify-between py-4 border-b border-slate-300">
            <p>Motor Truck Cargo</p>
            <p>{formatPrice(motorTruckCargo)}</p>
          </div>
        )}
        {nonTruckingLiability !== 0 && (
          <div className="flex justify-between py-4 border-b border-slate-300">
            <p>Non Trucking Liability</p>
            <p>{formatPrice(nonTruckingLiability)}</p>
          </div>
        )}
        {generalLiability !== 0 && (
          <div className="flex justify-between pb-2 pt-4">
            <p>General Liability</p>
            <p>{formatPrice(generalLiability)}</p>
          </div>
        )}
      </div>
      {submissionData.loading ? (
        <div className="mt-10 flex flex-col items-center">
          <p className="font-bold mb-4">Loading quote documents…</p>
          <Loader className="w-12 h-12" loading="new loading" />
        </div>
      ) : (
        <>
          {submissionData.data?.Policy?.data?.premiumFinanceDetails && (
            <div className="border rounded p-4 mt-4 border-slate-300 w-full">
              <div className="font-bold pb-4 pt-2 border-b border-slate-300">
                <p>Premium Finance Details</p>
              </div>
              {formattedPremiumFinanceDetailsArray.map((premium, index) => (
                <div
                  className={`flex justify-between py-4 ${
                    index === formattedPremiumFinanceDetailsArray.length - 1
                      ? "border-none"
                      : "border-b"
                  } border-slate-300`}
                  key={premium.key}
                >
                  <p>{premium.key}</p>
                  <p>{formatPrice(premium.value)}</p>
                </div>
              ))}
            </div>
          )}
          <div className="border rounded p-4 mt-4 border-slate-300 w-full">
            <div className="font-bold pb-4 pt-2 border-b border-slate-300">
              <p>Document Name</p>
            </div>
            {pdfArray.map((pdf, index) => (
              <div
                className={`flex justify-between py-4 pr-4 ${
                  index === pdfArray.length - 1 ? "border-none" : "border-b"
                } border-slate-300`}
                key={index}
              >
                <p>{pdf.name}</p>
                <Button
                  icon="download-cloud"
                  size={10}
                  action={() => handleDownload(pdf.url)}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  );
}
