import { useContext, useState } from 'react';
import { ViewContext, Button, Fieldset, Form, TextInput } from 'components/lib';
import Axios from 'axios';

const AddTrailerForm = (props) => {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem('company'));
  const [loading, setLoading] = useState(false);
  const [validVIN, setValidVIN] = useState(true);
  const [step, setStep] = useState(true);
  const [ownVIN, setOwnVIN] = useState(false);
  const [VIN, setVIN] = useState('');
  const [modalData, setModalData] = useState({
    year: '',
    make: '',
    model: '',
    value: '',
    status: ''
  });
  const bodyTypeOptions = [
    {"value": "auto_transporter_trailer", "label": "Auto Transporter Trailer"},
    {"value": "chip_trailer", "label": "Chip Trailer"},
    {"value": "curtain_van_trailer", "label": "Curtain Van Trailer"},
    {"value": "double_van_trailer", "label": "Double Van Trailer"},
    {"value": "dry_bulk_trailer", "label": "Dry Bulk Trailer"},
    {"value": "dry_van_trailer", "label": "Dry Van Trailer"},
    {"value": "dump_trailer", "label": "Dump Trailer"},
    {"value": "flatbed_trailer", "label": "Flatbed Trailer"},
    {"value": "gooseneck_trailer", "label": "Gooseneck Trailer"},
    {"value": "hopper_trailer", "label": "Hopper Trailer"},
    {"value": "intermodal_container_hauler_trailer", "label": "Intermodal Container Hauler Trailer"},
    {"value": "logging_trailer", "label": "Logging Trailer"},
    {"value": "low_boy_trailer", "label": "Low Boy Trailer"},
    {"value": "pup_trailer", "label": "Pup Trailer"},
    {"value": "refrigerated_van_trailer", "label": "Refrigerated Van Trailer"},
    {"value": "step_deck_trailer", "label": "Step Deck Trailer"},
    {"value": "tanker_trailer", "label": "Tanker Trailer"},
    {"value": "tilt_deck_trailer", "label": "Tilt deck Trailer"},
    {"value": "triple_van_trailer", "label": "Triple Van Trailer"},
    {"value": "utility_trailer", "label": "Utility Trailer"}
  ];
  const addVehicleURL = ['master', 'owner', 'admin', 'sales', 'service'].includes(user.permission) ? '/api/trailer/salesAdd' : '/api/trailer/request';

  async function getDataVIN(){

    setLoading(true);

    if (ownVIN) {
      setLoading(false);
      setStep(false);
      setVIN('NoVIN_' + Math.random().toString().slice(2,16));
      return;
    };
  
    if (VIN === '' || VIN === '0'){

      setLoading(false);
      setValidVIN(false)
      return false;

    };

    try {

      let res = await Axios.get(

        `https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${VIN}?format=json`

      );

      if (res.data.error) {
        setLoading(false);
        setStep(false);
        return;
      };

      const year = res.data.Results?.filter(item => item.Variable === 'Model Year')[0].Value || '';
      const make = res.data.Results?.filter(item => item.Variable === 'Make')[0].Value || '';
      const model = res.data.Results?.filter(item => item.Variable === 'Model')[0].Value || '';

      setModalData({
        year: year,
        make: make,
        model: model,
        value: '',
        status: 'active'
      });

      // finish loading
      setLoading(false);
      setStep(false);

    }
    catch (err){
      setLoading(false);
    }
  };

  const data = {
    userName: {
      type: 'hidden',
      value: user.name
    },
    user_id: {
      type: 'hidden',
      value: user.accounts[0].user_id
    },
    client: {
      type: 'hidden',
      value: company.type || 'customer'
    },
    editedField: {
      type: 'hidden',
      value: `Vehicle ${VIN} Created`
    },
    dotNumber: {
      type: 'hidden',
      value: company['dot_#'],
    },
    vin: {
      type: 'hidden',
      value: VIN
    },
    year: {
      id: 'year',
      label: 'Year',
      value: modalData.year,
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: company.type === 'customer',
    },
    make: {
      id: 'make',
      label: 'Make',
      value: modalData.make,
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: company.type === 'customer',
    },
    model: {
      id: 'model',
      label: 'Model',
      value: modalData.model,
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: company.type === 'customer',
    },
    value: {
      for: 'value',
      label: 'Stated Value',
      value: modalData.value,
      type: 'money',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: company.type === 'customer',
    },
    bodyTypeKey: {
      id: 'bodyTypeKey',
      label: 'Body type',
      type: 'select',
      options: bodyTypeOptions,
      class: 'w-full',
      containerClass: 'w-full',
      required: company.type === 'customer',
    },
    deductibleType: {
      type: 'hidden',
      value: 'FollowsPolicy',
    },
    deductible: {
      type: 'hidden',
      value: '5000',
    },
    status: {
      type: 'hidden',
      value: 'active'
    }
  };

  return (
    <>
      {step ?
        <form className='flex flex-wrap justify-between'>
          <TextInput
            id='vin'
            name= 'vin'
            label= 'VIN'
            value= { VIN }
            className= 'w-full'
            containerClass= 'w-full'
            valid= { validVIN }
            onChange= { (name, value) => setVIN(value) }
            readonly={ ownVIN }
          />
          <Fieldset
            id='ownVIN'
            options={['No VIN']}
            type='checkbox'
            class='w-full font-bold'
            className='w-full text-lg font-bold mt-4'
            onChange= { e => setOwnVIN(!ownVIN) }
          />
          <div className='flex justify-end w-full mt-4'>
            <Button 
              color={ props.destructive ? 'green' : 'red' } 
              outline 
              text='Cancel' 
              className='w-full inline-block mr-[2%] last:mr-0'
              action={ props.cancel } 
            />
    
            <Button
              color={ props.destructive ? 'red' : 'bluesign' }
              loading={ loading }
              text='Next'
              action={ getDataVIN }
              className='w-full inline-block mr-[2%] last:mr-0'
            />
          </div>
        </form>
      :
        <Form
          method='POST'
          url={ addVehicleURL }
          data={ data }
          buttonText='Save'
          buttonClass='flex justify-end w-full mt-4'
          className='flex flex-wrap justify-between'
          cancel={ e => context.modal.hide(true) }
        />
      }
    </>
  )

};

export default AddTrailerForm;
