/***
 *
 *   Policy Page
 *
 **********/

import { React, Fragment } from "react";
import _ from "lodash";
import { Card, Animate, Table, Button } from "components/lib";
import { CompanyNav } from "components/nav/company/companyNav";

export function Policy(props) {
  const policy = JSON.parse(localStorage.getItem("policy"));

  if (!policy) {
    return <div>Policy Not Found</div>;
  }

  function transformDocuments(documents) {
    const transformedDocs = [];

    for (const key in documents) {
      const value = documents[key];
      transformedDocs.push({
        PDF: key.replace(/_/g, " ").toUpperCase().replace("PDF", ""),
        value
      });
    }

    return _.chunk(transformedDocs, 3);
  }

  const groupOfDocuments = {};

  for (const key in policy.documents) {
    const newKey = key.replace(/_/g, " ").toUpperCase();
    groupOfDocuments[newKey] = transformDocuments(policy.documents[key]);
  }

  const downloadDocument = (data) => {
    window.open(data.value, "_blank");
  };

  return (
    <Fragment>
      <CompanyNav />

      <Animate type="pop">
        <Card>
          <div className="w-full flex justify-between mb-8">
            <div>
              <h3 className="font-bold text-xl">Company Name</h3>
              <p>{policy.legalName}</p>
            </div>

            <div>
              <h3 className="font-bold text-xl">DOT #</h3>
              <p>{policy.dotNumber}</p>
            </div>

            <div>
              <h3 className="font-bold text-xl">Agent</h3>
              <p>{policy.agent}</p>
            </div>

            <div>
              <h3 className="font-bold text-xl">Effective Date</h3>
              <p>{policy.effective_date}</p>
            </div>

            <div>
              <h3 className="font-bold text-xl">Policy Type</h3>
              <p>{policy.policy_Type}</p>
            </div>

            <div>
              <h3 className="font-bold text-xl">Policy Number</h3>
              <p>{policy.policy_number}</p>
            </div>

            <div>
              <h3 className="font-bold text-xl">Total Premium</h3>
              <p>{policy.premium}</p>
            </div>
          </div>

          <div className="mb-8 border border-slate-300 rounded p-4">
            <header className="font-bold border-b border-slate-300 text-2xl mb-8">
              <h1>Coverages</h1>
            </header>

            <Table
              data={policy.coverages}
              paginate
              paginateLimit="5"
              money="value"
            />
          </div>

          <div className="mb-8 border border-slate-300 rounded p-4">
            <header className="font-bold border-b border-slate-300 text-2xl mb-8">
              <h1>Vehicles</h1>
            </header>

            <Table
              data={policy.vehicles}
              paginate
              paginateLimit="5"
              money="value"
            />
          </div>

          <div className="mb-8 border border-slate-300 rounded p-4">
            <header className="font-bold text-2xl mb-8">
              <h1>Drivers</h1>
            </header>

            <Table
              data={policy.drivers}
              paginate
              paginateLimit="5"
              money="value"
            />
          </div>
          {Object.entries(groupOfDocuments).map((documentKeys) => (
            <div
              className="mb-8 border border-slate-300 rounded p-4"
              key={documentKeys[0]}
            >
              <header className="font-bold text-2xl mb-8">
                <h1>{documentKeys[0]}</h1>
              </header>

              <table className="border-collapse w-full">
                <thead>
                  <tr className="text-left outline-0 capitalize p-5 pt-0 border-b border-solid border-slate-400">
                    <th className="px-4 py-2 w-1/3">
                      <div className=" flex justify-between">
                        <p>PDF</p>
                        <p>Download</p>
                      </div>
                    </th>
                    <th className="px-4 py-2 w-1/3">
                      <div className=" flex justify-between">
                        <p>PDF</p>
                        <p>Download</p>
                      </div>
                    </th>
                    <th className="px-4 py-2 w-1/3">
                      <div className=" flex justify-between">
                        <p>PDF</p>
                        <p>Download</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {documentKeys[1].length > 0 &&
                    documentKeys[1].map((group, indexDocs) => (
                      <tr key={`${documentKeys[0] + " " + indexDocs}`}>
                        {group.map((row, index) => (
                          <td
                            key={index}
                            className="float-left w-full p-0 mb-2 md:float-none md:w-auto md:mb-0 md:p-5 md:border-b md:border-solid md:border-slate-100"
                          >
                            <div className=" flex justify-between">
                              <p>{row.PDF}</p>
                              <Button
                                icon="download"
                                action={() => downloadDocument(row)}
                                className="inline-block whitespace-nowrap bg-transparent"
                              />
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ))}
        </Card>
      </Animate>
    </Fragment>
  );
}
