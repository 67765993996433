/***
*
*   Documents Page
*
**********/

import { React, useContext, useState, Fragment, useEffect } from 'react';
import { Card, Table, Animate, TitleRow, Button, ViewContext, useAPI, TabView } from 'components/lib';
import { CompanyNav } from 'components/nav/company/companyNav';

export function Documents(props){

  const context = useContext(ViewContext);
  const [documentsData, setDocumentsData] = useState([]);
  const [archiveDocuments, setArchiveDocuments] = useState([]);

  const user = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem('company'));
  const dotNumber = company ? company["dot_#"] : "";
  const companyDataAPI = useAPI(`/api/document/get/?dotNumber=${dotNumber}`);
  const archiveCompany = useAPI(`/api/company/archives?dotNumber=${dotNumber}`);

  const getPermissionsArray = (arr) => {
    if (arr.length === 1) {
      return arr[0].split(',');
    } else {
      return arr;
    }
  };
  
  useEffect(() => {

    // format the user list
    let list = [];

    if (companyDataAPI?.data?.Documents.length){
      list = companyDataAPI.data.Documents.map(x => {
        return {

          document_name: x.documentName,
          document_type: x.documentType,
          date_uploaded: x.dateUploaded,
          uploaded_by: x.uploadedBy,
          document_url: x.documentUrl,
          id: x.documentId,
          permission: getPermissionsArray(x.permission)
        }
      })
    };

    setDocumentsData(list);

    let archiveList = [];

    if (archiveCompany?.data?.Archives?.documents.length){
      archiveList = archiveCompany.data.Archives.documents.map(x => {
        return {

          document_name: x.documentName,
          document_type: x.documentType,
          date_uploaded: x.dateUploaded,
          uploaded_by: x.uploadedBy,
          document_url: x.documentUrl,
          id: x.documentId,
          permission: x.permission
        }
      })
    };

    setArchiveDocuments(archiveList);

  }, [companyDataAPI, archiveCompany]);

  const addDocument = () => {
    const addDocumentURL = ['master', 'owner', 'admin', 'sales', 'service'].includes(user.permission) ? '/api/document/salesAdd' : '/api/document/request';

    context.modal.show({
      title: 'Add Documents',
      addDocument: {
        url: addDocumentURL,
        client: "customer"
      }

      }, (form, res) => {

        if (res.Document) {
          let list = [];
            list = res.Document.documents.map(x => {
              return {
                document_name: x.documentName,
                document_type: x.documentType,
                date_uploaded: x.dateUploaded,
                uploaded_by: x.uploadedBy,
                document_url: x.documentUrl,
                id: x.documentId,
                permission: getPermissionsArray(x.permission)
              }
            })
            setDocumentsData(list);
          context.notification.show('Document Created', 'success', true);
        };

        if (res.requestDocument) {
          context.notification.show('Document Created', 'success', true);
        };
    });
  };

  const editDocument = (data, callback) => {
    const editDocumentURL = ['master', 'owner', 'admin', 'sales', 'service'].includes(user.permission) ? '/api/document/salesEdit' : '/api/document/request';
    const editDocumentMethod = ['master', 'owner', 'admin', 'sales', 'service'].includes(user.permission) ? 'PUT' : 'POST';  

    context.modal.show({
      title: 'Edit Documents',
      form: {
        userName: {
          type: 'hidden',
          value: user.name
        },
        client: {
          type: 'hidden',
          value: 'customer'
        },
        user_id: {
          type: 'hidden',
          value: user.accounts[0].user_id
        },
        editedField: {
          type: 'hidden',
          value: `Document ${data.document_name} Edited`
        },
        dotNumber: {
          type: 'hidden',
          value: dotNumber
        },
        documentId: {
          type: 'hidden',
          value: data.id
        },
        requestType: {
          type: 'hidden',
          value: data.id,
        },
        uploadedBy: {
          type: 'hidden',
          value: data.uploaded_by
        },
        documentName: {
          id:'documentName',
          label: 'Document Name',
          value: data.document_name,
          type: 'text',
          class: 'w-full',
          containerClass: 'w-[49%]',
          required: true,
        },
        documentType: {
          id:'documentType',
          label: "Document Type",
          default: data.document_type,
          type: 'select',
          options: [
            { value: 'IDCard', label: 'ID Card'},
            { value: 'certificates', label: 'Certificates'},
            { value: 'quotes', label: 'Quotes'},
            { value: 'policies', label: 'Policies'},
            { value: 'driversLicense', label: 'Drivers License'},
            { value: 'lossRuns', label: 'Loss Runs'},
            { value: 'agreements', label: 'Agreements'},
            { value: 'proofofPriorInsurance', label: 'Proof of Prior Insurance'},
            { value: 'underinsuredMotoristForm', label: 'Underinsured motorist form'},
            { value: 'checklist', label: 'Checklist'},
          ],
          class: 'w-full',
          containerClass: 'w-[49%]',
          required: true,
        },
        document: {
          label: "Upload and Change Document",
          type: 'file',
          max: 1,
          containerClass: 'w-full',
          class: 'w-full',
        },
        actualDocument: {
          text: 'Document Link',
          url: data.document_url,
          type: 'link',
          class: 'block text-blue-500 underline mb-4 text-center w-full'
        },
        permission: {
          label: "View Access",
          default: data.permission,
          type: 'checkbox',
          name: 'viewAccess',
          options: ['Admin', 'Sales', 'Services', 'Customer'],
          cols: '4',
          class: 'w-[49%]',
          required: true,
        }
      },
      className:'flex flex-wrap justify-between',
      buttonText: 'Save',
      buttonClass: 'flex justify-end w-full',
      url: editDocumentURL,
      method: editDocumentMethod,

      }, (form, res) => {

        if (res.Document) {
          let list = [];
            list = res.Document.documents.map(x => {
              return {
                document_name: x.documentName,
                document_type: x.documentType,
                date_uploaded: x.dateUploaded,
                uploaded_by: x.uploadedBy,
                document_url: x.documentUrl,
                id: x.documentId,
                permission: getPermissionsArray(x.permission)
              }
            })
            setDocumentsData(list);
          context.notification.show('Document Edited', 'success', true);
        };

        if (res.requestDocument) {
          context.notification.show(`Change waiting for approval`, 'success', true);
        };
    });
  };

  const archiveDocument = (data) => {
    const archiveDocumentURL = ['master', 'owner', 'admin', 'sales', 'service'].includes(user.permission) ? '/api/document/salesArchive' : '/api/document/requestArchive';

    context.modal.show({
      title: 'Archive Document',
      text: `Are you sure you want to archive Document "${data.document_name}" ?`,
      form: {
        userName: {
          type: 'hidden',
          value: user.name
        },
        client: {
          type: 'hidden',
          value: 'customer'
        },
        user_id: {
          type: 'hidden',
          value: user.accounts[0].user_id
        },
        editedField: {
          type: 'hidden',
          value: `Driver "${data.document_name}" Archived`
        },
        documentId: {
          value: data.id,
          type: 'hidden'
        },
        dotNumber: {
          value: dotNumber,
          type: 'hidden'
        }
      },
      className:'flex flex-wrap justify-between',
      buttonText: 'Archive',
      buttonClass: 'flex justify-end w-full',
      url: archiveDocumentURL,
      method: 'DELETE',

      }, (form, res) => {

        if (res.archivedDocument) {
          let documentsList = [...documentsData];
          setDocumentsData(documentsList.filter(item => item.id !== data.id));

          delete data.actions;

          const archiveList = [data, ...archiveDocuments];
      
          setArchiveDocuments(archiveList);
          context.notification.show('Document Archived', 'success', true);
        };
      
    });
  };

  const restoreDocument = (data) => {

    context.modal.show({
      title: 'Restore Document',
      text: `Are you sure you want to restore document "${data.document_name}" ?`,
      form: {
        documentId: {
          type: 'hidden',
          value: data.id,
        },
        dotNumber: {
          type: 'hidden',
          value: dotNumber
        },
        user_id: {
          type: 'hidden',
          value: user.accounts[0].user_id
        },
        userName: {
          type: 'hidden',
          value: user.name
        },
        editedField: {
          type: 'hidden',
          value: `Restored document "${data.document_name}"`
        }
      },
      className:'flex flex-wrap justify-between',
      buttonText: 'Restore',
      buttonClass: 'flex justify-end w-full mt-4',
      url: '/api/document/restore',
      method: 'POST',

      }, (form, res) => {
        
        if (res.restoredDocument){
          delete data.actions

          let documentsList = [data, ...documentsData];
          setDocumentsData(documentsList);

          const archiveList = [...archiveDocuments];
          setArchiveDocuments(archiveList.filter(item => item.id !== data.id));

          context.notification.show('Document Restored', 'success', true);
        }
    });
  };

  return (
    <Fragment>

      <CompanyNav />

      <Animate type='pop'>

        <TitleRow>
          { user.permission !== 'customer' &&
            <>
              <Button small text='New File' icon='plus-circle' className='bg-blue-700 text-white rounded mr-4' action={ addDocument }/>
            </>
          }
        </TitleRow>

        <TabView name='Documents' labels={ ['Active Documents', 'Archived Documents'] }>

          <Card>
            <Table
              search
              loading={companyDataAPI.loading}
              data={ documentsData }
              show={['document_name', 'document_type', 'date_uploaded', 'uploaded_by']}
              badge={{ col: 'document_type', color: 'blue' }}
              actions={{
                newWindow: {
                  col: 'document_url',
                },
                edit: editDocument,
                custom: [
                  {
                    col: "archive",
                    value: "archive",
                    icon: "archive",
                    action: archiveDocument
                  }
                ]
              }}
              paginate
              paginateLimit='6'
            >
            </Table>
          </Card>

          <Card>
            <Table
              search
              loading={archiveCompany.loading}
              data={ archiveDocuments }
              show={['document_name', 'document_type', 'date_uploaded', 'uploaded_by']}
              badge={{ col: 'document_type', color: 'blue' }}
              actions={{
                custom: [{
                  col: "restore",
                  value: "restore",
                  icon: "rotate-ccw",
                  action: restoreDocument
                }]
              }}
              paginate
              paginateLimit='6'
            >
            </Table>
          </Card>

        </TabView>

      </Animate>

    </Fragment>
  );
};
