import { Badge } from "components/lib";
import TableActions from "./actions";
import Style from "./table.tailwind.js";

export function Body(props) {
  if (props.data?.length) {
    return (
      <tbody className={Style.body}>
        {props.data.map((row, index) => {
          return <Row {...props} data={row} key={index} />;
        })}
      </tbody>
    );
  }

  return (
    <tbody className={Style.body}>
      <tr>
        <td colSpan="10" className={Style.empty}>
          No results found
        </td>
      </tr>
    </tbody>
  );
}

export function Row(props) {
  let row = { ...props.data };
  row.actions = row.actions || props.actions;
  const hasActions = Object.values(row.actions).some((x) => x !== undefined);

  function convertToNumber(value) {
    if (typeof value === "string") {
      return parseFloat(value);
    } else if (typeof value === "number") {
      return value;
    } else {
      return 0;
    }
  }

  return (
    <tr data-id={props.data.id}>
      {Object.keys(row).map((cell, index) => {
        // actions
        if (cell === "actions")
          return hasActions ? (
            <TableActions
              row={row}
              index={index}
              key={index}
              callback={props.callback}
            />
          ) : (
            false
          );

        // hide
        if (props.hide?.includes(cell)) return false;

        // show
        if (props.show && !props.show.includes(cell)) return false;

        let value = row[cell];

        // is date/time
        if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value) && !isNaN(new Date(value).getTime())) {
          const date = new Date(value).toISOString().split("T");
          const dateParts = date[0].split("-");
          const year = dateParts[0];
          const month = dateParts[1];
          const day = dateParts[2];

          const newDate = `${month}-${day}-${year}`;
          value = newDate;
        }

        // convert number to money value
        if (
          props.money &&
          props.money === cell &&
          value !== "" &&
          value !== null
        ) {
          value = "$ " + convertToNumber(value)?.toLocaleString("en-US");
        }

        // has badge
        if (value !== undefined && props.badge && cell === props.badge.col) {
          // default color
          let color = props.badge.color;

          // check each condition
          if (props.badge.condition) {
            props.badge.condition.forEach((cond) => {
              typeof cond.value === "string" && typeof value === "string"
                ? (color =
                    cond.value.toLowerCase() === value.toLowerCase()
                      ? cond.color
                      : color)
                : (color = cond.value === value ? cond.color : color);
            });
          }

          return (
            <td key={index} className={Style.cell}>
              <Badge
                text={value === true ? "Yes" : value === false ? "No" : value}
                color={color}
                className={Style.badge}
              />
            </td>
          );
        }

        // standard cell
        return (
          <td key={index} className={Style.cell}>
            {value === true ? "Yes" : value === false ? "No" : value}
          </td>
        );
      })}
    </tr>
  );
}
