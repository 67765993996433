import { Text, View } from "@react-pdf/renderer";
import Box from "./box";
function FourthRow() {
  return (
    <View style={{ flexDirection: "row", height: "60px" }}>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "25%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <Text
          style={{ textAlign: "left", marginLeft: "2px", paddingTop: "2px" }}
        >
          WORKERS COMPENSATION
        </Text>
        <Text style={{ textAlign: "left", marginLeft: "2px" }}>
          AND EMPLOYERS' LIABILITY
        </Text>
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              paddingTop: "2px",
              fontFamily: "Helvetica",
              marginLeft: "2px"
            }}
          >
            <Text>ANY PROPERTIER/PARTNER/EXECUTIVE</Text>
            <Text>OFFICER/MEMBER EXCLUDED?</Text>
            <Text style={{ fontFamily: "Helvetica-Bold", fontSize: "6px" }}>
              (Mandatory in NH)
            </Text>
            <Text style={{ fontSize: "6px" }}>If yes, describe under</Text>
            <Text>DESCRIPTION OF OPERATIONS below</Text>
          </View>
          <View style={{ marginTop: "5px", marginLeft: "2px" }}>
            <Text style={{ paddingLeft: "1px" }}>Y/N</Text>
            <Box />
          </View>
        </View>
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>N / A</Text>
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "18%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View style={{ width: "29%", borderBottom: "1px solid black" }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
            fontFamily: "Helvetica"
          }}
        >
          <View
            style={{
              flex: "1",
              borderRight: "1px solid black",
              fontSize: "5px"
            }}
          >
            <View
              style={{
                borderBottom: "1px solid black",
                height: "28%",
                flexDirection: "row"
              }}
            >
              <View style={{ flexDirection: "row", width: "55%" }}>
                <Box />
                <View
                  style={{
                    paddingTop: "1px",
                    flexDirection: "column",
                    paddingLeft: "1px"
                  }}
                >
                  <Text>WC STATU-</Text>
                  <Text>TORY LIMITS</Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "25%",
                  marginLeft: "6px"
                }}
              >
                <Box />
                <View style={{ paddingTop: "1px", paddingLeft: "1px" }}>
                  <Text style={{ width: "30%" }}>OTH-</Text>
                  <Text>ER</Text>
                </View>
              </View>
            </View>
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "24%",
                paddingTop: "4px",
                paddingLeft: "2px"
              }}
            >
              E.L. EACH ACCIDENT
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "24%",
                paddingLeft: "2px",
                paddingTop: "4px"
              }}
            >
              E.L. DISEASE - EA EMPLOYEE
            </Text>
            <Text
              style={{ height: "24%", paddingLeft: "2px", paddingTop: "4px" }}
            >
              E.L. DISEASE - POLICY LIMIT
            </Text>
          </View>
          <View style={{ flex: "1", fontSize: "6px" }}>
            <View
              style={{
                height: "28%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
            </View>
            <View
              style={{
                height: "24%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
            <View
              style={{
                height: "24%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
            <View
              style={{ height: "24%", flexDirection: "row", paddingTop: "2px" }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
export default FourthRow;
