/***
 *
 *   Company Page
 *
 **********/

import { React, useContext, useState, Fragment, useEffect } from "react";
import {
  Card,
  Form,
  Animate,
  ViewContext,
  NumberInput,
  TextInput,
  Select,
  EmailInput,
  PhoneInput,
  DateInput,
  TitleRow,
  Button,
  TabView,
  Table,
  useAPI,
  Label
} from "components/lib";
import { CompanyNav } from "components/nav/company/companyNav";
import axios from "axios";
import SelectReact from "react-select";
import usStates from "../../data/short-us-states.json";

export function ProspectCompany(props) {
  const context = useContext(ViewContext);
  const [companyData, setCompanyData] = useState({});
  const [company, setCompany] = useState({});
  const [contacts, setContacts] = useState([]);
  const [archiveContacts, setArchiveContacts] = useState([]);
  const sales = useAPI(`/api/user/getAllSales`)?.data || [];
  const user = JSON.parse(localStorage.getItem("user"));
  const editCompanyURL = "/api/prospect/edit";
  const editCompanyMethod = "PUT";

  const getData = async () => {
    try {
      const company = JSON.parse(localStorage.getItem("company"));
      setCompany(company);
      const resCompany = await axios.get("/api/prospect/get", {
        params: {
          dotNumber: company["dot_#"]
        }
      });

      if (resCompany.data.Prospects) {
        const transformedUser = {
          label: resCompany.data.Prospects[0].salesPersonAssigned,
          value: {
            name: resCompany.data.Prospects[0].salesPersonAssigned
          }
        };

        resCompany.data.Prospects[0].salesPersonAssigned = transformedUser;

        setCompanyData(resCompany.data.Prospects[0]);
      }

      const resContact = await axios.get(
        `/api/prospectcontact/get?dotNumber=${company["dot_#"]}`
      );
      let list = [];

      if (resContact.data?.Contacts?.length) {
        list = resContact.data?.Contacts.map((x) => {
          return {
            name: x.contactName,
            email: x.contactEmail,
            phone: x.contactPhone,
            notes: x.notes,
            details: x.notes?.length > 0 ? "YES" : "NO",
            contactId: x.contactId
          };
        });
      }
      setContacts(list);
      const resArchive = await axios.get(
        `/api/prospect/archives?dotNumber=${company["dot_#"]}`
      );
      let archiveList = [];

      if (resArchive.data?.Archives?.contacts?.length) {
        archiveList = resArchive.data?.Archives?.contacts.map((x) => {
          return {
            name: x.contactName,
            email: x.contactEmail,
            phone: x.contactPhone,
            notes: x.notes,
            details: x.notes?.length > 0 ? "YES" : "NO",
            contactId: x.contactId
          };
        });
      }
      setArchiveContacts(archiveList);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onChange = (name, value) => {
    setCompanyData({
      ...companyData,
      [name]: value
    });
  };

  const formData = {
    userName: {
      type: "hidden",
      value: user.name
    },
    user_id: {
      type: "hidden",
      value: user.accounts[0].user_id
    },
    account_id: {
      type: "hidden",
      value: user.account_id
    },
    editedField: {
      type: "hidden",
      value: `Company ${companyData.legalName} Edited`
    },
    dotNumber: {
      type: "hidden",
      value: companyData.dotNumber
    },
    newDotNumber: {
      type: "hidden",
      value: companyData.newDotNumber
    },
    companyRep1: {
      type: "hidden",
      value: companyData.companyRep1
    },
    legalName: {
      type: "hidden",
      value: companyData.legalName
    },
    dbaName: {
      type: "hidden",
      value: companyData.dbaName
    },
    address: {
      type: "hidden",
      value: companyData.address
    },
    address2: {
      type: "hidden",
      value: companyData.address2
    },
    emailAddress: {
      type: "hidden",
      value: companyData.emailAddress
    },
    telephone: {
      type: "hidden",
      value: companyData.telephone
    },
    phyCity: {
      type: "hidden",
      value: companyData.phyCity
    },
    phyState: {
      type: "hidden",
      value: companyData.phyState
    },
    phyZip: {
      type: "hidden",
      value: companyData.phyZip
    },
    salesPersonAssigned: {
      type: "hidden",
      value: companyData.salesPersonAssigned?.value?.name
    },
    policyRenewalDate: {
      type: "hidden",
      value: companyData.policyRenewalDate
    },
    leadSource: {
      type: "hidden",
      value: companyData.leadSource
    },
    yearsInBusiness: {
      type: "hidden",
      value: companyData.yearsInBusiness
    },
    status: {
      type: "hidden",
      value: companyData.status
    }
  };

  const retailAgent = {
    label: "Joe Agentzzz",
    value: {
      name: "Joe Agentzzz",
      FirstName: "Joe",
      LastName: "Agentzzz",
      Phone: "5555555555",
      Email: "Joe@mail.com",
      AgencyName: "Trucking",
      Street: "10 Main Street",
      City: "Chicago",
      State: "IL",
      Zip: "90201"
    }
  };

  function transformUserObject(originalUser) {
    const transformedUser = {
      label: originalUser.name,
      value: {
        name: originalUser.name,
        FirstName: originalUser.name?.split(" ")[0],
        LastName: originalUser.name?.split(" ")[1] || "",
        Phone: originalUser.phone ? originalUser.phone.replace(/-/g, "") : "",
        Email: originalUser.email,
        AgencyName: "Trucking",
        Street: "10 Main Street",
        City: "Chicago",
        State: "IL",
        Zip: "90201"
      }
    };

    return transformedUser;
  }

  const transformedUsers = sales.map(transformUserObject);

  const salesOPtions = [retailAgent, ...transformedUsers];

  const handleSelectChange = (selectedOption) => {
    setCompanyData((prevState) => {
      return {
        ...prevState,
        salesPersonAssigned: selectedOption
      };
    });
  };

  const addContact = () => {
    context.modal.show(
      {
        title: "Add Contact",
        form: {
          userName: {
            type: "hidden",
            value: user.name
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id
          },
          editedField: {
            type: "hidden",
            value: "Contact Created"
          },
          dotNumber: {
            type: "hidden",
            value: companyData.dotNumber
          },
          contactName: {
            id: "contactName",
            label: "Name",
            type: "text",
            required: true,
            containerClass: "w-full",
            class: "w-full"
          },
          contactEmail: {
            label: "Email",
            type: "email",
            containerClass: "w-full md:w-[49%]",
            class: "w-full",
            required: false
          },
          contactPhone: {
            for: "contactPhone",
            label: "Phone",
            type: "phone",
            containerClass: "w-full md:w-[49%]",
            class: "w-full"
          },
          notes: {
            id: "notes",
            label: "Notes",
            type: "textarea",
            containerClass: "w-full",
            class: "w-full"
          }
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Save",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/prospectcontact/add",
        method: "POST"
      },
      (form, res) => {
        if (res === "That contact is already registered.") {
          context.notification.show(
            `Email is already registered`,
            "error",
            false
          );
        }

        if (res?.Contact?.contacts?.length) {
          let list = [];
          list = res?.Contact?.contacts.map((x) => {
            return {
              name: x.contactName,
              email: x.contactEmail,
              phone: x.contactPhone,
              notes: x.notes,
              details: x.notes?.length > 0 ? "YES" : "NO",
              contactId: x.contactId
            };
          });
          setContacts(list);
          context.notification.show(`Contact Created`, "success", true);
        }
      }
    );
  };

  const editContact = (data, callback) => {
    context.modal.show(
      {
        title: "Edit Contact",
        form: {
          dotNumber: {
            type: "hidden",
            value: companyData.dotNumber
          },
          userName: {
            type: "hidden",
            value: user.name
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id
          },
          editedField: {
            type: "hidden",
            value: `Contact ${data.name} Edited`
          },
          contactId: {
            type: "hidden",
            value: data.contactId
          },
          contactName: {
            id: "contactName",
            label: "Name",
            type: "text",
            required: true,
            containerClass: "w-full",
            class: "w-full",
            value: data.name
          },
          contactEmail: {
            label: "Email",
            type: "email",
            containerClass: "w-full md:w-[49%]",
            class: "w-full",
            value: data.email,
            required: false
          },
          contactPhone: {
            for: "contactPhone",
            label: "Phone",
            type: "phone",
            containerClass: "w-full md:w-[49%]",
            class: "w-full",
            value: data.phone
          },
          notes: {
            id: "notes",
            label: "Notes",
            type: "textarea",
            containerClass: "w-full",
            class: "w-full",
            value: data.notes
          }
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Save",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/prospectcontact/edit",
        method: "PUT"
      },
      (form, res) => {
        let list = [];

        if (res?.editedContact?.contacts?.length) {
          list = res?.editedContact?.contacts.map((x) => {
            return {
              name: x.contactName,
              email: x.contactEmail,
              phone: x.contactPhone,
              notes: x.notes,
              details: x.notes?.length > 0 ? "YES" : "NO",
              contactId: x.contactId
            };
          });
        }
        setContacts(list);
        context.notification.show(
          `Contact ${data.name} Edited`,
          "success",
          true
        );
      }
    );
  };

  const archiveContact = (data) => {
    context.modal.show(
      {
        title: "Archive Contact",
        text: `Are you sure you want to archive Contact "${data.name}" ?`,
        form: {
          userName: {
            type: "hidden",
            value: user.name
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id
          },
          editedField: {
            type: "hidden",
            value: `Contact "${data.name}" Archived`
          },
          contactId: {
            value: data.contactId,
            type: "hidden"
          },
          dotNumber: {
            value: companyData.dotNumber,
            type: "hidden"
          }
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Archive",
        buttonClass: "flex justify-end w-full",
        url: "/api/prospectcontact/archive",
        method: "DELETE"
      },
      (form, res) => {
        if (res?.archivedContact?.contacts) {
          let contactsList = [...contacts];
          setContacts(
            contactsList.filter((item) => item.contactId !== data.contactId)
          );

          delete data.actions;

          const archiveList = [data, ...archiveContacts];

          setArchiveContacts(archiveList);
          context.notification.show(
            `Contact ${data.name} Archived`,
            "success",
            true
          );
        }
      }
    );
  };

  const restoreContact = (data) => {
    context.modal.show(
      {
        title: "Restore Contact",
        text: `Are you sure you want to restore contact "${data.name}" ?`,
        form: {
          contactId: {
            type: "hidden",
            value: data.contactId
          },
          dotNumber: {
            type: "hidden",
            value: companyData.dotNumber
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id
          },
          userName: {
            type: "hidden",
            value: user.name
          },
          editedField: {
            type: "hidden",
            value: `Restored contact "${data.name}"`
          }
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Restore",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/prospectcontact/restore",
        method: "POST"
      },
      (form, res) => {
        if (res.restoredContact) {
          delete data.actions;

          let contactsList = [data, ...contacts];
          setContacts(contactsList);

          const archiveList = [...archiveContacts];
          setArchiveContacts(
            archiveList.filter((item) => item.contactId !== data.contactId)
          );

          context.notification.show("Contact Restored", "success", true);
        }
      }
    );
  };

  return (
    <Fragment>
      <CompanyNav />

      <Animate type="pop">
        <TitleRow>
          <Button
            small
            text="Add Contact"
            icon="plus-circle"
            className="bg-blue-700 text-white rounded mr-4"
            action={addContact}
          />
        </TitleRow>

        <TabView
          name="company"
          labels={["Company", "Contacts", "Archived Contacts"]}
        >
          <Card className="pb-14">
            <div className="flex flex-wrap justify-between">
              <TextInput
                id="legalName"
                name="legalName"
                label="Business Name"
                value={companyData.legalName}
                className="w-full"
                containerClass="w-full md:w-[22%]"
                onChange={onChange}
              />
              <TextInput
                id="dbaName"
                name="dbaName"
                label="DBA Name"
                value={companyData.dbaName}
                className="w-full"
                containerClass="w-full md:w-[24%] md:ml-4"
                onChange={onChange}
              />
              <TextInput
                id="companyRep1"
                name="companyRep1"
                label="Owner's Name"
                value={companyData.companyRep1}
                containerClass="w-full md:w-[49%]"
                className="w-full"
                onChange={onChange}
              />
              <NumberInput
                name="newDotNumber"
                label="DOT Number"
                value={
                  companyData.newDotNumber?.length > 0
                    ? companyData.newDotNumber
                    : companyData.dotNumber
                }
                className="w-full"
                containerClass="w-full md:w-[49%]"
                onChange={onChange}
              />
              <TextInput
                id="address"
                name="address"
                label="Address"
                value={companyData.address}
                containerClass="w-full md:w-[49%]"
                className="w-full"
                onChange={onChange}
              />
              <EmailInput
                id="emailAddress"
                name="emailAddress"
                label="Email"
                value={companyData.emailAddress}
                containerClass="w-full md:w-[22%]"
                className="w-full"
                onChange={onChange}
              />
              <PhoneInput
                id="telephone"
                name="telephone"
                for="telephone"
                label="Phone"
                value={companyData.telephone}
                containerClass="w-full md:w-[24%] md:ml-4"
                className="w-full"
                onChange={onChange}
              />
              <TextInput
                id="address2"
                name="address2"
                label="Address Line 2"
                value={companyData.address2}
                containerClass="w-full md:w-[49%]"
                className="w-full"
                onChange={onChange}
              />
              <div className="w-full md:w-[22%]">
                <Label for="salesPersonAssigned" text="Sales Person Assigned" />
                <SelectReact
                  options={salesOPtions}
                  value={companyData.salesPersonAssigned}
                  onChange={handleSelectChange}
                  name="salesPersonAssigned"
                  styles={{
                    control: (styles) => ({ ...styles, padding: ".3rem" })
                  }}
                />
              </div>
              <DateInput
                id="policyRenewalDate"
                name="policyRenewalDate"
                label="Policy Renewal Date"
                value={companyData.policyRenewalDate}
                containerClass="w-full md:w-[24%] md:ml-4"
                className="w-full"
                onChange={onChange}
                calendarPosition="top"
              />
              <TextInput
                id="phyCity"
                name="phyCity"
                label="City"
                value={companyData.phyCity}
                containerClass="w-full md:w-[25%]"
                className="w-full"
                onChange={onChange}
              />
              <Select
                id="phyState"
                name="phyState"
                label="State"
                containerClass="w-full md:w-[10%]"
                className="w-full"
                onChange={onChange}
                default={companyData.phyState}
                value={companyData.phyState}
                options={usStates}
              />
              <TextInput
                id="phyZip"
                name="phyZip"
                label="ZIP"
                value={companyData.phyZip}
                containerClass="w-full md:w-[10%]"
                className="w-full"
                onChange={onChange}
              />
              <TextInput
                id="leadSource"
                name="leadSource"
                label="Lead Source"
                value={companyData.leadSource}
                containerClass="w-full md:w-[22%]"
                className="w-full"
                onChange={onChange}
              />
              <NumberInput
                id="yearsInBusiness"
                name="yearsInBusiness"
                label="Years in Business"
                value={companyData.yearsInBusiness}
                className="w-full md:w-[32%]"
                containerClass="w-full md:w-[75%]"
                onChange={onChange}
              />
              <TextInput
                id="clientSince"
                name="clientSince"
                label="Client Since"
                value={companyData.clientSince}
                containerClass="w-full md:w-[22%]"
                className="w-full"
                readonly
              />
              <Select
                id="status"
                name="status"
                label="Status"
                default={companyData.status}
                value={companyData.status}
                options={[
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" }
                ]}
                className="md:w-[32%]"
                containerClass="w-full md:w-[75%]"
                onChange={onChange}
              />
            </div>
            <Form
              data={formData}
              method={editCompanyMethod}
              url={editCompanyURL}
              callback={(res) => {
                const newCompany = {
                  ...company,
                  "dot_#": companyData.newDotNumber?.length
                    ? companyData.newDotNumber
                    : company["dot_#"]
                };
                localStorage.setItem("company", JSON.stringify(newCompany));
                setCompanyData({
                  ...companyData,
                  dotNumber: companyData.newDotNumber?.length
                    ? companyData.newDotNumber
                    : company["dot_#"]
                });
                context.notification.show(
                  "Company " + companyData.legalName + " updated",
                  "success",
                  true
                );
              }}
              buttonText="Save"
              buttonClass="flex w-full justify-end mt-4"
              buttonStyle="w-[14%]"
              updateOnChange
              succesText
            />
          </Card>

          <Card className="pb-14">
            <Table
              search
              data={contacts}
              show={["name", "email", "phone", "details"]}
              badge={{
                col: "details",
                color: "orange",
                condition: [
                  { value: "NO", color: "red" },
                  { value: "YES", color: "green" }
                ]
              }}
              actions={{
                edit: editContact,
                custom: [
                  {
                    col: "archive",
                    value: "archive",
                    icon: "archive",
                    action: archiveContact
                  }
                ]
              }}
              paginate
              paginateLimit="5"
            />
          </Card>

          <Card className="pb-14">
            <Table
              search
              data={archiveContacts}
              show={["name", "email", "phone", "details"]}
              badge={{
                col: "details",
                color: "orange",
                condition: [
                  { value: "NO", color: "red" },
                  { value: "YES", color: "green" }
                ]
              }}
              actions={{
                custom: [
                  {
                    col: "restore",
                    value: "restore",
                    icon: "rotate-ccw",
                    action: restoreContact
                  }
                ]
              }}
              paginate
              paginateLimit="5"
            />
          </Card>
        </TabView>
      </Animate>
    </Fragment>
  );
}
