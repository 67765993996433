/***
 *
 *   Certificates Page
 *
 **********/

import { React, useContext, Fragment, useEffect, useState } from "react";
import {
  Card,
  TitleRow,
  Button,
  ViewContext,
  Animate,
  Table,
  useAPI,
} from "components/lib";
import { CompanyNav } from "components/nav/company/companyNav";

export function Certificate(props) {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const company = JSON.parse(localStorage.getItem("company"));
  const certificates = useAPI(`/api/certificate/getAll/${company ? company["dot_#"] : ""}`);
  const submissionsNumbers = useAPI(`/api/policy/get/bound/${company ? company["dot_#"] : ""}`)
    ?.data || { Bound: [] };
  const [certificatesArray, setCertificatesArray] = useState([]);

  useEffect(() => {
    if (
      Array.isArray(certificates?.data?.Certificates) &&
      certificates?.data?.Certificates.length > 0
    ) {
      const uniquePdfUrls = new Set();

      const uniqueArray = certificates.data.Certificates.filter((obj) => {
        if (!uniquePdfUrls.has(obj.pdf_url)) {
          uniquePdfUrls.add(obj.pdf_url);
          return true;
        }
        return false;
      }).map((cert) => {
        const {
          dateUploaded,
          certificate_holder,
          additional_remarks,
          ...rest
        } = cert;
        const newObj = {
          date_uploaded: dateUploaded,
          ...rest,
        };

        if (!certificate_holder) {
          newObj.certificate_holder = "";
        } else {
          newObj.certificate_holder = certificate_holder;
        }

        if (!additional_remarks) {
          newObj.additional_remarks = "";
        } else {
          newObj.additional_remarks = additional_remarks;
        }

        return newObj;
      });

      setCertificatesArray(Array.from(uniqueArray));
    }
  }, [certificates]);

  const addCertificate = () => {
    context.modal.show(
      {
        title: "Create certificate",
        addCertificateModal: {
          submissionsNumbers: submissionsNumbers.Bound,
        },
      },
      (form, res) => {
        if (res?.pdf_url) {
          const { dateUploaded, ...rest } = res;
          const newObj = {
            date_uploaded: dateUploaded,
            ...rest,
          };
          setCertificatesArray([...certificatesArray, newObj]);
          context.notification.show("Certificate Created", "success", true);
        } else {
          context.notification.show(
            "There was a problem creating the certificate",
            "error",
            true
          );
        }
      }
    );
  };

  const refreshCertificate = async (data) => {
    context.modal.show(
      {
        title: "Refresh certificate",
        text: "Are you sure you want to refresh this certificate?",
        refreshCertificateModal: {
          data,
        },
      },
      (form, res) => {
        const { dateUploaded, ...rest } = res;
        const newObj = {
          date_uploaded: dateUploaded,
          ...rest,
        };

        setCertificatesArray([...certificatesArray, newObj]);
      }
    );
  };

  return (
    <Fragment>
      <CompanyNav />

      <Animate type="pop">
        <TitleRow>
          {!["customer", "sales"].includes(user.permission) && (
            <>
              <Button
                small
                text="Add Certificate"
                icon="plus-circle"
                className="bg-blue-700 text-white rounded mr-4"
                action={addCertificate}
              />
            </>
          )}
        </TitleRow>

        <Card className="pb-14">
          <Table
            data={certificatesArray}
            loading={certificates.loading}
            show={["submission_number", "date_uploaded", "certificate_holder"]}
            actions={{
              newWindow: {
                col: "pdf_url",
              },
              custom: [
                {
                  icon: "refresh-cw",
                  action: refreshCertificate,
                },
              ],
            }}
            paginate
            paginateLimit="5"
          ></Table>
        </Card>
      </Animate>
    </Fragment>
  );
}
