/***
 *
 *   Trailers Page
 *
 **********/

import { React, useContext, useState, Fragment, useEffect } from "react";
import {
  Card,
  Table,
  Animate,
  TitleRow,
  Button,
  ViewContext,
  TabView,
} from "components/lib";
import { CompanyNav } from "components/nav/company/companyNav";
import axios from "axios";

export function Trailers(props) {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [trailers, setTrailers] = useState([]);
  const [archiveTrailers, setArchiveTrailers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const company = JSON.parse(localStorage.getItem("company"));
    try {
      const res = await axios.get("/api/trailer/get", {
        params: {
          dotNumber: company["dot_#"],
        },
      });
  
      const newTrailerArray = res.data.trailers.map((objet) => {
        const { vin: value, ...restofObjet } = objet;
  
        const objetRename = { VIN: value, ...restofObjet };
  
        return objetRename;
      });
      setTrailers(newTrailerArray);
  
      const resArchive = await axios.get(
        `/api/company/archives?dotNumber=${company["dot_#"]}`
      );
  
      const newArchiveArray = resArchive.data?.Archives?.trailers.map((objet) => {
        const { vin: value, ...restofObjet } = objet;
  
        const objetRename = { VIN: value, ...restofObjet };
  
        return objetRename;
      });
      setArchiveTrailers(newArchiveArray);
    } catch(error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const addTrailer = () => {
    context.modal.show(
      {
        title: "Add Trailer",
        addTrailer: true,
      },
      (form, res) => {
        if (res.Trailer) {
          context.notification.show("Trailer created", "success", true);
          const vehiclesArray = res.Trailer.trailers.map((object) => {
            const { vin: value, ...restofObjet } = { ...object };

            const objetRename = { VIN: value, ...restofObjet };

            return objetRename;
          });
          setTrailers(vehiclesArray);
        }

        if (res.requestTrailer) {
          context.notification.show(
            "Trailer waiting for approval",
            "success",
            true
          );
          res.requestTrailer.trailers[0].status = "Pending";
          const { vin: value, ...restofObjet } = res.requestTrailer.trailers[0];

          const objetRename = { VIN: value, ...restofObjet };

          const vehiclesData = [objetRename, ...trailers];
          setTrailers(vehiclesData);
        }
      }
    );
  };

  const editTrailer = (data, callback) => {
    context.modal.show(
      {
        editTrailer: {
          data: data,
        },
      },
      (form, res) => {
        if (res.editedTrailer) {
          context.notification.show("Trailer edited", "success", true);
          const vehiclesArray = res.editedTrailer.trailers.map((object) => {
            const { vin: value, ...restofObjet } = { ...object };

            const objetRename = { VIN: value, ...restofObjet };

            return objetRename;
          });
          setTrailers(vehiclesArray);
        }

        if (res.requestTrailer) {
          const { vin: value, ...restofObjet } = res.requestTrailer.vehicles[0];

          const objetRename = { VIN: value, ...restofObjet };
          objetRename.status = "Pending";

          const vehiclesCopyArray = [...trailers];

          const index = vehiclesCopyArray.findIndex(function (object) {
            return object.VIN === objetRename.VIN;
          });

          if (index !== -1) {
            vehiclesCopyArray[index] = objetRename;
          }
          setTrailers(vehiclesCopyArray);
          context.notification.show(
            "Change awaiting approval",
            "success",
            true
          );
        }
      }
    );
  };

  const archiveTrailer = (data) => {
    const archiveVehicleURL = [
      "master",
      "owner",
      "admin",
      "sales",
      "service",
    ].includes(user.permission)
      ? "/api/trailer/salesArchive"
      : "/api/trailer/requestArchive";

    context.modal.show(
      {
        title: "Archive Trailer",
        text: `Are you sure you want to archive Trailer "${data.VIN}" ?`,
        form: {
          userName: {
            type: "hidden",
            value: user.name,
          },
          client: {
            type: "hidden",
            value: "customer",
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          editedField: {
            type: "hidden",
            value: `Trailer "${data.VIN}" Archived`,
          },
          vin: {
            type: "hidden",
            value: data.VIN,
          },
          dotNumber: {
            type: "hidden",
            value: companyData["dot_#"],
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Archive",
        buttonClass: "flex justify-end w-full",
        url: archiveVehicleURL,
        method: "DELETE",
      },
      (form, res) => {
        if (res.archivedTrailer) {
          if (archiveVehicleURL === "/api/trailer/salesArchive") {
            let vehiclesList = [...trailers];
            setTrailers(vehiclesList.filter((item) => item.VIN !== data.VIN));
            context.notification.show("Trailer Archived", "success", true);

            delete data.actions;

            const archiveList =
              archiveTrailers?.length > 0 ? [data, ...archiveTrailers] : [data];

            setArchiveTrailers(archiveList);
            return;
          }
          context.notification.show(
            "Archive trailer requested to Admin",
            "success",
            true
          );
        }
      }
    );
  };

  const restoreTrailer = (data) => {
    context.modal.show(
      {
        title: "Restore Trailer",
        text: `Are you sure you want to restore Trailer "${data.VIN}" ?`,
        form: {
          vin: {
            type: "hidden",
            value: data.VIN,
          },
          dotNumber: {
            type: "hidden",
            value: companyData["dot_#"],
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          userName: {
            type: "hidden",
            value: user.name,
          },
          editedField: {
            type: "hidden",
            value: `Restored trailer "${data.VIN}"`,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Restore",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/trailer/restore",
        method: "POST",
      },
      (form, res) => {
        if (res.restoredTrailer) {
          delete data.actions;

          let vehiclesList = [data, ...trailers];
          setTrailers(vehiclesList);

          const archiveList = [...archiveTrailers];
          setArchiveTrailers(
            archiveList.filter((item) => item.VIN !== data.VIN)
          );

          context.notification.show("Trailer Restored", "success", true);
        }
      }
    );
  };

  return (
    <Fragment>
      <CompanyNav />

      <Animate type="pop">
        <TitleRow>
          <Button
            small
            text="Add Trailer"
            icon="plus-circle"
            className="bg-blue-700 text-white rounded mr-4"
            action={addTrailer}
          />
        </TitleRow>

        <TabView name="Vehicles" labels={["Trailers", "Archived Trailers"]}>
          <Card>
            <Table
              data={trailers}
              loading={loading}
              show={["year", "make", "model", "VIN", "value", "status"]}
              actions={{
                edit: editTrailer,
                custom: [
                  {
                    col: "archive",
                    value: "archive",
                    icon: "archive",
                    action: archiveTrailer,
                  },
                ],
              }}
              badge={{
                col: "status",
                color: "orange",
                condition: [
                  { value: "inactive", color: "red" },
                  { value: "active", color: "green" },
                ],
              }}
              paginate
              paginateLimit="6"
              money="value"
            ></Table>
          </Card>

          <Card>
            <Table
              data={archiveTrailers}
              loading={loading}
              show={["year", "make", "model", "VIN", "value"]}
              actions={{
                custom: [
                  {
                    col: "restore",
                    value: "restore",
                    icon: "rotate-ccw",
                    action: restoreTrailer,
                  },
                ],
              }}
              paginate
              paginateLimit="6"
              money="value"
            ></Table>
          </Card>
        </TabView>
      </Animate>
    </Fragment>
  );
}
