import { useContext, useState } from 'react';
import { ViewContext, Form, NumberInput, TextInput, Select } from 'components/lib';
import { MoneyInput } from '../input/money';

const VehiclesForm = (props) => {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem('company'));
  const dotNumber = company['dot_#'];
  const [modalData, setModalData] = useState({
    year: props.data.year,
    VIN: props.data.VIN,
    make: props.data.make,
    model: props.data.model,
    value: props.data.value,
    classKey: props.data.classKey,
    bodyTypeKey: props.data.bodyTypeKey,
    deductibleType: "FollowsPolicy",
    deductible: props.data.deductible,
    status: props.data.status
  });
  const classOptions = [
    {"value": "1", "label": "Class 1: 0–6,000 pounds (0–2,722 kg)"},
    {"value": "2a", "label": "Class 2a: 6,001–8,500 pounds (2,722–3,856 kg)"},
    {"value": "2b", "label": "Class 2b: 8,501–10,000 pounds (3,856–4,536 kg)"},
    {"value": "3", "label": "Class 3: 10,001–14,000 pounds (4,536–6,350 kg)"},
    {"value": "4", "label": "Class 4: 14,001–16,000 pounds (6,351–7,257 kg)"},
    {"value": "5", "label": "Class 5: 16,001–19,500 pounds (7,258–8,845 kg)"},
    {"value": "6", "label": "Class 6 : 19,501–26,000 pounds (8,846–11,793 kg)"},
    {"value": "7", "label": "Class 7: 26,001–33,000 pounds (11,794–14,969 kg)"},
    {"value": "8", "label": "Class 8: 33,001 pounds (14,969 kg) +"}
  ];
  const bodyTypeOptions = [
    {"value": "boom_truck", "label": "Boom Truck"},
    {"value": "box_truck", "label": "Box Truck"},
    {"value": "bucket_truck", "label": "Bucket Truck"},
    {"value": "bus", "label": "Bus"},
    {"value": "catering_truck", "label": "Catering Truck"},
    {"value": "cement_truck", "label": "Cement Truck"},
    {"value": "delivery_truck", "label": "Delivery Truck"},
    {"value": "delivery_van", "label": "Delivery Van"},
    {"value": "dump_truck", "label": "Dump Truck"},
    {"value": "fire_truck", "label": "Fire Truck"},
    {"value": "flatbed_tow_truck", "label": "Flatbed Tow Truck"},
    {"value": "flatbed_truck", "label": "Flatbed Truck"},
    {"value": "food_truck", "label": "Food Truck"},
    {"value": "fuel_tank_truck", "label": "Fuel Tank Truck"},
    {"value": "garbage_truck", "label": "Garbage Truck"},
    {"value": "landscape_truck", "label": "Landscape Truck"},
    {"value": "logging_truck", "label": "Logging Truck"},
    {"value": "milk_tanker_truck", "label": "Milk Tanker Truck"},
    {"value": "mini_van", "label": "Mini Van"},
    {"value": "moving_truck", "label": "Moving Truck"},
    {"value": "pick_up", "label": "Pickup"},
    {"value": "on_hook_tow_truck", "label": "On Hook Tow Truck"},
    {"value": "rack_truck", "label": "Rack Truck"},
    {"value": "snow_plow_truck", "label": "Snow Plow Truck"},
    {"value": "stake_truck", "label": "Stake Truck"},
    {"value": "step_van", "label": "Step Van"},
    {"value": "suv", "label": "SUV"},
    {"value": "tanker_truck", "label": "Tanker Truck"},
    {"value": "tractor", "label": "Tractor"},
    {"value": "utility_van", "label": "Utility Van"}
  ];

  const editVehicleURL = ['master', 'owner', 'admin', 'sales', 'service'].includes(user.permission) ? '/api/vehicle/salesEdit' : '/api/vehicle/request';
  const editVehicleMethod = ['master', 'owner', 'admin', 'sales', 'service'].includes(user.permission) ? 'PUT' : 'POST';

  const onChange = (name, value) => {
    setModalData({
      ...modalData,
      [name]: value
    });
  };

  const formData = {
    userName: {
      type: 'hidden',
      value: user.name
    },
    user_id: {
      type: 'hidden',
      value: user.accounts[0].user_id
    },
    client: {
      type: 'hidden',
      value: company.type || 'customer'
    },
    editedField: {
      type: 'hidden',
      value: `Vehicle ${props.data.VIN} Edited`
    },
    dotNumber: {
      type: 'hidden',
      value: dotNumber,
    },
    vehicleId: {
      type: 'hidden',
      value: props.data.vehicleId,
    },
    requestType: {
      type: 'hidden',
      value: props.data.vehicleId,
    },
    vin: {
      type: 'hidden',
      value: modalData.VIN,
    },
    year: {
      type: 'hidden',
      value: modalData.year,
    },
    make: {
      type: 'hidden',
      value: modalData.make,
    },
    model: {
      type: 'hidden',
      value: modalData.model,
    },
    value: {
      type: 'hidden',
      value: modalData.value,
    },
    classKey: {
      type: 'hidden',
      value: modalData.classKey,
    },
    bodyTypeKey: {
      type: 'hidden',
      value: modalData.bodyTypeKey,
    },
    deductibleType: {
      type: 'hidden',
      value: modalData.deductibleType,
    },
    deductible: {
      type: 'hidden',
      value: modalData.deductible,
    },
    status: {
      type: 'hidden',
      value: modalData.status,
    }
  };

  return (
    <>
      <header className='relative pb-5 mb-5 border-2 border-slate-100 border-b'>
        <h1 className='float:left m-0 text-xl font-bold text-center'>Edit Vehicle</h1>
      </header>
      <div className='flex flex-wrap justify-between'>
        <TextInput
          id='VIN'
          name= 'VIN'
          label= 'VIN'
          value= { modalData.VIN }
          className= 'w-full'
          containerClass= 'w-[49%]'
          required= { company.type === 'customer' }
          onChange= { onChange }
        />
        <NumberInput
          id='year'
          name= 'year'
          label= 'Year'
          value= { modalData.year }
          className= 'w-full'
          containerClass= 'w-[49%]'
          required= { company.type === 'customer' }
          onChange= { onChange }
        />
        <TextInput
          id='make'
          name= 'make'
          label= 'Make'
          value= { modalData.make }
          className= 'w-full'
          containerClass= 'w-[49%]'
          required= { true }
          onChange= { onChange }
        />
        <TextInput
          id='model'
          name= 'model'
          label= 'Model'
          value= { modalData.model }
          className= 'w-full'
          containerClass= 'w-[49%]'
          required= { company.type === 'customer' }
          onChange= { onChange }
        />
        <MoneyInput
          id='value'
          name= 'value'
          label= 'Stated Value'
          value= { modalData.value }
          className= 'w-full'
          containerClass= 'w-[49%]'
          required= { company.type === 'customer' }
          onChange= { onChange }
        />
        <Select
          id='classKey'
          name= 'classKey'
          label= 'Class'
          default= { modalData.classKey }
          value= { modalData.classKey }
          options= {classOptions}
          class= 'w-full'
          containerClass= 'w-[49%]'
          required= { company.type === 'customer' }
          onChange= { onChange }
        />
        <Select
          id='bodyTypeKey'
          name= 'bodyTypeKey'
          label= 'Body Type'
          default= { modalData.bodyTypeKey }
          value= { modalData.bodyTypeKey }
          options= {bodyTypeOptions}
          class= 'w-full'
          containerClass= 'w-[49%]'
          required= { company.type === 'customer' }
          onChange= { onChange }
        />
        <Select
          id='status'
          name= 'status'
          label= 'Status'
          default= { modalData.status }
          value= { modalData.status }
          options= {[
            { value: 'active', label: 'Active'},
            { value: 'inactive', label: 'Inactive'},
          ]}
          class= 'w-full'
          containerClass= 'w-[49%]'
          required= { company.type === 'customer' }
          onChange= { onChange }
        />
      </div>

      <Form
        method={ editVehicleMethod }
        url={ editVehicleURL }
        data={ formData }
        buttonText='Save'
        buttonClass='flex justify-end w-full mt-4'
        className='flex justify-between'
        cancel={ e => context.modal.hide(true) }
        updateOnChange
      />
    </>
  )

};

export default VehiclesForm;
