import { useContext, useState } from 'react';
import { ViewContext, Button, Form, TextInput } from 'components/lib';
import Axios from 'axios';

const AddTrailerRunQuote = (props) => {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem('company'));
  const [loading, setLoading] = useState(false);
  const [validVIN, setValidVIN] = useState(true);
  const [VIN, setVIN] = useState('');
  const bodyTypeOptions = [
    {"value": "auto_transporter_trailer", "label": "Auto Transporter Trailer"},
    {"value": "chip_trailer", "label": "Chip Trailer"},
    {"value": "curtain_van_trailer", "label": "Curtain Van Trailer"},
    {"value": "double_van_trailer", "label": "Double Van Trailer"},
    {"value": "dry_bulk_trailer", "label": "Dry Bulk Trailer"},
    {"value": "dry_van_trailer", "label": "Dry Van Trailer"},
    {"value": "dump_trailer", "label": "Dump Trailer"},
    {"value": "flatbed_trailer", "label": "Flatbed Trailer"},
    {"value": "gooseneck_trailer", "label": "Gooseneck Trailer"},
    {"value": "hopper_trailer", "label": "Hopper Trailer"},
    {"value": "intermodal_container_hauler_trailer", "label": "Intermodal Container Hauler Trailer"},
    {"value": "logging_trailer", "label": "Logging Trailer"},
    {"value": "low_boy_trailer", "label": "Low Boy Trailer"},
    {"value": "pup_trailer", "label": "Pup Trailer"},
    {"value": "refrigerated_van_trailer", "label": "Refrigerated Van Trailer"},
    {"value": "step_deck_trailer", "label": "Step Deck Trailer"},
    {"value": "tanker_trailer", "label": "Tanker Trailer"},
    {"value": "tilt_deck_trailer", "label": "Tilt deck Trailer"},
    {"value": "triple_van_trailer", "label": "Triple Van Trailer"},
    {"value": "utility_trailer", "label": "Utility Trailer"}
  ];
  const [modalData, setModalData] = useState({
    userName: {
      type: 'hidden',
      value: user.name
    },
    user_id: {
      type: 'hidden',
      value: user.accounts[0].user_id
    },
    client: {
      type: 'hidden',
      value: company.type || 'customer'
    },
    editedField: {
      type: 'hidden',
      value: `Vehicle ${VIN} Created`
    },
    dotNumber: {
      type: 'hidden',
      value: company['dot_#'],
    },
    vin: {
      type: 'hidden',
      value: VIN
    },
    typeOfVehicle: {
      type: 'hidden',
      value: 'trailer'
    },
    year: {
      id: 'year',
      label: 'Year',
      type: 'number',
      class: 'w-full',
      containerClass: 'w-[32%]',
      required: true,
    },
    make: {
      id: 'make',
      label: 'Make of manufacturer',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[32%]',
      required: true,
    },
    model: {
      id: 'model',
      label: 'Model',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[32%]',
      required: true,
    },
    value: {
      for: 'value',
      label: 'Value',
      type: 'money',
      class: 'w-full',
      containerClass: 'w-full',
      required: true,
    },
    bodyTypeKey: {
      id: 'bodyTypeKey',
      label: 'Body type',
      type: 'select',
      options: bodyTypeOptions,
      class: 'w-full',
      containerClass: 'w-full',
    },
    deductibleType: {
      type: 'hidden',
      value: 'Deductible Type',
    },
    deductible: {
      type: 'hidden',
      value: '5000',
    },
    status: {
      type: 'hidden',
      value: 'active'
    }
  });
  const addVehicleURL = '/api/vehicle/salesAdd';

  async function getDataVIN(){

    setLoading(true);
  
    if (VIN === '' || VIN === '0'){

      setLoading(false);
      setValidVIN(false)
      return false;

    };

    try {

      let res = await Axios.get(

        `https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${VIN}?format=json`

      );

      if (res.data.error) {
        setLoading(false);
        return;
      };

      const year = res.data.Results?.filter(item => item.Variable === 'Model Year')[0].Value || '';
      const make = res.data.Results?.filter(item => item.Variable === 'Make')[0].Value || '';
      const model = res.data.Results?.filter(item => item.Variable === 'Model')[0].Value || '';

      setModalData((prevState)=> {
        return({
          ...prevState,
          vin: {
            type: 'hidden',
            value: VIN
          },
          year: {
            id: 'year',
            label: 'Year',
            type: 'number',
            class: 'w-full',
            containerClass: 'w-[32%]',
            required: true,
            value: year
          },
          make: {
            id: 'make',
            label: 'Make of manufacturer',
            type: 'text',
            class: 'w-full',
            containerClass: 'w-[32%]',
            required: true,
            value: make
          },
          model: {
            id: 'model',
            label: 'Model',
            type: 'text',
            class: 'w-full',
            containerClass: 'w-[32%]',
            required: true,
            value: model
          },
        });
      });

      // finish loading
      setLoading(false);

    }
    catch (err){
      setLoading(false);
    }
  };

  const handleVehicleChange = (inputName) => {
    setModalData((prevState)=> {
      return({
        ...prevState,
        vin: {
          type: 'hidden',
          value: VIN
        },
        [inputName.input]: {
          ...prevState[inputName.input],
          value: inputName.value
        },
      });
    });
  };

  return (
    <>
      <div className='flex justify-between'>
        <TextInput
          id='vin'
          name= 'vin'
          label= 'Trailer identification number (VIN)'
          value= { VIN }
          className= 'w-full'
          containerClass= 'w-[75%]'
          valid= { validVIN }
          onChange= { (name, value) => setVIN(value) }
        />
        <Button
          color='bluesign'
          small
          className='mt-7'
          text='Add Trailer'
          action={ getDataVIN }
          loading={ loading }
        />
      </div>
      <Form
        data={ modalData }
        url={ addVehicleURL }
        method='POST'
        buttonText='Save'
        buttonClass='flex justify-end w-full mt-4'
        className='flex flex-wrap justify-between'
        cancel={ e => context.modal.hide(true) }
        updateOnChange
        onChange={ handleVehicleChange }
        callback={ res => {
          context.modal.hide(false, res);
        }}
      />
    </>
  )

};

export default AddTrailerRunQuote;