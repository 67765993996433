/***
*
*   Activity Log Page
*
**********/

import { React, Fragment } from 'react';
import { Card, Table, Animate, useAPI } from 'components/lib';
import { CompanyNav } from 'components/nav/company/companyNav';

export function ActivityLog(props){
  const company = JSON.parse(localStorage.getItem('company'));
  
  const activityData = useAPI(`/api/company/history?dotNumber=${company ? company["dot_#"] : ""}`);

  let activity = [];

  if (activityData?.data?.Companies[0].editHistory?.length){
    activity = activityData.data?.Companies[0].editHistory.map(x => {
      return {

        information_Modified: x.editedField,
        person_that_Modified: x.editedByName,
        date_modified: x.editedDate
      }
    })
  };

  return (
    <Fragment>

      <CompanyNav />

      <Animate type='pop'>

        <Card>
          <Table
            search
            loading={activityData.loading}
            sort="date_modified"
            data={ activity }
            paginate
            paginateLimit='20'
          />
        </Card>

      </Animate>

    </Fragment>
  );
};
