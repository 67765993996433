import { Text, View } from "@react-pdf/renderer";
import Box from "./box";
function ThirdRow() {
  return (
    <View style={{ flexDirection: "row", height: "55px" }}>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "25%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <View style={{ flexDirection: "row", fontSize: "5.7px" }}>
          <View
            style={{
              flexDirection: "row",
              fontFamily: "Helvetica-Bold",
              flexGrow: "1"
            }}
          >
            <Box />
            <Text style={{ marginLeft: "2px", paddingTop: "6px" }}>
              UMBRELLA LIAB
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              fontFamily: "Helvetica",
              flexGrow: "1",
              marginLeft: "-10px"
            }}
          >
            <Box />
            <Text style={{ marginLeft: "2px", paddingTop: "6px" }}>OCCUR</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            fontSize: "5.7px",
            borderBottom: "1px solid black",
            marginBottom: "0"
          }}
        >
          <View
            style={{
              flexDirection: "row",
              fontFamily: "Helvetica-Bold",
              flexGrow: "1"
            }}
          >
            <Box />
            <Text
              style={{
                marginRight: "5px",
                marginLeft: "2px",
                paddingTop: "5px"
              }}
            >
              EXCESS LIAB
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              fontFamily: "Helvetica",
              flexGrow: "1",
              marginLeft: "12.7px"
            }}
          >
            <Box />
            <Text style={{ marginLeft: "2px", paddingTop: "6px" }}>
              CLAIMS-MADE
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", fontSize: "5.7px" }}>
          <View style={{ flexDirection: "row", fontFamily: "Helvetica-Bold" }}>
            <Box />
            <Text
              style={{
                marginRight: "5px",
                marginLeft: "2px",
                paddingTop: "6px"
              }}
            >
              DED
            </Text>
          </View>
          <View style={{ flexDirection: "row", fontFamily: "Helvetica" }}>
            <Box />
            <Text style={{ marginLeft: "2px", paddingTop: "6px" }}>
              RETENTIONS $
            </Text>
            <Text style={{ marginLeft: "2px", paddingTop: "6px" }}>
              Lorem ipsum
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "18%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View style={{ width: "29%", borderBottom: "1px solid black" }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
            fontFamily: "Helvetica"
          }}
        >
          <View
            style={{
              flex: "1",
              borderRight: "1px solid black",
              fontSize: "5px"
            }}
          >
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "33.33%",
                paddingTop: "4px",
                paddingLeft: "2px"
              }}
            >
              EACH OCCURENCE
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "33.33%",
                paddingTop: "4px",
                paddingLeft: "2px"
              }}
            >
              AGGREGATE
            </Text>
            <Text
              style={{
                height: "33.33%",
                paddingTop: "4px",
                paddingLeft: "1.5px"
              }}
            ></Text>
          </View>
          <View style={{ flex: "1", fontSize: "6px" }}>
            <View
              style={{
                height: "33.33%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
            <View
              style={{
                height: "33.33%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
            <View
              style={{
                height: "33.33%",
                flexDirection: "row",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
export default ThirdRow;
