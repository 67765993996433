import React, { useContext } from "react";
import { useNavigate } from "components/lib";
import { Button, ViewContext } from "components/lib";

const ModalRunQuote = props => {
  const context = useContext(ViewContext);
  const navigate = useNavigate();

  const coverwhaleRedirect = () => {
    context.modal.hide();
    navigate(`/${props.clientType}/runquote`, {
      state: { data: "CW_Quote" }
    });
  };

  return (
    <div className="flex justify-center">
      <Button text="CoverWhale" color="purple" action={coverwhaleRedirect} />
    </div>
  );
};

export default ModalRunQuote;
