/***
 *
 *   Customers Page
 *
 **********/

import { React, useContext, useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Animate,
  ViewContext,
  TitleRow,
  TabView,
} from "components/lib";
import axios from "axios";

export function Customers(props) {
  const [companiesData, setCompaniesData] = useState([]);
  const [archiveCompanies, setArchiveCompanies] = useState([]);
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const res = await axios.get("/api/user/getCompanies");

    // format the companies list table
    let list = [];

    if (res.data?.length) {
      list = res.data.map((x) => {
        return {
          "dot_#": x.dotNumber,
          company_name: x.legalName,
          owner_name: x.companyRep1,
          phone: x.telephone,
          email: x.emailAddress,
          status: x.status,
          type: "customer",
        };
      });
    }
    setCompaniesData(list);
    const resArchive = await axios.get("/api/user/getArchived");
    let archiveList = [];

    if (resArchive.data) {
      archiveList = resArchive.data.map((x) => {
        return {
          "dot_#": x.dotNumber,
          company_name: x.legalName,
          owner_name: x.companyRep1,
          phone: x.telephone,
          email: x.emailAddress,
          status: x.status,
          type: "customer",
        };
      });
    }
    setArchiveCompanies(archiveList);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const addCustomer = () => {
    context.modal.show(
      {
        title: "Add Company",
        addCompany: "Company",
      },
      (form, res) => {
        if (res) {
          context.notification.show(
            `Company ${res.addedCompany.legalName} Created `,
            "success",
            true
          );
          const company = {
            "dot_#": res.addedCompany.dotNumber,
            company_name: res.addedCompany.legalName,
            owner_name: res.addedCompany.companyRep1,
            phone: res.addedCompany.telephone,
            email: res.addedCompany.emailAddress,
            status: res.addedCompany.status,
            type: "customer",
          };
          setCompaniesData([company, ...companiesData]);
        }
      }
    );
  };

  const editCustomer = (data, callback) => {
    context.modal.show(
      {
        title: "Edit Company",
        form: {
          userName: {
            type: "hidden",
            value: user.name,
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          editedField: {
            type: "hidden",
            value: `Company ${data.company_name} status Edited`,
          },
          legalName: {
            id: "legalName",
            label: "Company Name",
            type: "text",
            value: data.company_name,
            class: "w-full",
            containerClass: "w-[49%]",
            readonly: true,
          },
          dotNumber: {
            type: "hidden",
            value: data["dot_#"],
          },
          status: {
            label: "Status",
            type: "select",
            value: data.status,
            default: data.status,
            class: "w-full",
            options: [
              { value: "active", label: "Active" },
              { value: "inactive", label: "Inactive" },
            ],
            containerClass: "w-[49%]",
            required: true,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Save",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/company/salesEdit",
        method: "PUT",
      },
      (form, res) => {
        if (res?.editedCompany) {
          context.notification.show(
            res.editedCompany.legalName + " updated",
            "success",
            true
          );

          const newArr = companiesData.map((element) => {
            if (element["dot_#"] === res.editedCompany.dotNumber) {
              const editedCompany = {
                "dot_#": res.editedCompany.dotNumber,
                company_name: res.editedCompany.legalName,
                owner_name: res.editedCompany.companyRep1,
                phone: res.editedCompany.telephone,
                email: res.editedCompany.emailAddress,
                status: res.editedCompany.status,
              };
              return editedCompany;
            }
            return element;
          });

          setCompaniesData(newArr);
        }
      }
    );
  };

  const archiveCompany = (data) => {
    context.modal.show(
      {
        title: "Archive Company",
        text: `Are you sure you want to archive Vehicle "${data.company_name}" ?`,
        secondText:
          "Warning: All archived items (such as archived vehicles) will be converted to active",
        form: {
          userName: {
            type: "hidden",
            value: user.name,
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          editedField: {
            type: "hidden",
            value: `Company "${data.company_name}" Archived`,
          },
          dotNumber: {
            type: "hidden",
            value: data["dot_#"],
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Archive",
        buttonClass: "flex justify-end w-full",
        url: "/api/company/archive",
        method: "DELETE",
      },
      (form, res) => {
        if (res.Archives) {
          let companiesList = [...companiesData];
          setCompaniesData(
            companiesList.filter((item) => item["dot_#"] !== data["dot_#"])
          );
          context.notification.show("Company Archived", "success", true);

          delete data.actions;

          const archiveList = [data, ...archiveCompanies];

          setArchiveCompanies(archiveList);
        }
      }
    );
  };

  const restoreCompany = (data) => {
    context.modal.show(
      {
        title: "Restore Company",
        text: `Are you sure you want to restore company "${data.company_name}" ?`,
        form: {
          dotNumber: {
            type: "hidden",
            value: data["dot_#"],
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          userName: {
            type: "hidden",
            value: user.name,
          },
          editedField: {
            type: "hidden",
            value: `Restored company "${data.company_name}"`,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Restore",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/company/restore",
        method: "POST",
      },
      (form, res) => {
        if (res) {
          delete data.actions;

          let companiesLiest = [data, ...companiesData];
          setCompaniesData(companiesLiest);

          const archiveList = [...archiveCompanies];
          setArchiveCompanies(
            archiveList.filter((item) => item["dot_#"] !== data["dot_#"])
          );

          context.notification.show("Company Restored", "success", true);
        }
      }
    );
  };

  return (
    <Animate type="pop">
      <TitleRow>
        {!["customer"].includes(user.permission) && (
          <Button
            small
            text="Add Customer"
            icon="plus-circle"
            className="bg-blue-700 text-white rounded mr-4"
            action={addCustomer}
          />
        )}
      </TitleRow>

      <TabView
        name="customers"
        labels={["Active Customers", "Archived Customers"]}
      >
        <Card>
          <Table
            search
            data={companiesData}
            loading={loading}
            actions={{
              viewCompany: {
                col: "dot_#",
                saveData: true,
              },
              edit: editCustomer,
              custom: [
                {
                  col: "archive",
                  value: "archive",
                  icon: "archive",
                  action: archiveCompany,
                },
              ],
            }}
            badge={{
              col: "status",
              color: "orange",
              condition: [
                { value: "inactive", color: "red" },
                { value: "active", color: "green" },
              ],
            }}
            show={[
              "dot_#",
              "company_name",
              "owner_name",
              "phone",
              "email",
              "status",
            ]}
            paginate
            paginateLimit="10"
          ></Table>
        </Card>

        <Card>
          <Table
            search
            data={archiveCompanies}
            loading={loading}
            actions={{
              custom: [
                {
                  col: "restore",
                  value: "restore",
                  icon: "rotate-ccw",
                  action: restoreCompany,
                },
              ],
            }}
            show={["dot_#", "company_name", "owner_name", "phone", "email"]}
            paginate
            paginateLimit="5"
          ></Table>
        </Card>
      </TabView>
    </Animate>
  );
}
