import { React, useState } from 'react';
import { Button, TextInput, NumberInput } from 'components/lib';

const VehiclesDataForm = (props) => {
  const [vehicle, setVehicle] = useState({
    vehicleYear: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleVIN: '',
  });
  const [vehicles, setVehicles] = useState([
    {
      vehicleYear: '2021',
      vehicleMake: 'vehicleMake1',
      vehicleModel: 'vehicleModel1',
      vehicleVIN: '124241241231',
    },
  ]);

  const handleVehicleChange = (inputName, newValue) => {
    setVehicle((prevState)=> {
      return({
        ...prevState,
        [inputName]: newValue,
      });
    });
  };

  const handleAddVehicle = () => {
    setVehicles([vehicle, ...vehicles]);
    setVehicle({
      vehicleYear: '',
      vehicleMake: '',
      vehicleModel: '',
      vehicleVIN: '',
    });
  };

  return (
    <>
      {vehicles.length > 0 &&
        <div>
          {vehicles.map((vehicle) => (
            <div className='flex items-end gap-4 w-full' key={vehicle.vehicleVIN}>
              <NumberInput
                type='number'
                label='Year'
                value={vehicle.vehicleYear}
                containerClass='w-[18%]'
                className='w-full text-sm'
                readonly
              />
              <TextInput
                type='text'
                label='Make'
                value={vehicle.vehicleMake}
                containerClass='w-[18%]'
                className='w-full text-sm'
                readonly
              />
              <TextInput
                type='text'
                label='Model'
                value={vehicle.vehicleModel}
                containerClass='w-[18%]'
                className='w-full text-sm'
                readonly
              />
              <TextInput
                type='text'
                label='VIN'
                value={vehicle.vehicleVIN}
                containerClass='w-[18%]'
                className='w-full text-sm'
                readonly
              />
              <Button
                icon='minus-square'
                iconColor='white'
                className='bg-[#3056d3] !w-10 h-10 rounded-md mb-2 ml-auto mr-4'
                action={ () => setVehicles(vehicles.filter(item => item.vehicleVIN !== vehicle.vehicleVIN)) }
              />
            </div>
          ))}
        </div>
      }

      <div className='flex items-end gap-4'>
        <NumberInput
          type='number'
          name='vehicleYear'
          label='Year'
          value={vehicle.vehicleYear}
          onChange={handleVehicleChange}
          containerClass='w-[18%]'
          className='w-full text-sm'
        />
        <TextInput
          id='vehicleMake'
          type='text'
          name='vehicleMake'
          label='Make'
          value={vehicle.vehicleMake}
          onChange={handleVehicleChange}
          containerClass='w-[18%]'
          className='w-full text-sm'
        />
        <TextInput
          id='vehicleModel'
          type='text'
          name='vehicleModel'
          label='Model'
          value={vehicle.vehicleModel}
          onChange={handleVehicleChange}
          containerClass='w-[18%]'
          className='w-full text-sm'
        />
        <TextInput
          id='vehicleVIN'
          type='text'
          name='vehicleVIN'
          label='VIN'
          value={vehicle.vehicleVIN}
          onChange={handleVehicleChange}
          containerClass='w-[18%]'
          className='w-full text-sm'
        />
        <Button
          icon='plus-circle'
          iconColor='white'
          className='bg-[#3056d3] !w-10 h-10 rounded-md mb-2 ml-auto mr-4'
          action={ handleAddVehicle }
        />
      </div>
    </>
  )
};

export default VehiclesDataForm;
