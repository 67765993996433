/***
 *
 *   LOADER
 *   Infinite spinning animation for loading states
 *
 **********/

import Orbit from "./images/orbit.svg";
import Loading from "./images/loading.svg";
import { ClassHelper } from "components/lib";
import Style from "./loader.tailwind.js";

export function Loader(props) {
  const loaderStyle = ClassHelper(Style, props);

  return (
    <div className={props.className ? props.className : loaderStyle}>
      {props.loading === "new loading" ? (
        <img src={Loading} className={Style.orbit}  alt="Loading Spinner" />
      ) : (
        <img src={Orbit} className={Style.orbit} alt="Orbit Spinner" />
      )}
    </div>
  );
}
