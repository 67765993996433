/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import { Fragment, useContext } from "react";
import { AuthContext, AppNav, Header, User } from "components/lib";
import Style from "./app.module.scss";

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);
  let path = window.location.pathname;
  let costumersPath = path.split("/customers/");
  let prospectsPath = path.split("/prospects/");

  if (!costumersPath[1]?.length > 0 && !prospectsPath[1]?.length > 0 && path !== "/review") {
    localStorage.removeItem("company");
  }
  const company = JSON.parse(localStorage.getItem("company"));

  const itemsArray = [
    { label: "Customers", icon: "users", link: "/customers" },
    { label: "Prospects", icon: "clipboard", link: "/prospects" },
    { label: "Requests", icon: "check-circle", link: "/requests" },
    { label: "Search", icon: "search", link: "/searchcompany" }
  ];

  return (
    <Fragment>
      <AppNav
        items={itemsArray}
        fixedItems={[
          { label: "Account", icon: "user", link: "/account" },
          { label: "Help", icon: "help-circle", link: "/help" },
          { label: "Sign Out", icon: "log-out", action: context.signout }
        ]}
      />

      <main className={Style.app}>
        {props.title && (
          <Header title={company ? company.company_name : props.title}>
            <User />
          </Header>
        )}

        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}
