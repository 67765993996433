const Style = {

  onboarding: 'relative w-full mt-8 mx-auto mb-12 shadow-md md:w-[55em] md:min-h-[36em]',
  sidebar: `text-white p-5 rounded-t bg-[#212B36] md:absolute md:top-0 md:left-0 md:bottom-0 
  md:z-10 md:rounded-tr-none md:rounded-bl md:w-48 flex flex-col`,
  
  logo: 'm-0',
  main: 'h-[40em] overflow-auto p-8 pt-6 pb-8 bg-white rounded-b md:rounded md:pl-56 flex flex-col',
  header: 'w-full pb-4 mb-4 bg-transparent border-b border-dotted border-slate-200',
  name: 'capitalize text-xl font-semibold',
  description: 'text-sm text-slate-400',
  checklist: 'mt-10 md:left-6',
  nav: 'mt-auto mb-4',
  prev: 'float-left',
  next: 'float-right',
  skip: '-top-7 right-0 text-sm text-white',
  article: 'h-full flex flex-col justify-between'

}

export default Style;