import { useContext } from 'react';
import { ViewContext, Form } from 'components/lib';
import usStates from '../../../views/data/us-states.json';

const AddDriverRunQuote = (props) => {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem('company'));
  const addDriverURL = '/api/driver/salesAdd';

  const modalData = {
    userName: {
      type: 'hidden',
      value: user.name
    },
    client: {
      type: 'hidden',
      value: company.type
    },
    user_id: {
      type: 'hidden',
      value: user.accounts[0].user_id
    },
    editedField: {
      type: 'hidden',
      value: 'Driver Created'
    },
    dotNumber: {
      type: 'hidden',
      value: company['dot_#'],
    },
    status: {
      type: 'hidden',
      value: 'active',
    },
    firstName: {
      id: 'firstName',
      label: 'First Name',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: true,
    },
    lastName: {
      id: 'lastName',
      label: 'Last Name',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: true,
    },
    dateOfBirth: {
      id: 'dateOfBirth',
      label: 'Date of birth',
      type: 'date',
      class: 'w-full',
      containerClass: 'w-[32%]',
      required: true,
    },
    dateOfHire: {
      id: 'dateOfHire',
      label: 'Date of Hire',
      type: 'date',
      class: 'w-full',
      containerClass: 'w-[32%]',
      required: true,
    },
    yearsExperience: {
      id: 'yearsExperience',
      label: 'Years of experience',
      type: 'number',
      class: 'w-full',
      containerClass: 'w-[32%]',
      required: true,
    },
    licenseState: {
      id: 'licenseState',
      label: 'Driver’s license state',
      type: 'select',
      options: usStates,
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: true,
    },
    licenseNumber: {
      id: 'licenseNumber',
      label: 'Driver’s license number',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[49%]',
      required: true,
    },
    frontPhoto: {
      label: "Driver's License Front Picture",
      type: 'file',
      max: 1,
      accept: ['jpg', 'png', 'jpeg', 'pdf'],
      class: 'w-full',
      containerClass: 'w-[49%]',
    },
    backPhoto: {
      label: "Driver's License Back Picture",
      type: 'file',
      max: 1,
      accept: ['jpg', 'png', 'jpeg', 'pdf'],
      class: 'w-full',
      containerClass: 'w-[49%]',
    },
    // elegibility: {
    //   id: 'elegibility',
    //   label: 'Exclude this driver from the policy?',
    //   type: 'switch',
    //   labelClassName: 'mb-0',
    //   labelRight: true,
    //   class: 'w-full',
    //   containerClass: 'w-full',
      // covered or excluded
    // },
    mvr: {
      label: 'MVR Summary (prior 36 months)',
      type: 'header',
      class: 'w-full mt-4',
      containerClass: 'w-[32%] mt-4',
    },
    accidents: {
      id: 'accidents',
      label: 'Accidents',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[32%]',
    },
    violations: {
      id: 'violations',
      label: 'Violations',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[32%]',
    },
    suspensions: {
      id: 'suspensions',
      label: 'suspensions',
      type: 'text',
      class: 'w-full',
      containerClass: 'w-[32%]',
    },
  };

  return (
    <Form
      data={ modalData }
      url={ addDriverURL }
      method='POST'
      buttonText='Save'
      buttonClass='flex justify-end w-full mt-4'
      className='flex flex-wrap justify-between'
      cancel={ e => context.modal.hide(true) }
      callback={ res => {
        context.modal.hide(false, res);
      }}
    />
  )

};

export default AddDriverRunQuote;