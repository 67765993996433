import { Text, View } from "@react-pdf/renderer";
function Desription({ vehicles, remark, drivers, secondDescription }) {
  const numberDrivers = drivers?.length ?? 0;
  const numberVehicles = vehicles?.length ?? 0;

  const vehicleText = (vehicle, index) => {
    return (
      <Text style={{ padding: "2px" }} key={vehicle.vin}>
        {index}. VIN:{vehicle.vin}, Year: {vehicle.year}, Make: {vehicle.make},
        Model: {vehicle.model}, Value: ${vehicle.value}
      </Text>
    );
  };
  const driverText = (driver, index) => {
    return (
      <Text style={{ padding: "2px" }} key={index}>
        {index}. Driver: {driver.firstName ?? ""} {driver.lastName ?? ""},
        License: {driver.licenseNumber}
      </Text>
    );
  };

  const OperationSection = () => {
    if (secondDescription) {
      return (
        <>
          {numberDrivers > 0 &&
            drivers.map((driver, index) => driverText(driver, index + 1))}
          {numberVehicles > 0 &&
            vehicles.map((vehicle, index) => vehicleText(vehicle, index + 1))}
        </>
      );
    } else {
      if (numberVehicles + numberDrivers < 6) {
        return (
          <>
            {numberDrivers > 0 &&
              drivers.map((driver, index) => driverText(driver, index + 1))}
            {numberVehicles > 0 &&
              vehicles.map((vehicle, index) => vehicleText(vehicle, index + 1))}
            {numberVehicles === 0 && (
              <Text style={{ padding: "2px" }}>There aren't vehicles</Text>
            )}
          </>
        );
      } else {
        return (
          <>
            {numberDrivers > 0 && (
              <Text style={{ padding: "2px" }}>
                There are {numberDrivers} drivers on the second page
              </Text>
            )}
            <Text style={{ padding: "2px" }}>
              There are {numberVehicles} vehicles on the second page
            </Text>
          </>
        );
      }
    }
  };

  return (
    <View
      style={{
        border: "1.5px solid black",
        width: "95%",
        fontSize: "7.5px",
      }}
    >
      <Text style={{ fontFamily: "Helvetica-Bold", padding: "2px" }}>
        DESCRIPTION OF OPERATIONS / LOCATIONS / VEHICLES / (Attach ACORD 101,
        Additional Remarks Schedule, if more space is required)
      </Text>
      <OperationSection />
      <Text style={{ width: "90%", fontSize: "8px", paddingLeft: "2px" }}>
        {remark}
      </Text>
    </View>
  );
}
export default Desription;
