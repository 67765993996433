import CurrencyInput from 'react-currency-input-field';
import { Label } from 'components/lib';
import Style from './input.tailwind.js';

export function MoneyInput(props){

  return(
    <div className={ Style.input + " " + props.containerClass }>

      { props.label && 
        <Label
          text={ props.label }
          required={ props.required }
          for={ props.id }
        />
      }

      <CurrencyInput
        id={ props.id }
        name={ props.name }
        defaultValue={1000}
        decimalsLimit={2}
        value={ props.value || '' }
        placeholder='$'
        prefix="$"
        decimalSeparator="."
        groupSeparator=","
        onValueChange={(value, name) => props.onChange?.(name, value, undefined)}
        className='p-3 text-sm w-full rounded bg-white border bordeer-solid border-slate-300 focus:bg-slate-50 appearance-none disabled:opacity-50'
        readOnly= {props.readonly}
      />
     </div>
  );
}
