import { useContext, useState } from "react";
import { ViewContext, Form } from "components/lib";

const AddDocumentForm = ({ url, client }) => {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const company = JSON.parse(localStorage.getItem("company"));
  const dotNumber = company["dot_#"];
  const [modalData, setModalData] = useState({
    userName: {
      type: "hidden",
      value: user.name
    },
    client: {
      type: "hidden",
      value: client
    },
    user_id: {
      type: "hidden",
      value: user.accounts[0].user_id
    },
    uploadedBy: {
      type: "hidden",
      value: user.name
    },
    editedField: {
      type: "hidden",
      value: "Document Created"
    },
    dotNumber: {
      type: "hidden",
      value: dotNumber
    },
    documentName: {
      id: "documentName",
      label: "Document Name",
      type: "text",
      class: "w-full",
      containerClass: "w-[49%]",
      required: true
    },
    documentType: {
      label: "Document Type",
      type: "select",
      options: [
        { value: "IDCard", label: "ID Card" },
        { value: "certificates", label: "Certificates" },
        { value: "quotes", label: "Quotes" },
        { value: "policies", label: "Policies" },
        { value: "driversLicense", label: "Drivers License" },
        { value: "lossRuns", label: "Loss Runs" },
        { value: "agreements", label: "Agreements" },
        { value: "proofofPriorInsurance", label: "Proof of Prior Insurance" },
        {
          value: "underinsuredMotoristForm",
          label: "Underinsured Motorist Form"
        },
        { value: "checklist", label: "Checklist" }
      ],
      class: "w-full",
      containerClass: "w-[49%]",
      required: true
    },
    document: {
      label: "Upload Document",
      type: "file",
      max: 1,
      containerClass: "w-full",
      class: "w-full",
      required: true
    },
    permission: {
      label: "View Access",
      type: "checkbox",
      name: "permission",
      options: ["Admin", "Sales", "Services", "Customer"],
      default: ["Admin"],
      required: true,
      cols: "4",
      class: "w-[49%]"
    }
  });

  const handleChange = (inputName) => {
    if(inputName.input === "documentName") {
      const documentName = inputName.value.split(".")[0];
      setModalData((prevState) => {
        return {
          ...prevState,
          editedField: {
            type: "hidden",
            value: `Document ${documentName} Created`
          },
          [inputName.input]: {
            ...prevState[inputName.input],
            value: inputName.value
          }
        };
      });
      return
    }
    if(inputName.input === "document") {
      if (!inputName.value[0].data) {
        setModalData((prevState) => {
          return {
            ...prevState,
            documentName: {
              ...prevState.documentName,
              value: ""
            },
            [inputName.input]: {
              ...prevState[inputName.input],
              value: [],
              valid: false,
            }
          };
        });
        
      } else {
        const documentName = inputName.value[0].name.split(".")[0];
        setModalData((prevState) => {
          return {
            ...prevState,
            documentName: {
              ...prevState.documentName,
              valid: true,
              value: documentName
            },
            editedField: {
              type: "hidden",
              value: `Document ${documentName} Created`
            },
            [inputName.input]: {
              ...prevState[inputName.input],
              value: inputName.value
            }
          };
        });
      }
    } else {
      setModalData((prevState) => {
        return {
          ...prevState,
          [inputName.input]: {
            ...prevState[inputName.input],
            value: inputName.value
          }
        };
      });
    }
  };

  return (
    <Form
      data={modalData}
      url={url}
      method="POST"
      buttonText="Save"
      buttonClass="flex justify-end w-full mt-4"
      className="flex flex-wrap justify-between"
      cancel={(e) => context.modal.hide(true)}
      updateOnChange
      onChange={handleChange}
      callback={(res) => {
        context.modal.hide(false, res);
      }}
    />
  );
};

export default AddDocumentForm;
