/***
*
*   ONBOARDING
*   Flow to help users set up the app, accepts multiple views/steps 
*   On finish/cancel, the user will be marked as onboarded
*
*   PROPS
*   views: array of views to render containing keys: 
*     name, description and component to render
*
**********/

import { useState } from 'react';
import Axios from 'axios';
import { Animate, CheckList, Button, Logo, useNavigate, Event } from 'components/lib';
import Style from './onboarding.tailwind.js';

export function Onboarding(props){

  const navigate = useNavigate();
  const hash = window.location.hash;
  const [activeView, setActiveView] = useState(hash ? hash.substring(1)-1 : 0);

  if (!props?.views?.length)
    return false;

  const view = props.views[activeView];

  async function goTo(view){

    window.location.hash = view + 1;
    setActiveView(view);

  }

  async function finish(){

    if (props.save)
      await Axios.patch('/api/user', { onboarded: true });
      
    navigate(props.onFinish || '/customers');
  
  }

  const progressPercentage = activeView / props.views.length * 100;

  const Parentdiv = {
    height: 10,
    width: '100%',
    borderRadius: 40,
  }
    
  const Childdiv = {
    height: '100%',
    width: `${progressPercentage > 100 ? 100 : progressPercentage}%`,
    backgroundColor: 'rgb(255,255,255)',
    borderRadius:40,
  }
    
  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 900
  }

  return (
    <Animate type='pop-center'>
      <div className={ Style.onboarding }>

        { !props.noSkip &&
          <Button
            color='light'
            icon='arrow-right-circle'
            text='Skip Intro'
            position='absolute'
            alignRight
            className={ Style.skip } 
            size={ 16 } 
            action={ () => {
              
              Event('cancelled_onboarding');
              finish();
            
            }}
          />
        }
      

        <section className={ Style.sidebar }>

          <Logo className={ Style.logo }/>

          { props.progressBar &&
            <div className='mt-4'>
              <p className='text-center'>Application Progress</p>
              <div className='flex items-center gap-2'>
                <div style={Parentdiv} className="border border-white">
                  <div style={Childdiv} className="flex flex-col">
                    <span style={progresstext} />
                  </div>
                </div>
                <p className="w-fit text-gray-400 whitespace-nowrap">{Math.trunc(progressPercentage)} %</p>
              </div>
            </div>
          }

          <CheckList color='white'
            className={ Style.checklist } 
            hideCross interactive
            items={ props.views.map((view, i) => {

              return { name: view.name, checked: i <= activeView ? true : false, onClick: () => goTo(i) }

            })
          }/>

        </section>

        <section className={ Style.main }>
                  
          <header className={ Style.header }>
            <h2 className={ Style.name }>
              { view.name }
            </h2>

            { view.description && 
            <span className={ Style.description }>
              { view.description }
            </span> 
            }
          </header>

          <article className={ Style.article }>
            { view.component }
            <nav className={ Style.nav }>
              
              { activeView > 0 &&
                <Button 
                  icon='chevron-left'
                  iconColor={ props.buttonPreviconColor || 'dark'}
                  alignLeft
                  color={ props.buttonPrevColor || 'dark'}
                  size={ 16 } 
                  text='Prev' 
                  className={ props.buttonPrevClassName || Style.prev }
                  action={ () => goTo(activeView-1) }
                />
              }

              { activeView < props.views.length-1 && 
                <Button 
                  icon='chevron-right' 
                  alignRight
                  color={ props.buttonNextColor || 'dark'} 
                  size={ 16 } 
                  text='Next' 
                  className={ props.buttonNextClassName || Style.next }
                  action={ () => { goTo(activeView+1) }}
              />
              }

              { activeView === props.views.length -1 &&
              <Button 
                icon='check-circle' 
                alignRight
                color={ props.buttonNextColor || 'dark' }
                size={ 16 } 
                text='Finish' 
                className={ props.buttonNextClassName || Style.next }
                action={ () => {
                  
                  Event('completed_onboarding');
                  finish();
                
                }}
              />
              }

            </nav>
          </article>

        </section>

    </div>
    </Animate>
  );
}