/***
 *
 *   Quotes Page
 *
 **********/

import { React, Fragment } from "react";
import {
  Card,
  useAPI,
  Animate,
  Table,
  useNavigate,
  TabView,
} from "components/lib";
import { CompanyNav } from "components/nav/company/companyNav";

export function ProspectQuotes(props) {
  const navigate = useNavigate();
  const company = JSON.parse(localStorage.getItem("company"));
  const quotesData = useAPI(`/api/company/allMyQuotes/${company ? company["dot_#"] : ""}`);
  const savedQuotesData = useAPI(`/api/prospect/getQuote/${company ? company["dot_#"] : ""}`);

  function calculatePremiumSum(coverageData) {
    let sum = 0;

    if (!coverageData || typeof coverageData !== "object") {
      return sum;
    }

    for (const key in coverageData) {
      if (Object.hasOwnProperty.call(coverageData, key)) {
        const coverage = coverageData[key];

        if (coverage && typeof coverage === "object" && coverage.premium) {
          const premium = parseFloat(coverage.premium);
          sum += isNaN(premium) ? 0 : premium;
        }
      }
    }

    return sum;
  }

  const reviewQuote = (data) => {
    delete data.actions;
    navigate("/review", { state: { data: data } });
  };

  let quotes = [];

  if (
    Array.isArray(quotesData?.data?.Certificates) &&
    quotesData?.data?.Certificates?.length > 0
  ) {
    quotes = quotesData.data.Certificates.map((item) => {
      const { date, quote } = item.Quote || {};
      const { drivers, trailers, vehicles } = item.company || {};

      const coverages =
        item.Quote?.coverages || item.policy?.data?.coverages || {};
      const submissionNumber =
        quote?.data?.submission_number ||
        quote?.submission_number ||
        "No submission number";
      const premium = calculatePremiumSum(coverages);
      const createdAt = date || "No date";
      const driversCount = drivers?.length > 0 ? drivers.length : 0;
      const vehiclesCount = vehicles?.length > 0 ? vehicles.length : 0;
      const trailersCount = trailers?.length > 0 ? trailers.length : 0;
      const status = quote?.data?.status || quote?.status || "No Status";

      const result = {
        submission_number: submissionNumber,
        premium,
        created_at: createdAt,
        _drivers: driversCount,
        _vehicles: vehiclesCount,
        _trailers: trailersCount,
        status,
        quote: {
          data: { coverages: coverages },
        },
        ...item.company,
      };

      if (Object.keys(coverages).length > 0) {
        result.actions = {
          custom: [
            {
              icon: "eye",
              action: reviewQuote,
            },
          ],
        };
      }

      return result;
    });
  }

  let savedQuotes = [];

  if (
    Array.isArray(savedQuotesData?.data?.Quotes) &&
    savedQuotesData?.data?.Quotes?.length > 0
  ) {
    savedQuotes = savedQuotesData.data.Quotes.map((quote) => {
      return {
        ...quote,
        id: quote._id,
        last_saved: quote.date ? quote.date : "No date",
        _drivers: quote.drivers.length,
        _vehicles: quote.vehicles.length,
        _trailers: quote.trailers.length,
      };
    });
  }

  const summaryQuote = (row) => {
    delete row.actions;
    navigate(`/customers/summary`, { state: { data: row } });
  };

  const editQuote = (data) => {
    delete data.actions;
    const generalLiabilityArray = [];
    data.quote.coverage?.requestApd === "Y" &&
      generalLiabilityArray.push("Automobile Physical Damage (APD)");
    data.quote.coverage?.requestMtc === "Y" &&
      generalLiabilityArray.push("Motor Truck Cargo (MTC)");
    data.quote.coverage?.requestNtl === "Y" &&
      generalLiabilityArray.push("Truckers General Liability (TGL)");
    data.quote.coverage?.requestTgl === "Y" &&
      generalLiabilityArray.push("Non-Trucking Liability (NTL)");

    const operationDescriptionArray = [];
    data.quote.operations?.opsLocal === "Y" &&
      operationDescriptionArray.push("Local trucker");
    data.quote.operations?.opsIntermediate === "Y" &&
      operationDescriptionArray.push("Intermediate");
    data.quote.operations?.opsLongHaul === "Y" &&
      operationDescriptionArray.push("Long haul trucker");
    data.quote.operations?.opsIntermodalPort === "Y" &&
      operationDescriptionArray.push("Intermodal or port");
    data.quote.operations?.opsHouseholdGoods === "Y" &&
      operationDescriptionArray.push("Household goods mover");
    data.quote.operations?.opsTanker === "Y" &&
      operationDescriptionArray.push("Tankers");
    data.quote.operations?.UIIAintermodal === "Y" &&
      operationDescriptionArray.push("UIIA - Intermodal");
    data.quote.operations?.opsDumpTruckOther === "Y" &&
      operationDescriptionArray.push("Dump trucks (Other)");
    data.quote.operations?.opsDumpTruckSandGravel === "Y" &&
      operationDescriptionArray.push("Dump trucks (sand and gravel)");
    data.quote.operations?.opsEndDumper === "Y" &&
      operationDescriptionArray.push("End dumper units");
    data.quote.operations?.opsLogging === "Y" &&
      operationDescriptionArray.push("Logging");
    data.quote.operations?.opsRefrigirated === "Y" &&
      operationDescriptionArray.push("refrigerated trailer");
    data.quote.operations?.opsOversizedOvernight === "Y" &&
      operationDescriptionArray.push("Oversized or overweight");
    data.quote.operations?.opsAutomobileHauler === "Y" &&
      operationDescriptionArray.push(
        "Automobile hauler [Excluded for cargo and NTL]"
      );
    data.quote.operations?.opsHotShot === "Y" &&
      operationDescriptionArray.push(
        "Hot shot (TGL and NTL not available for  class 3 vehicles)"
      );

    const quoteData = {
      _id: data._id,
      ownerName: data.quote.insuredInformation?.ownerName,
      dbaName: data.quote.insuredInformation?.dbaName,
      dotNumber: data.dotNumber,
      emailAddress: data.quote.insuredInformation?.email,
      legalName: data.quote.insuredInformation?.legalName,
      phone: data.quote.insuredInformation?.insuranceContactPhone,
      yearsInBusiness: data.quote.insuredInformation?.yearsInBusiness,
      monthsInBusiness: data.quote.insuredInformation?.monthsInBusiness,
      insuranceContactFirstName:
        data.quote.insuredInformation?.insuranceContactFirstName,
      insuranceContactLastName:
        data.quote.insuredInformation?.insuranceContactLastName,
      insuranceContactEmail:
        data.quote.insuredInformation?.insuranceContactEmail,
      insuranceContactPhone:
        data.quote.insuredInformation?.insuranceContactPhone,
      mailingStreet: data.quote.mailingAddress?.mailingStreet,
      mailingStreet2: data.quote.mailingAddress?.mailingStreet2,
      mailingCity: data.quote.mailingAddress?.mailingCity,
      mailingState: data.quote.mailingAddress?.mailingState,
      mailingZip: data.quote.mailingAddress?.mailingZip,
      mailingCountry: data.quote.mailingAddress?.mailingCountry,
      mailingCounty: data.quote.mailingAddress?.mailingCounty,
      garageStreet: data.quote.garageAddress?.garageStreet,
      garageCity: data.quote.garageAddress?.garageCity,
      garageState: data.quote.garageAddress?.garageState,
      garageZip: data.quote.garageAddress?.garageZip,
      garageCountry: data.quote.garageAddress?.garageCountry,
      garageCounty: data.quote.garageAddress?.garageCounty,
      effectiveDate: data.quote.coverage?.effectiveDate,
      optAlPip: data.quote.coverage?.optAlPip || "N",
      optAlUm: data.quote.coverage?.optAlUm || "N",
      requestAl: data.quote.coverage?.requestAl || "N",
      requestApd: data.quote.coverage?.requestApd || "N",
      requestMtc: data.quote.coverage?.requestMtc || "N",
      requestNtl: data.quote.coverage?.requestNtl || "N",
      requestTgl: data.quote.coverage?.requestTgl || "N",
      generalLiability: generalLiabilityArray,
      limitAutoLiability: data.quote.limits?.limitAutoLiability,
      limitTowingStorage: data.quote.limits?.limitTowingStorage,
      mtcLimit: data.quote.limits?.mtcLimit,
      nbrOfTrailers: data?.trailers?.length || "0",
      nbrOfTrucks: data.vehicles?.length || "0",
      trailerInterchange:
        data.quote.limits?.trailerInterchange === "Y" ? true : false,
      trailerInterchangeLimit: data.quote.limits?.trailerInterchangeLimit,
      valueOfTrailers: data.quote.limits?.valueOfTrailers,
      valueOfTrucks: data.quote.limits?.valueOfTrucks,
      lossConfirmed23:
        data.quote.losses?.["1"].lossConfirmed === "Y" ? true : false,
      lossAlCount23: data.quote.losses?.["1"]?.lossAlCount,
      lossAlPaid23: data.quote.losses?.["1"]?.lossAlPaid,
      lossApdCount23: data.quote.losses?.["1"]?.lossApdCount,
      lossApdPaid23: data.quote.losses?.["1"]?.lossApdPaid,
      lossMtcCount23: data.quote.losses?.["1"]?.lossMtcCount,
      lossMtcPaid23: data.quote.losses?.["1"]?.lossMtcPaid,
      lossTglCount23: data.quote.losses?.["1"]?.lossTglCount,
      lossTglPaid23: data.quote.losses?.["1"]?.lossTglPaid,
      lossNtlCount23: data.quote.losses?.["1"]?.lossNtlCount,
      lossNtlPaid23: data.quote.losses?.["1"]?.lossNtlPaid,
      lossAlCount22: data.quote.losses?.["2"]?.lossAlCount,
      lossAlPaid22: data.quote.losses?.["2"]?.lossAlPaid,
      lossApdCount22: data.quote.losses?.["2"]?.lossApdCount,
      lossApdPaid22: data.quote.losses?.["2"]?.lossApdPaid,
      lossMtcCount22: data.quote.losses?.["2"]?.lossMtcCount,
      lossMtcPaid22: data.quote.losses?.["2"]?.lossMtcPaid,
      lossTglCount22: data.quote.losses?.["2"]?.lossTglCount,
      lossTglPaid22: data.quote.losses?.["2"]?.lossTglPaid,
      lossNtlCount22: data.quote.losses?.["2"]?.lossNtlCount,
      lossNtlPaid22: data.quote.losses?.["2"]?.lossNtlPaid,
      lossAlCount21: data.quote.losses?.["3"]?.lossAlCount,
      lossAlPaid21: data.quote.losses?.["3"]?.lossAlPaid,
      lossApdCount21: data.quote.losses?.["3"]?.lossApdCount,
      lossApdPaid21: data.quote.losses?.["3"]?.lossApdPaid,
      lossMtcCount21: data.quote.losses?.["3"]?.lossMtcCount,
      lossMtcPaid21: data.quote.losses?.["3"]?.lossMtcPaid,
      lossTglCount21: data.quote.losses?.["3"]?.lossTglCount,
      lossTglPaid21: data.quote.losses?.["3"]?.lossTglPaid,
      lossNtlCount21: data.quote.losses?.["3"]?.lossNtlCount,
      lossNtlPaid21: data.quote.losses?.["3"]?.lossNtlPaid,
      radius0_50: data.quote.radius?.radius0_50,
      radius51_200: data.quote.radius?.radius51_200,
      radius201_500: data.quote.radius?.radius201_500,
      radius501: data.quote.radius?.radius501,
      ineligibleOperations:
        data.quote.operations?.ineligibleOperations === "Y" ? true : false,
      filingsAlFederal:
        data.quote.operations?.filingsAlFederal === "Y" ? true : false,
      filingsAlState:
        data.quote.operations?.filingsAlState === "Y" ? true : false,
      priorInsuranceCancelledNonrenewed:
        data.quote.operations?.priorInsuranceCancelledNonrenewed === "Y"
          ? true
          : false,
      operationDescription: operationDescriptionArray,
      commodityArray: data.quote.commodities,
      commoditiesRefrigeration:
        data.quote.commoditiesRefrigeration === "Y" ? true : false,
      trailers: data.trailers || [],
      vehicles: data.vehicles || [],
      drivers: data.drivers || [],
      producer: data.quote?.retailAgent,
    };
    navigate(`/prospects/runquote`, {
      state: { data: { myQuote: quoteData } },
    });
  };

  return (
    <Fragment>
      <CompanyNav />

      <Animate type="pop">
        <TabView name="Quotes" labels={["Quotes", "Saved Quotes"]}>
          <Card className="pb-14">
            <Table
              data={quotes}
              loading={quotesData.loading}
              show={[
                "submission_number",
                "premium",
                "status",
                "created_at",
                "_drivers",
                "_trailers",
                "_vehicles",
              ]}
              money="premium"
              badge={{
                col: "status",
                color: "green",
                condition: [
                  { value: "Declined", color: "red" },
                  { value: "Canceled", color: "red" },
                  { value: "No Status", color: "orange" },
                ],
              }}
              paginate
              paginateLimit="5"
            ></Table>
          </Card>
          <Card className="pb-14">
            <Table
              data={savedQuotes}
              loading={savedQuotesData.loading}
              show={["id", "_drivers", "_vehicles", "_trailers", "last_saved"]}
              actions={{
                edit: editQuote,
                custom: [
                  {
                    icon: "eye",
                    action: summaryQuote,
                  },
                ],
              }}
              paginate
              paginateLimit="5"
            ></Table>
          </Card>
        </TabView>
      </Animate>
    </Fragment>
  );
}
