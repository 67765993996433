import { Text, View } from "@react-pdf/renderer";

function SixthRow({ physical, letter }) {
  function formatDate(inputDate) {
    if (!inputDate) {
      return "";
    }
    const date = new Date(inputDate);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
  }

  return (
    <View style={{ flexDirection: "row", height: "40px" }}>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>{letter}</Text>
      </View>
      <View
        style={{
          width: "25%",
          borderRight: "1px solid black",
        }}
      >
        {physical && (
          <Text style={{ padding: "8px", fontSize: "8px" }}>
            Automobile Physical Damage
          </Text>
        )}
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      ></View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      ></View>
      <View
        style={{
          width: "18%",
          borderRight: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>
          {physical && physical.policy_number}
        </Text>
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>
          {physical && formatDate(physical.policy_effective_date)}
        </Text>
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>
          {physical && formatDate(physical.updated_policy_expiration_date)}
        </Text>
      </View>
      <View style={{ width: "29%" }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
            fontFamily: "Helvetica"
          }}
        >
          <View
            style={{
              flex: "1",
              borderRight: "1px solid black",
              height: "100%",
              fontSize: "5px"
            }}
          >
            <Text
              style={{
                paddingLeft: "2px",
                paddingTop: "6px",
                height: "50%"
              }}
            >
              LIMIT
            </Text>
            <Text
              style={{
                paddingLeft: "2px",
                paddingTop: "4px"
              }}
            >
              Premium
            </Text>
          </View>
          <View style={{ flex: "1", fontSize: "6px" }}>
            <View
              style={{
                flexDirection: "row",
                paddingTop: "4px",
                height: "50%"
              }}
            >
              {physical && (
                <>
                  <Text
                    style={{ paddingHorizontal: "2px", textAlign: "center" }}
                  >
                    $ {physical.limit}
                  </Text>
                </>
              )}
            </View>
            <View style={{ flexDirection: "row", paddingTop: "4px" }}>
              {physical && (
                <>
                  <Text
                    style={{ paddingHorizontal: "2px", textAlign: "center" }}
                  >
                    $ {physical.premium}
                  </Text>
                </>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
export default SixthRow;
