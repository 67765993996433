import { useContext } from 'react';
import { ViewContext, Form } from 'components/lib';
import Axios from 'axios';

const RequestForm = (props) => {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem('user'));

  const date = new Date(props.data.date_requested).toISOString().split('T')[0];

  const data = {
    dotNumber: {
      type: 'hidden',
      value: props.data.dotNumber,
    },
    requestId: {
      type: 'hidden',
      value: props.data.requestId,
    },
    user_id: {
      type: 'hidden',
      value: user.accounts[0].user_id
    },
    userName: {
      type: 'hidden',
      value: user.name
    },
    editedField: {
      type: 'hidden',
      value: `Approved ${props.data.description}`
    },
    vehicleId: {
      type: 'hidden',
      value: props.data.id
    },
    driverId: {
      type: 'hidden',
      value: props.data.id
    },
    documentId: {
      type: 'hidden',
      value: props.data.id
    }
  };

  let firstEndpoint = '';
  let secondEndpoint = 'add';
  let metod = 'POST';

  if (props.data.alteration === 'vehicles') {
    firstEndpoint = 'vehicle';
  };

  if (props.data.alteration === 'drivers') {
    firstEndpoint = 'driver';
  };

  if (props.data.alteration === 'documents') {
    firstEndpoint = 'document';
  };

  if (props.data.alteration === 'company') {
    firstEndpoint = 'company';
  };

  if (props.data.description.includes('Edited')) {
    secondEndpoint = 'edit';
    metod = 'PUT';
  };

  if (props.data.description.includes('Archived')) {
    secondEndpoint = 'archive';
    metod = 'DELETE';
  };

  const postURL = `/api/${firstEndpoint}/${secondEndpoint}`;

  const denyRequest = async () => {

    let res = await Axios({

      method: 'DELETE',
      url: '/api/company/reject',
      data: {
        dotNumber: data.dotNumber.value,
        requestId: data.requestId.value,
        user_id: data.user_id.value,
        userName: data.userName.value,
        editedField: data.editedField.value
      }

    });

    if (res.data.editedHistory) {
      props.setRequestsData(props.requestsData.filter(item => item.requestId !== data.requestId.value));
    }
    context.modal.hide(true);
  };

  return (
    <>
      <div className='flex flex-wrap gap-8 w-full'>
        <div className='text-lg'>
          <h3 className='font-bold'>Company Name</h3>
          <p>{props.data.legalName}</p>
        </div>

        <div className='text-lg'>
          <h3 className='font-bold'>Date Requested</h3>
          <p>{date}</p>
        </div>

        <div className='text-lg'>
          <h3 className='font-bold'>Alteration</h3>
          <p>{props.data.alteration}</p>
        </div>

        <div className='text-lg'>
          <h3 className='font-bold'>Description</h3>
          <p>{props.data.description}</p>
        </div>
      </div>
      <Form
        method={metod}
        url={postURL}
        data={ data }
        buttonText='Approve'
        cancelText='Deny'
        buttonClass='flex justify-end w-full mt-4'
        className='flex flex-wrap justify-between'
        cancel={ denyRequest }
      />
    </>
  )

};

export default RequestForm;
