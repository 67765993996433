import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  box: {
    height: 13,
    width: 13,
    border: "1 solid grey",
    justifyContent: "center",
    alignItems: "center",
  },
  cross: {
    color: "black",
    fontSize: 10,
  },
});

function Box({showCross}) {
  if (showCross) {
    return (
      <View style={styles.box}>
        <Text style={styles.cross}>X</Text>
      </View>
    );
  } else {
    return <View style={styles.box} />;
  }
}

export default Box;
