/***
 *
 *   Vehicles Page
 *
 **********/

import { React, useContext, useState, Fragment, useEffect } from "react";
import {
  Card,
  Table,
  Animate,
  TitleRow,
  Button,
  ViewContext,
  TabView,
} from "components/lib";
import { CompanyNav } from "components/nav/company/companyNav";
import axios from "axios";

export function Vehicles(props) {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [vehicles, setVehicles] = useState([]);
  const [archiveVehicles, setArchiveVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const company = JSON.parse(localStorage.getItem("company"));
      const res = await axios.get("/api/vehicle/get", {
        params: {
          dotNumber: company["dot_#"],
        },
      });
  
      const newArray = res.data.vehicles.map((object) => {
        const { vin: value, ...restofObject } = object;
  
        const objectRename = { VIN: value, ...restofObject };
  
        return objectRename;
      });
      setVehicles(newArray);
  
      const resArchive = await axios.get(
        `/api/company/archives?dotNumber=${company["dot_#"]}`
      );
  
      const newArchiveArray = resArchive.data?.Archives?.vehicles.map((object) => {
        const { vin: value, ...restofObject } = object;
  
        const objectRename = { VIN: value, ...restofObject };
  
        return objectRename;
      });
      setArchiveVehicles(newArchiveArray);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const addVehicle = () => {
    context.modal.show(
      {
        title: "Add Vehicle",
        addVehicle: true,
      },
      (form, res) => {
        if (res.Vehicle) {
          context.notification.show("Vehicle created", "success", true);
          const vehiclesArray = res.Vehicle.vehicles.map((object) => {
            const { vin: value, ...restofObjet } = { ...object };

            const objetRename = { VIN: value, ...restofObjet };

            return objetRename;
          });
          setVehicles(vehiclesArray);
        }

        if (res.requestVehicle) {
          context.notification.show(
            "Vehicle waiting for approval",
            "success",
            true
          );
          res.requestVehicle.vehicles[0].status = "Pending";
          const { vin: value, ...restofObjet } = res.requestVehicle.vehicles[0];

          const objetRename = { VIN: value, ...restofObjet };

          const vehiclesData = [objetRename, ...vehicles];
          setVehicles(vehiclesData);
        }
      }
    );
  };

  const editVehicle = (data, callback) => {
    context.modal.show(
      {
        vehiclesForm: {
          data: data,
        },
      },
      (form, res) => {
        if (res.editedVehicle) {
          context.notification.show("Vehicle edited", "success", true);
          const vehiclesArray = res.editedVehicle.vehicles.map((object) => {
            const { vin: value, ...restofObjet } = { ...object };

            const objetRename = { VIN: value, ...restofObjet };

            return objetRename;
          });
          setVehicles(vehiclesArray);
        }

        if (res.requestVehicle) {
          const { vin: value, ...restofObjet } = res.requestVehicle.vehicles[0];

          const objetRename = { VIN: value, ...restofObjet };
          objetRename.status = "Pending";

          const vehiclesCopyArray = [...vehicles];

          const index = vehiclesCopyArray.findIndex(function (object) {
            return object.VIN === objetRename.VIN;
          });

          if (index !== -1) {
            vehiclesCopyArray[index] = objetRename;
          }
          setVehicles(vehiclesCopyArray);
          context.notification.show(
            "Change awaiting approval",
            "success",
            true
          );
        }
      }
    );
  };

  const archiveVehicle = (data) => {
    const archiveVehicleURL = [
      "master",
      "owner",
      "admin",
      "sales",
      "service",
    ].includes(user.permission)
      ? "/api/vehicle/salesArchive"
      : "/api/vehicle/requestArchive";

    context.modal.show(
      {
        title: "Archive Vehicle",
        text: `Are you sure you want to archive Vehicle "${data.VIN}" ?`,
        form: {
          userName: {
            type: "hidden",
            value: user.name,
          },
          client: {
            type: "hidden",
            value: "customer",
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          editedField: {
            type: "hidden",
            value: `Vehicle "${data.VIN}" Archived`,
          },
          vin: {
            type: "hidden",
            value: data.VIN,
          },
          dotNumber: {
            type: "hidden",
            value: companyData["dot_#"],
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Archive",
        buttonClass: "flex justify-end w-full",
        url: archiveVehicleURL,
        method: "DELETE",
      },
      (form, res) => {
        if (res.archivedVehicle) {
          if (archiveVehicleURL === "/api/vehicle/salesArchive") {
            let vehiclesList = [...vehicles];
            setVehicles(vehiclesList.filter((item) => item.VIN !== data.VIN));
            context.notification.show("Vehicle Archived", "success", true);

            delete data.actions;

            const archiveList =
              archiveVehicles?.length > 0 ? [data, ...archiveVehicles] : [data];

            setArchiveVehicles(archiveList);
            return;
          }
          context.notification.show(
            "Archive vehicle requested to Admin",
            "success",
            true
          );
        }
      }
    );
  };

  const restoreVehicle = (data) => {
    context.modal.show(
      {
        title: "Restore Vehicle",
        text: `Are you sure you want to restore vehicle "${data.VIN}" ?`,
        form: {
          vin: {
            type: "hidden",
            value: data.VIN,
          },
          dotNumber: {
            type: "hidden",
            value: companyData["dot_#"],
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          userName: {
            type: "hidden",
            value: user.name,
          },
          editedField: {
            type: "hidden",
            value: `Restored vehicle "${data.VIN}"`,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Restore",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/vehicle/restore",
        method: "POST",
      },
      (form, res) => {
        if (res.restoredVehicle) {
          delete data.actions;

          let vehiclesList = [data, ...vehicles];
          setVehicles(vehiclesList);

          const archiveList = [...archiveVehicles];
          setArchiveVehicles(
            archiveList.filter((item) => item.VIN !== data.VIN)
          );

          context.notification.show("Vehicle Restored", "success", true);
        }
      }
    );
  };

  return (
    <Fragment>
      <CompanyNav />

      <Animate type="pop">
        <TitleRow>
          <Button
            small
            text="Add Vehicle"
            icon="plus-circle"
            className="bg-blue-700 text-white rounded mr-4"
            action={addVehicle}
          />
        </TitleRow>

        <TabView
          name="Vehicles"
          labels={["Active Vehicles", "Archived Vehicles"]}
        >
          <Card>
            <Table
              data={vehicles}
              loading={loading}
              show={["year", "make", "model", "VIN", "value", "status"]}
              actions={{
                edit: editVehicle,
                custom: [
                  {
                    col: "archive",
                    value: "archive",
                    icon: "archive",
                    action: archiveVehicle,
                  },
                ],
              }}
              badge={{
                col: "status",
                color: "orange",
                condition: [
                  { value: "inactive", color: "red" },
                  { value: "active", color: "green" },
                ],
              }}
              paginate
              paginateLimit="6"
              money="value"
            ></Table>
          </Card>

          <Card>
            <Table
              data={archiveVehicles}
              loading={loading}
              show={["year", "make", "model", "VIN", "value"]}
              actions={{
                custom: [
                  {
                    col: "restore",
                    value: "restore",
                    icon: "rotate-ccw",
                    action: restoreVehicle,
                  },
                ],
              }}
              paginate
              paginateLimit="6"
              money="value"
            ></Table>
          </Card>
        </TabView>
      </Animate>
    </Fragment>
  );
}
