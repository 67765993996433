import { Text, View } from "@react-pdf/renderer";

function FifthRow({ motor, letter }) {
  function formatDate(inputDate) {
    if (!inputDate) {
      return "";
    }
    const date = new Date(inputDate);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
  }

  return (
    <View style={{ flexDirection: "row", height: "40px" }}>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>{letter}</Text>
      </View>
      <View
        style={{
          width: "25%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        {motor && (
          <Text style={{ padding: "8px", fontSize: "12px" }}>
            Motor Truck Cargo
          </Text>
        )}
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      ></View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      ></View>
      <View
        style={{
          width: "18%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>
          {motor && motor.policy_number}
        </Text>
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>
          {motor && formatDate(motor.policy_effective_date)}
        </Text>
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>
          {motor && formatDate(motor.updated_policy_expiration_date)}
        </Text>
      </View>
      <View style={{ width: "29%", borderBottom: "1px solid black" }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
            fontFamily: "Helvetica"
          }}
        >
          <View
            style={{
              flex: "1",
              borderRight: "1px solid black",
              height: "100%",
              fontSize: "5px"
            }}
          >
            <Text
              style={{
                borderBottom: "1px solid black",
                paddingLeft: "2px",
                paddingTop: "4px"
              }}
            >
              LIMIT
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                paddingLeft: "2px",
                paddingTop: "4px"
              }}
            >
              Deductible
            </Text>
            <Text
              style={{
                paddingLeft: "2px",
                paddingTop: "4px"
              }}
            >
              Premium
            </Text>
          </View>
          <View style={{ flex: "1", fontSize: "6px" }}>
            {motor && (
              <>
                <View
                  style={{
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                    paddingTop: "4px"
                  }}
                >
                  <Text
                    style={{ paddingHorizontal: "2px", textAlign: "center" }}
                  >
                    $ {motor.limit}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                    paddingTop: "4px"
                  }}
                >
                  <Text
                    style={{ paddingHorizontal: "2px", textAlign: "center" }}
                  >
                    $ {motor.deductible}
                  </Text>
                </View>
                <View style={{ flexDirection: "row", paddingTop: "4px" }}>
                  <Text
                    style={{ paddingHorizontal: "2px", textAlign: "center" }}
                  >
                    $ {motor.premium}
                  </Text>
                </View>
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}
export default FifthRow;
