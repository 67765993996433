import { useContext, useState } from "react";
import {
  ViewContext,
  TextInput,
  Button,
} from "components/lib";
import { pdf } from "@react-pdf/renderer";
import Certificate from "components/certificatePDF/certificate";
import axios from "axios";

const RefreshCertificateModal = ({ data }) => {
  const context = useContext(ViewContext);
  const company = JSON.parse(localStorage.getItem("company"));
  const [loading, setLoading] = useState(false);

  const formatDate = (inputDate) => {
    try {
      const [year, month, day] = inputDate.split("-");
      return `${month}/${day}/${year}`;
    } catch (error) {
      return "";
    }
  };

  const updatePolicyExpirationDates = (object) => {
    const updatedCoverages = { ...object };

    for (const coverageKey in updatedCoverages.coverages) {
      if (
        Object.prototype.hasOwnProperty.call(
          updatedCoverages.coverages,
          coverageKey
        )
      ) {
        const coverage = updatedCoverages.coverages[coverageKey];
        if (coverage.hasOwnProperty("policy_expiration_date")) {
          const formatCoverageDate = formatDate(
            coverage.policy_expiration_date
          );

          coverage.policy_expiration_date = formatCoverageDate;
          coverage.updated_policy_expiration_date = formatCoverageDate;
        }
      }
    }
    return updatedCoverages;
  };

  const getDataFromSubmission = async (numbers) => {
    try {
      const submissionNumbers = numbers.split(",");
      const responses = [];
      for (let i = 0; i < submissionNumbers.length; i++) {
        const policyUrl = `/api/policy/${submissionNumbers[i]}`;
        let res = await axios.get(policyUrl);

        if (res?.data?.Policy?.data) {
          responses.push(res.data.Policy.data);
        }
      }

      const newObject = responses[0];

      for (let i = 1; i < responses.length; i++) {
        const currentObject = responses[i];

        for (const coverageKey in currentObject.coverages) {
          if (!(coverageKey in newObject.coverages)) {
            newObject.coverages[coverageKey] =
              currentObject.coverages[coverageKey];
          }
        }
      }

      return updatePolicyExpirationDates(newObject);
    } catch (err) {
      console.log(err);
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      const pdfData = await getDataFromSubmission(data.submission_number);
      const pdfBlob = await pdf(
        <Certificate
          data={pdfData}
          customerHolder={data.certificate_holder}
          remark={data.additional_remarks}
        />
      ).toBlob();
      const formData = new FormData();
      formData.append("document", pdfBlob, `${company.company_name}.pdf`);
      formData.append("submission_number", data.submission_number);
      formData.append("certificate_holder", data.certificate_holder);
      formData.append("additional_remarks", data.additional_remarks);
      formData.append("email", data.allEmails ?? "");
      const response = await axios.post(`/api/certificate/save`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!response?.data?.Quote?.pdf_url) {
        context.notification.show("Certificate Not Updated", "error", true);
        console.log(response);
        return;
      }
      context.notification.show("Certificate Updated", "success", true);
      context.modal.hide(false, response?.data?.Quote);
    } catch (error) {
      console.log(error);
      context.notification.show("Certificate Not Updated", "error", true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={submit}
      noValidate
      className="flex flex-wrap gap-2 justify-between"
    >
      <TextInput
        name="submission_number"
        id="submission_number"
        label="Submission Number"
        className="w-full"
        containerClass="w-full"
        value={data.submission_number}
        readonly
      />
      <TextInput
        name="customerHolder"
        id="customerHolder"
        type="textarea"
        label="Certificate Holder"
        className="w-full"
        containerClass="w-[48%]"
        value={data.certificate_holder}
        readonly
      />
      <TextInput
        name="aditionalRemarks"
        id="aditionalRemarks"
        type="textarea"
        label="Additional Remarks"
        className="w-full"
        containerClass="w-[48%]"
        value={data.additional_remarks}
        readonly
      />

      <div className="flex justify-end w-full mt-2">
        <Button
          color="red"
          outline
          className="mr-[2%]"
          text="Cancel"
          action={(e) => context.modal.hide(true)}
        />

        <Button
          color="bluesign"
          loading={loading}
          text="Refresh PDF"
          action={submit}
        />
      </div>
    </form>
  );
};

export default RefreshCertificateModal;