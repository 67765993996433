/***
 *
 *   Summary page
 *
 **********/

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Icon } from "components/lib";

const progressive = false;

export function Summary(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const company = JSON.parse(localStorage.getItem("company"));
  const [data, setData] = useState({
    commodityArray: [],
    vehicles: [],
    trailers: [],
    drivers: [],
  });

  const myQuote = location.state?.data;

  useEffect(() => {
    if (
      typeof myQuote === "object" &&
      myQuote !== null &&
      !Array.isArray(myQuote)
    ) {
      const generalLiabilityArray = [];
      myQuote.quote.coverage?.requestApd === "Y" &&
        generalLiabilityArray.push("Automobile Physical Damage (APD)");
      myQuote.quote.coverage?.requestMtc === "Y" &&
        generalLiabilityArray.push("Motor Truck Cargo (MTC)");
      myQuote.quote.coverage?.requestNtl === "Y" &&
        generalLiabilityArray.push("Truckers General Liability (TGL)");
      myQuote.quote.coverage?.requestTgl === "Y" &&
        generalLiabilityArray.push("Non-Trucking Liability (NTL)");

      const operationDescriptionArray = [];
      myQuote.quote.operations?.opsLocal === "Y" &&
        operationDescriptionArray.push("Local trucker");
      myQuote.quote.operations?.opsIntermediate === "Y" &&
        operationDescriptionArray.push("Intermediate");
      myQuote.quote.operations?.opsLongHaul === "Y" &&
        operationDescriptionArray.push("Long haul trucker");
      myQuote.quote.operations?.opsIntermodalPort === "Y" &&
        operationDescriptionArray.push("Intermodal or port");
      myQuote.quote.operations?.opsHouseholdGoods === "Y" &&
        operationDescriptionArray.push("Household goods mover");
      myQuote.quote.operations?.opsTanker === "Y" &&
        operationDescriptionArray.push("Tankers");
      myQuote.quote.operations?.UIIAintermodal === "Y" &&
        operationDescriptionArray.push("UIIA - Intermodal");
      myQuote.quote.operations?.opsDumpTruckOther === "Y" &&
        operationDescriptionArray.push("Dump trucks (Other)");
      myQuote.quote.operations?.opsDumpTruckSandGravel === "Y" &&
        operationDescriptionArray.push("Dump trucks (sand and gravel)");
      myQuote.quote.operations?.opsEndDumper === "Y" &&
        operationDescriptionArray.push("End dumper units");
      myQuote.quote.operations?.opsLogging === "Y" &&
        operationDescriptionArray.push("Logging");
      myQuote.quote.operations?.opsRefrigirated === "Y" &&
        operationDescriptionArray.push("refrigerated trailer");
      myQuote.quote.operations?.opsOversizedOvernight === "Y" &&
        operationDescriptionArray.push("Oversized or overweight");
      myQuote.quote.operations?.opsAutomobileHauler === "Y" &&
        operationDescriptionArray.push(
          "Automobile hauler [Excluded for cargo and NTL]"
        );
      myQuote.quote.operations?.opsHotShot === "Y" &&
        operationDescriptionArray.push(
          "Hot shot (TGL and NTL not available for  class 3 vehicles)"
        );

      setData({
        _id: myQuote._id,
        ownerName: myQuote.quote.insuredInformation?.ownerName,
        dbaName: myQuote.quote.insuredInformation?.dbaName,
        dotNumber: myQuote.dotNumber,
        emailAddress: myQuote.quote.insuredInformation?.email,
        legalName: myQuote.quote.insuredInformation?.legalName,
        phone: myQuote.quote.insuredInformation?.insuranceContactPhone,
        yearsInBusiness: myQuote.quote.insuredInformation?.yearsInBusiness,
        monthsInBusiness: myQuote.quote.insuredInformation?.monthsInBusiness,
        insuranceContactFirstName:
          myQuote.quote.insuredInformation?.insuranceContactFirstName,
        insuranceContactLastName:
          myQuote.quote.insuredInformation?.insuranceContactLastName,
        insuranceContactEmail:
          myQuote.quote.insuredInformation?.insuranceContactEmail,
        insuranceContactPhone:
          myQuote.quote.insuredInformation?.insuranceContactPhone,
        mailingStreet: myQuote.quote.mailingAddress?.mailingStreet,
        mailingStreet2: myQuote.quote.mailingAddress?.mailingStreet2,
        mailingCity: myQuote.quote.mailingAddress?.mailingCity,
        mailingState: myQuote.quote.mailingAddress?.mailingState,
        mailingZip: myQuote.quote.mailingAddress?.mailingZip,
        mailingCountry: myQuote.quote.mailingAddress?.mailingCountry,
        mailingCounty: myQuote.quote.mailingAddress?.mailingCounty,
        garageStreet: myQuote.quote.garageAddress?.garageStreet,
        garageCity: myQuote.quote.garageAddress?.garageCity,
        garageState: myQuote.quote.garageAddress?.garageState,
        garageZip: myQuote.quote.garageAddress?.garageZip,
        garageCountry: myQuote.quote.garageAddress?.garageCountry,
        garageCounty: myQuote.quote.garageAddress?.garageCounty,
        effectiveDate: myQuote.quote.coverage?.effectiveDate,
        optAlPip: myQuote.quote.coverage?.optAlPip || "N",
        optAlUm: myQuote.quote.coverage?.optAlUm || "N",
        requestAl: myQuote.quote.coverage?.requestAl || "N",
        requestApd: myQuote.quote.coverage?.requestApd || "N",
        requestMtc: myQuote.quote.coverage?.requestMtc || "N",
        requestNtl: myQuote.quote.coverage?.requestNtl || "N",
        requestTgl: myQuote.quote.coverage?.requestTgl || "N",
        generalLiability: generalLiabilityArray,
        limitAutoLiability: myQuote.quote.limits?.limitAutoLiability,
        limitTowingStorage: myQuote.quote.limits?.limitTowingStorage,
        mtcLimit: myQuote.quote.limits?.mtcLimit,
        nbrOfTrailers: myQuote?.trailers?.length || "0",
        nbrOfTrucks: myQuote.vehicles?.length || "0",
        trailerInterchange:
          myQuote.quote.limits?.trailerInterchange === "Y" ? true : false,
        trailerInterchangeLimit: myQuote.quote.limits?.trailerInterchangeLimit,
        valueOfTrailers: myQuote.quote.limits?.valueOfTrailers,
        valueOfTrucks: myQuote.quote.limits?.valueOfTrucks,
        lossConfirmed23:
          myQuote.quote.losses?.["1"].lossConfirmed === "Y" ? true : false,
        lossAlCount23: myQuote.quote.losses?.["1"]?.lossAlCount,
        lossAlPaid23: myQuote.quote.losses?.["1"]?.lossAlPaid,
        lossApdCount23: myQuote.quote.losses?.["1"]?.lossApdCount,
        lossApdPaid23: myQuote.quote.losses?.["1"]?.lossApdPaid,
        lossMtcCount23: myQuote.quote.losses?.["1"]?.lossMtcCount,
        lossMtcPaid23: myQuote.quote.losses?.["1"]?.lossMtcPaid,
        lossTglCount23: myQuote.quote.losses?.["1"]?.lossTglCount,
        lossTglPaid23: myQuote.quote.losses?.["1"]?.lossTglPaid,
        lossNtlCount23: myQuote.quote.losses?.["1"]?.lossNtlCount,
        lossNtlPaid23: myQuote.quote.losses?.["1"]?.lossNtlPaid,
        lossAlCount22: myQuote.quote.losses?.["2"]?.lossAlCount,
        lossAlPaid22: myQuote.quote.losses?.["2"]?.lossAlPaid,
        lossApdCount22: myQuote.quote.losses?.["2"]?.lossApdCount,
        lossApdPaid22: myQuote.quote.losses?.["2"]?.lossApdPaid,
        lossMtcCount22: myQuote.quote.losses?.["2"]?.lossMtcCount,
        lossMtcPaid22: myQuote.quote.losses?.["2"]?.lossMtcPaid,
        lossTglCount22: myQuote.quote.losses?.["2"]?.lossTglCount,
        lossTglPaid22: myQuote.quote.losses?.["2"]?.lossTglPaid,
        lossNtlCount22: myQuote.quote.losses?.["2"]?.lossNtlCount,
        lossNtlPaid22: myQuote.quote.losses?.["2"]?.lossNtlPaid,
        lossAlCount21: myQuote.quote.losses?.["3"]?.lossAlCount,
        lossAlPaid21: myQuote.quote.losses?.["3"]?.lossAlPaid,
        lossApdCount21: myQuote.quote.losses?.["3"]?.lossApdCount,
        lossApdPaid21: myQuote.quote.losses?.["3"]?.lossApdPaid,
        lossMtcCount21: myQuote.quote.losses?.["3"]?.lossMtcCount,
        lossMtcPaid21: myQuote.quote.losses?.["3"]?.lossMtcPaid,
        lossTglCount21: myQuote.quote.losses?.["3"]?.lossTglCount,
        lossTglPaid21: myQuote.quote.losses?.["3"]?.lossTglPaid,
        lossNtlCount21: myQuote.quote.losses?.["3"]?.lossNtlCount,
        lossNtlPaid21: myQuote.quote.losses?.["3"]?.lossNtlPaid,
        radius0_50: myQuote.quote.radius?.radius0_50,
        radius51_200: myQuote.quote.radius?.radius51_200,
        radius201_500: myQuote.quote.radius?.radius201_500,
        radius501: myQuote.quote.radius?.radius501,
        ineligibleOperations:
          myQuote.quote.operations?.ineligibleOperations === "Y" ? true : false,
        filingsAlFederal:
          myQuote.quote.operations?.filingsAlFederal === "Y" ? true : false,
        filingsAlState:
          myQuote.quote.operations?.filingsAlState === "Y" ? true : false,
        priorInsuranceCancelledNonrenewed:
          myQuote.quote.operations?.priorInsuranceCancelledNonrenewed === "Y"
            ? true
            : false,
        operationDescription: operationDescriptionArray,
        commodityArray: myQuote.quote.commodities,
        commoditiesRefrigeration:
          myQuote.quote.commoditiesRefrigeration === "Y" ? true : false,
        trailers: myQuote.trailers || [],
        vehicles: myQuote.vehicles || [],
        drivers: myQuote.drivers || [],
        producer: myQuote.quote?.retailAgent,
      });
    }
  }, [myQuote]);

  return (
    <Card>
      <Coverages data={data} />
      <Insured data={data} />
      <Limits data={data} />
      <Operations data={data} />
      <Radius data={data} />
      <Cargo data={data} />
      <Vehicles data={data} />
      <Trailers data={data} />
      <Drivers data={data} />
      <Losses data={data} />
      <Button
        text="Edit"
        color="bluesign"
        className="w-full mt-4"
        action={() =>
          navigate(
            `/${
              company.type === "customer" ? "customers" : "prospects"
            }/runquote`,
            { state: { data: { myQuote: data } } }
          )
        }
      />
    </Card>
  );
}

function Coverages(props) {
  const data = props.data;

  const formatDate = (originalDate) => {
    const date = new Date(originalDate);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  return (
    <Card className="mb-4 border border-slate-300" id="1">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300">
        <h2 className="capitalize text-xl font-semibold pt-4">
          Coverages (Select coverages to quote)
        </h2>
      </header>
      <div className="font-bold flex flex-col gap-2 mb-2">
        <div className="flex items-center gap-2">
          <Icon
            image={data.requestAl === "Y" ? "check" : "x"}
            color={data.requestAl === "Y" ? "green" : "red"}
            size={20}
          />
          <p>Auto Liability (AL)</p>
        </div>
        <div className="flex items-center gap-2">
          <Icon
            image={data.optAlPip === "Y" ? "check" : "x"}
            color={data.optAlPip === "Y" ? "green" : "red"}
            size={20}
          />
          <p>Personal Injury Protection (PIP)</p>
        </div>
        <div className="flex items-center gap-2">
          <Icon
            image={data.optAlUm === "Y" ? "check" : "x"}
            color={data.optAlUm === "Y" ? "green" : "red"}
            size={20}
          />
          <p>Uninsured Motorists/Property Damage</p>
        </div>
        <div className="flex items-center gap-2">
          <Icon
            image={data.requestApd === "Y" ? "check" : "x"}
            color={data.requestApd === "Y" ? "green" : "red"}
            size={20}
          />
          <p>Automobile Physical Damage (APD)</p>
        </div>
        <div className="flex items-center gap-2">
          <Icon
            image={data.requestMtc === "Y" ? "check" : "x"}
            color={data.requestMtc === "Y" ? "green" : "red"}
            size={20}
          />
          <p>Motor Truck Cargo (MTC)</p>
        </div>
        <div className="flex items-center gap-2">
          <Icon
            image={data.requestTgl === "Y" ? "check" : "x"}
            color={data.requestTgl === "Y" ? "green" : "red"}
            size={20}
          />
          <p>Truckers General Liability (TGL)</p>
        </div>
        <div className="flex items-center gap-2">
          <Icon
            image={data.requestNtl === "Y" ? "check" : "x"}
            color={data.requestNtl === "Y" ? "green" : "red"}
            size={20}
          />
          <p>Non-Trucking Liability (NTL)</p>
        </div>
        <p className="underline text-xl">
          Policy Effective Date: {formatDate(data.effectiveDate)}
        </p>
      </div>
      {progressive && (
        <>
          <p className="m-0">
            <strong>
              Is the customer currently insured with Progressive Commercial
              Auto? (Progressive Only)
            </strong>
          </p>
          <div className="border rounded p-2 mt-2">
            <p className="text-xl">
              <strong>Coverages applied to all vehicles</strong>
            </p>
            <div className="flex flex-wrap justify-between w-full gap-4 mt-4">
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Bodily injury and property damage liability (Progressive
                    Only)
                  </strong>
                </p>
                <p>{data.bodilyInjuryDamage}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Uninsured Motorist property damage (Progressive Only)
                  </strong>
                </p>
                <p>{data.uninsuredMotoristDamage}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Uninsured/underinsured motorist bodily injury (Progressive
                    Only)
                  </strong>
                </p>
                <p>{data.underinsuredMotoristBodilyInjury}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Personal injury protection (Progressive Only)</strong>
                </p>
                <p>{data.personalInjuryProtection}</p>
              </div>
            </div>
          </div>
          <div className="border rounded p-4 mt-2">
            <p className="text-xl">
              <strong>Coverages for the vehicles</strong>
            </p>
            <div className="flex flex-wrap justify-between w-full gap-4 mt-4">
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Comprehensive (Progressive Only)</strong>
                </p>
                <p>{data.comprehensive}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Collision (Progressive Only)</strong>
                </p>
                <p>{data.collision}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Medical payments (Progressive Only)</strong>
                </p>
                <p>{data.medicalPayments}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Rental reimbursement (Progressive Only)</strong>
                </p>
                <p>{data.rentalReimbursement}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Rental with downtime (Progressive Only)</strong>
                </p>
                <p>{data.rentalWithDowntime}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Roadside Assistance (Progressive Only)</strong>
                </p>
                <p>{data.roadsideAssistance}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Fire & Theft w/ combined additional coverage (Progressive
                    Only)
                  </strong>
                </p>
                <p>{data.fireTheftCoverage}</p>
              </div>
            </div>
          </div>
          <div className="border rounded p-4 mt-2">
            <p className="text-xl">
              <strong>
                Special coverages related to the customer's business
              </strong>
            </p>
            <p className="my-2">
              How much did the customer spend in renting, hiring, or borrowing
              vehicles last year, including the cost to hire drivers not listed
              on the policy? (Progressive Only)
            </p>
            <div className="flex items-center gap-2">
              <Icon image="check" color="green" size={20} />
              <p>"$5,000 or Less", "More than $5,000"</p>
              <p>{data.lastYearCustomerSpend}</p>
            </div>
            <div className="flex items-center gap-2">
              <Icon image="check" color="green" size={20} />
              <p>
                Are non-owned vehicles which are not listed on the policy used
                in the business? (Progressive Only)
              </p>
              <p>{data.nonOwnedNotListedVehiclesBusiness}</p>
            </div>
            <div className="flex items-center gap-2">
              <Icon image="x" color="red" size={20} />
              <p>
                Does the customer have an interchange agreement requiring
                trailer interchange coverage? (Progressive Only)
              </p>
              <p>{data.interchangeAgreementRequiringCoverage}</p>
            </div>
            <div className="flex items-center gap-2">
              <Icon image="check" color="green" size={20} />
              <p>
                Does the customer agree to furnish a copy of the interchange
                agreement upon request, including in the event of a claim?
                (Progressive Only)
              </p>
              <p>{data.furnishCopyTheInterchangeAgreement}</p>
            </div>
            <div className="flex flex-wrap mt-2 gap-2">
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Number of non-owned trailers (Progressive Only): 0
                  </strong>
                </p>
                <p>{data.nonOwnedTrailers}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>
                    Type of non-owned trailers (Progressive Only): 0
                  </strong>
                </p>
                <p>{data.nonOwnedTrailersType}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>What is the length of this trailer?: 0</strong>
                </p>
                <p>{data.nonOwnedTrailers}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">
                  <strong>Trailer interchange coverage limit: 0</strong>
                </p>
                <p>{data.nonOwnedTrailersType}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  );
}

function Insured(props) {
  const data = props.data;

  return (
    <Card className="mb-4 border border-slate-300" id="2">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Insured</h2>
      </header>
      {progressive && (
        <div className="flex items-center gap-2 mb-4">
          <Icon image="check" color="green" size={20} />
          <p>
            {/* The customer will not have a DOT number */}
            The customer has applied /will apply for a DOT number (Progressive
            Only)
          </p>
        </div>
      )}
      <div className="flex justify-between mb-8">
        <div className="w-[22%]">
          <p>
            <strong>DOT Number</strong>
          </p>
          <p>{data.dotNumber}</p>
        </div>
        <div className="w-[22%]">
          <p>
            <strong>Insured Company Name</strong>
          </p>
          <p>{data.legalName}</p>
        </div>
        <div className="w-[22%]">
          <p>
            <strong>Doing business as “DBA”</strong>
          </p>
          <p>{data.dbaName}</p>
        </div>
        <div className="w-[22%]">
          <p>
            <strong>Owner’s Name</strong>
          </p>
          <p>{data.ownerName}</p>
        </div>
      </div>
      <div className="border rounded p-4 mt-2 mb-8">
        <p className="text-xl">
          <strong>Mailing address</strong>
        </p>
        <div className="flex justify-between w-full flex-wrap gap-4 mt-4">
          <div className="w-[49%]">
            <p>
              <strong>Address: </strong>
              {data.mailingStreet}
            </p>
          </div>
          <div className="w-[49%]">
            <p>
              <strong>Address Line 2: </strong>
              {data.mailingStreet2}
            </p>
          </div>
          <div className="w-[32%]">
            <p>
              <strong>City: </strong>
              {data.mailingCity}
            </p>
          </div>
          <div className="w-[32%]">
            <p>
              <strong>State: </strong>
              {data.mailingState}
            </p>
          </div>
          <div className="w-[32%]">
            <p>
              <strong>ZIP: </strong>
              {data.mailingZip}
            </p>
          </div>
        </div>
      </div>
      <div className="border rounded p-4 mb-8">
        <p className="text-xl">
          <strong>Business garaging address</strong>
        </p>
        <div className="flex justify-between w-full flex-wrap gap-4 mt-4">
          <div className="w-[49%]">
            <p>
              <strong>Address: </strong>
              {data.garageStreet}
            </p>
          </div>
          <div className="w-[49%]">
            <p>
              <strong>Address Line 2: </strong>
              {data.garageStreet2}
            </p>
          </div>
          <div className="w-[32%]">
            <p>
              <strong>City: </strong>
              {data.garageCity}
            </p>
          </div>
          <div className="w-[32%]">
            <p>
              <strong>State: </strong>
              {data.garageState}
            </p>
          </div>
          <div className="w-[32%]">
            <p>
              <strong>ZIP: </strong>
              {data.garageZip}
            </p>
          </div>
        </div>
      </div>
      {progressive && (
        <div className="flex flex-col gap-2">
          <p>How is the customer's business structured? (Progressive Only)</p>
          <div className="flex items-center gap-2">
            <Icon image="check" color="green" size={20} />
            <p>
              Individual / Sole proprietor - Partnership - Corporation or LCC /
              Non-Profit
            </p>
          </div>
          <div className="flex justify-between w-full">
            <div className="w-[49%]">
              <p>Keyword describing your business (Progressive Only)</p>
              <p>{data.describingYourBusiness}</p>
            </div>
            <div className="w-[49%]">
              <p>
                business tax information: employer identification number
                (Progressive Only)
              </p>
              <p>{data.employerIdentificationNumber}</p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="check" color="green" size={20} />
            <p>Is the customer currently insured? (Progressive Only)</p>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="x" color="red" size={20} />
            <p>
              Has the customer had continuous coverage? (at least 1 year)
              (Progressive Only)
            </p>
          </div>
          <p>Current bodily injury liability limit (Progressive Only)</p>
          <p>Current policy expiration date (Progressive Only)</p>
          <p>
            Does the customer have other coverages for the business?
            (Progressive Only)
          </p>
          <div className="flex items-center gap-2">
            <Icon image="x" color="red" size={20} />
            <p>General liability - Business owner's policy - none</p>
          </div>
          <div className="flex justify-between w-full">
            <div className="w-[49%]">
              <p>Number of named additional insureds (Progressive Only)</p>
              <p>{data.numberNamedAdditionalInsureds}</p>
            </div>
            <div className="w-[49%]">
              <p>
                Number of named waivers of subrogation holders (Progressive
                Only)
              </p>
              <p>{data.numberNamedWaiversSubrogationHolders}</p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="x" color="red" size={20} />
            <p>
              Is a blanket additional insured endorsement needed by contract?
              (Progressive Only)
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="x" color="red" size={20} />
            <p>
              Is a blanket waiver of subrogation endorsement needed by contract?
              (Progressive Only)
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="x" color="red" size={20} />
            <p>Are state or federal filings required? (Progressive Only)</p>
          </div>
        </div>
      )}
      <p className="border-b pb-4 text-xl mt-6">
        <strong>Insured Contact Info</strong>
      </p>
      <div className="flex justify-between flex-wrap w-full mt-4">
        <div className="w-[49%]">
          <p>
            <strong>Name</strong>
          </p>
          <p>
            {data.insuranceContactFirstName} {data.insuranceContactLastName}
          </p>
        </div>
        <div className="w-[49%]">
          <p>
            <strong>Email address</strong>
          </p>
          <p>{data.insuranceContactEmail}</p>
        </div>
        <div className="w-[49%]">
          <p>
            <strong>Phone</strong>
          </p>
          <p>{data.insuranceContactPhone}</p>
        </div>
        {progressive && (
          <>
            <div className="w-[49%]">
              <p>
                <strong>Date of birth (Progressive Only)</strong>
              </p>
              <p>{data.insuredContactBirth}</p>
            </div>
            <div className="w-[49%]">
              <p>
                <strong>Home address (Progressive Only)</strong>
              </p>
              <p>{data.insuredContactHomeAddress}</p>
            </div>
            <div className="w-[49%]">
              <p>
                <strong>City (Progressive Only)</strong>
              </p>
              <p>{data.insuredContactCity}</p>
            </div>
            <div className="w-[49%]">
              <p>
                <strong>State (Progressive Only)</strong>
              </p>
              <p>{data.insuredContactState}</p>
            </div>
            <div className="w-[49%]">
              <p>
                <strong>ZIP (Progressive Only)</strong>
              </p>
              <p>{data.insuredContactZip}</p>
            </div>
          </>
        )}
      </div>
    </Card>
  );
}

function Limits(props) {
  const data = props.data;

  function formatPrice(price) {
    if (price === undefined || price === null) {
      return "$0.00";
    }

    if (
      typeof price === "string" &&
      !isNaN(parseFloat(price)) &&
      price.trim().slice(-1) !== "%"
    ) {
      price = parseFloat(price);
    }

    if (typeof price === "number" && !isNaN(price)) {
      return price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      return price.toUpperCase();
    }
  }

  return (
    <Card className="mb-6 border border-slate-300" id="4">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300">
        <h2 className="capitalize text-xl font-semibold pt-4">Limits</h2>
      </header>
      <div className="flex justify-between flex-wrap w-full gap-4">
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Number of trucks</strong>
          </p>
          <p>{data.nbrOfTrucks}</p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Total value of trucks</strong>
          </p>
          <p>{formatPrice(data.valueOfTrucks)}</p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Number of trailers</strong>
          </p>
          <p>{data.nbrOfTrailers}</p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Total value of trailers</strong>
          </p>
          <p>{formatPrice(data.valueOfTrailers)}</p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Towing, Labor and Storage Limit</strong>
          </p>
          <p>{formatPrice(data.limitTowingStorage)}</p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>Trailer Interchange Limit</strong>
          </p>
          <p>
            {formatPrice(data.trailerInterchangeLimit)}
          </p>
        </div>
      </div>
      <p className="mt-4">
        <strong>Auto Liability Limit</strong>
      </p>
      <p>{formatPrice(data.limitAutoLiability)}</p>
    </Card>
  );
}

function Operations(props) {
  const data = props.data;
  const operationDescription =
    data.operationDescription?.length > 0
      ? data.operationDescription.join(", ")
      : "No Description";

  return (
    <Card className="mb-6 border border-slate-300" id="5">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex items-center pt-4">
        <h2 className="capitalize text-xl font-semibold w-1/2">Operations</h2>
      </header>
      <div className="flex items-center gap-2">
        <Icon
          image={data.filingsAlFederal ? "check" : "x"}
          color={data.filingsAlFederal ? "green" : "red"}
          size={20}
        />
        <p>
          <strong>Do you require federal filings for auto liability?</strong>
        </p>
      </div>
      <div className="flex items-center gap-2">
        <Icon
          image={data.filingsAlState ? "check" : "x"}
          color={data.filingsAlState ? "green" : "red"}
          size={20}
        />
        <p className="my-2">
          <strong>Do you require state filings for auto liability?</strong>
        </p>
      </div>
      <div className="flex items-center gap-2">
        <Icon
          image={data.priorInsuranceCancelledNonrenewed ? "check" : "x"}
          color={data.priorInsuranceCancelledNonrenewed ? "green" : "red"}
          size={20}
        />
        <p>
          <strong>
            Has your prior insurance ever been cancelled or non-renewed by any
            insurer in the past 3 years (for any reasons NOT related to payment
            of premium)?
          </strong>
        </p>
      </div>
      <div className="w-full my-4">
        <p>
          <strong>
            Time operating in your name: {data.yearsInBusiness} Years,{" "}
            {data.monthsInBusiness} Months
          </strong>
        </p>
      </div>
      <p className="text-xl underline mt-4">
        <strong>Best description of your operation</strong>
      </p>
      <p className="text-lg my-4">{operationDescription}</p>
      <div className="flex items-center gap-2">
        <Icon
          image={data.ineligibleOperations ? "check" : "x"}
          color={data.ineligibleOperations ? "green" : "red"}
          size={20}
        />
        <p>Does insured conduct any of the following ineligible operations:</p>
      </div>
      <div className="flex justify-between w-full mt-4">
        <ul className="pl-4 list-disc w-[32%]">
          <li>Ambulance, EMT, Mobile Blood Banks</li>
          <li>Armored Cars</li>
          <li>Arms, Ammunition, Fireworks</li>
          <li>Boat Haulers</li>
          <li>Automobile Dismantiers</li>
          <li>Boom Trucks</li>
          <li>Brokerage</li>
          <li>Cannabis Haulers</li>
          <li>Carníivais, Circus</li>
          <li>Cement Mixers, Pumpers</li>
          <li>Coal Haulers</li>
          <li>Contractors, Contractor Equipment Mobile Equipment</li>
          <li>Cotton Haulers</li>
          <li>
            Courier Services, magazines, news delivery, parcel, film delivery
          </li>
        </ul>
        <ul className="pl-4 list-disc w-[32%]">
          <li>Drive Away, Transporter Plates</li>
          <li>Driving Schools</li>
          <li>Dump Trucks S&G</li>
          <li>Egg Haulers</li>
          <li>Fast food delivery, including restaurant take-out delivery</li>
          <li>Final Delivery</li>
          <li>Freight Brokerage</li>
          <li>Garbage/Refuse</li>
          <li>Hazmat, Nuclear, Biological</li>
          <li>Household Good Movers</li>
          <li>Livestock Transporters</li>
          <li>Logging Operations</li>
          <li>Milk Haulers</li>
          <li>Mobile cranes, Vehicles with buckets</li>
        </ul>
        <ul className="list-disc w-[32%]">
          <li>Mobile Home Toters</li>
          <li>Mobile Home Toters, Building Movers</li>
          <li>Municipal</li>
          <li>Operating outside of US</li>
          <li>Operations using double/triple trailers</li>
          <li>Oversized/Overweight haulers requiring pilot cars</li>
          <li>Owned Units, Rented or Leased to Others</li>
          <li>Private Passenger Vehícies</li>
          <li>
            Public Livery: Buses, taxis, black car, ride share, shuttle, day
            care, church
          </li>
          <li>Rental Leasing, Demo Vehícies</li>
          <li>Salvage, Scrap Metal</li>
          <li>Steel Dealers</li>
          <li>Tankers Hauling Flammable Liquids</li>
          <li>Towing/Repossession,</li>
          <li>Vendor Trucks</li>
        </ul>
      </div>
      {progressive && (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Icon image="check" color="green" size={20} />
            <p>
              <strong>
                Are all listed vehicles owned by the business? (Progressive
                Only)
              </strong>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="check" color="green" size={20} />
            <p>
              <strong>
                Are any listed vehicles used to haul any coiled steel?
                (Progressive Only)
              </strong>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="check" color="green" size={20} />
            <p>
              <strong>
                Are any listed vehicles used to transport passengers from
                centers to school? (Progressive Only)
              </strong>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="check" color="green" size={20} />
            <p>
              <strong>
                Are any listed vehicles used to transport children between home
                and school? (Progressive Only)
              </strong>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="check" color="green" size={20} />
            <p>
              <strong>
                Are any listed vehicles used to provide sightseeing tours?
                (Progressive Only)
              </strong>
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Icon image="check" color="green" size={20} />
            <p>
              <strong>
                Are any listed vehicles used to transport seasonal farm workers?
                (Progressive Only)
              </strong>
            </p>
          </div>
        </div>
      )}
    </Card>
  );
}

function Radius(props) {
  const data = props.data;

  return (
    <Card className="border border-slate-300" id="6">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300">
        <h2 className="capitalize text-xl font-semibold pt-4">Radius</h2>
      </header>
      <div className="flex justify-between flex-wrap w-full">
        <div className="w-[22%]">
          <p className="m-0">
            <strong>Under 50 Miles</strong>
          </p>
          <p>{data.radius0_50 || 0} %</p>
        </div>
        <div className="w-[22%]">
          <p className="m-0">
            <strong>51 - 200 Miles</strong>
          </p>
          <p>{data.radius51_200 || 0} %</p>
        </div>
        <div className="w-[22%]">
          <p className="m-0">
            <strong>201 - 500 Miles</strong>
          </p>
          <p>{data.radius201_500 || 0} %</p>
        </div>
        <div className="w-[22%]">
          <p className="m-0">
            <strong>500+ Miles</strong>
          </p>
          <p>{data.radius501 || 0} %</p>
        </div>
      </div>
    </Card>
  );
}

function Cargo(props) {
  const data = props.data;

  const commodityOptions = [
    { value: "agricultural_equipment", label: "Agricultural Equipment" },
    {
      value: "alcoholic_beverages",
      label: "Alcoholic beverages (except beer and wine)",
    },
    { value: "appliances", label: "Appliances (except TV and stereos)" },
    {
      value: "arms_ammunition_fireworks",
      label: "Arms, ammunition, fireworks",
    },
    {
      value: "automobile_parts_and_accessories",
      label: "Automobile parts and accessories",
    },
    {
      value: "automobiles_5_vehicles_or_less",
      label: "Automobiles (5 vehicles or less)",
    },
    {
      value: "automobiles_6_vehicles_or_more",
      label: "Automobiles (6+ vehicles)",
    },
    { value: "beer_and_wine", label: "Beer and wine" },
    { value: "beverages", label: "Beverages (nonalcoholic)" },
    { value: "building_materials", label: "Building materials" },
    { value: "bulk_or_bagged_nuts", label: "Bulk or Bagged Nuts" },
    { value: "cameras_and_film", label: "Cameras and film" },
    { value: "cannabis_cbd", label: "Cannabis, CBD" },
    { value: "canned_goods", label: "Canned goods" },
    { value: "cement_sand_or_gravel", label: "Cement, sand, or gravel" },
    {
      value: "chemicals_packaged_or_bulk",
      label: "Chemicals (packaged or bulk)",
    },
    { value: "china_and_ceramics", label: "China and ceramics" },
    { value: "cigarettes_and_cigars", label: "Cigarettes and cigars" },
    { value: "clothing", label: "Clothing" },
    { value: "coal", label: "Coal" },
    { value: "computers", label: "Computers" },
    {
      value: "contractors_heavy_equipment",
      label: "Contractors heavy equipment",
    },
    { value: "cosmetics_and_perfume", label: "Cosmetics and perfume" },
    { value: "cotton", label: "Cotton" },
    { value: "courier_documents", label: "Courier Documents" },
    { value: "cranes", label: "Cranes" },
    { value: "currency", label: "Currency" },
    { value: "dairy_products", label: "Dairy products" },
    {
      value: "dangerous_articles_explosives_corrosives_flamma",
      label: "Dangerous articles, explosives, corrosives, flamma...",
    },
    { value: "department_store_goods", label: "Department Store Goods" },
    { value: "documents", label: "Documents" },
    { value: "drugs", label: "Drugs (except narcotics)" },
    { value: "dry_goods", label: "Dry goods" },
    { value: "eggs_shell", label: "Eggs (shell)" },
    {
      value: "electrical_supplies_and_fixtures",
      label: "Electrical supplies and fixtures",
    },
    { value: "empty_trailers", label: "Empty Trailers" },
    { value: "farm_products", label: "Farm products" },
    { value: "fertilizer", label: "Fertilizer" },
    { value: "fine_arts", label: "Fine arts" },
    { value: "food_products", label: "Food products" },
    { value: "fracking", label: "Fracking" },
    { value: "frozen_or_refrigerated", label: "Frozen or refrigerated" },
    { value: "furniture", label: "Furniture" },
    { value: "furs", label: "Furs" },
    { value: "general_merchandise", label: "General merchandise" },
    { value: "glassware", label: "Glassware" },
    { value: "grain_hay_feed", label: "Grain, hay, feed" },
    { value: "hanging_meat", label: "Hanging Meat" },
    { value: "hardware_and_paint", label: "Hardware and paint" },
    { value: "hazmat", label: "Hazmat" },
    { value: "household_effects", label: "Household Effects" },
    {
      value: "household_goods",
      label: "Household Goods (Residential Delivery)",
    },
    { value: "intermodal_containers", label: "Intermodal Containers" },
    { value: "jewelry", label: "Jewelry" },
    {
      value: "ladies_and_mens_dress_apparel",
      label: "Ladies and mens dress apparel",
    },
    { value: "leather_goods", label: "Leather goods (except shoes)" },
    { value: "liquid_haulers", label: "Liquid haulers (bulk nonflammable)" },
    {
      value: "livestock_and_live_poultry",
      label: "Livestock and live poultry",
    },
    { value: "logs", label: "Logs" },
    { value: "lumber", label: "Lumber" },
    { value: "machinery_light", label: "Machinery (light)" },
    {
      value: "machinery_and_heavy_equipment",
      label: "Machinery and heavy equipment",
    },
    { value: "meat", label: "Meat" },
    { value: "metal_and steel", label: "Metal and steel" },
    { value: "mobile_homes", label: "Mobile Homes" },
    { value: "narcotics", label: "Narcotics" },
    { value: "non-ferrous_metal", label: "Non-Ferrous Metal" },
    {
      value: "nursery-plants_trees_flowers",
      label: "Nursery-Plants, Trees, Flowers",
    },
    { value: "office_equipment", label: "Office equipment" },
    { value: "paper_and_paper_products", label: "Paper and paper products" },
    { value: "people", label: "People" },
    {
      value: "petroleum_products",
      label: "Petroleum products (under 140 degrees flashpoint)",
    },
    { value: "pipe_cable_and_wire", label: "Pipe, cable, and wire" },
    { value: "plumbing_supplies", label: "Plumbing supplies" },
    {
      value: "plastic_and_plastic_products",
      label: "Plastic and plastic products",
    },
    { value: "poultry", label: "Poultry (dressed)" },
    { value: "power_tools", label: "Power tools" },
    { value: "precious_metals", label: "Precious metals" },
    { value: "precious_stones", label: "Precious stones" },
    { value: "produce", label: "Produce" },
    {
      value: "recreational_vehicles_boats_rvs",
      label: "Recreational Vehicles, boats, RVS",
    },
    { value: "refuse_garbage", label: "Refuse, Garbage" },
    {
      value: "rugs_and_carpets_oriental",
      label: "Rugs and carpets (Oriental)",
    },
    {
      value: "rugs_and_carpets",
      label: "Rugs and carpets (other than Oriental)",
    },
    { value: "scrap_metal", label: "Scrap Metal" },
    { value: "seafood_canned", label: "Seafood Canned" },
    { value: "seafood_fresh", label: "Seafood Fresh" },
    { value: "seafood_frozen", label: "Seafood Frozen" },
    { value: "seafood_shellfish", label: "Seafood Shellfish" },
    { value: "seafood", label: "Seafood" },
    { value: "shoes", label: "Shoes" },
    { value: "specialized_heavy_haulers", label: "Specialized heavy haulers" },
    { value: "sporting_goods_and_toys", label: "Sporting goods and toys" },
    { value: "textiles", label: "Textiles" },
    { value: "tires_and_tubes", label: "Tires and tubes" },
    { value: "tobacco", label: "Tobacco" },
    {
      value: "tv_radios_and_stereo_equipment",
      label: "TV, radios, and stereo equipment",
    },
    { value: "video_equipment_and_tapes", label: "Video equipment and tapes" },
  ];

  return (
    <Card className="mb-6 border border-slate-300" id="7">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Cargo</h2>
      </header>
      <div className="w-full mb-4">
        <p className="m-0">
          <strong>Limit-Motor Truck Cargo</strong>
        </p>
        <p>{data.mtcLimit}</p>
      </div>
      {data.commodityArray?.length > 0 &&
        data.commodityArray.map(
          (commodity) =>
            commodity.commodityKey?.length > 0 && (
              <div key={commodity.commodityId || commodity._id}>
                <p>Commodity</p>
                <p className="font-bold">
                  {
                    commodityOptions.find(
                      (option) => option.value === commodity.commodityKey
                    ).label
                  }{" "}
                  {commodity.commodityPercentage} %
                </p>
              </div>
            )
        )}
      <div className="flex items-center gap-2 mt-6 font-bold text-lg">
        <Icon
          image={data.commoditiesRefrigeration ? "check" : "x"}
          color={data.commoditiesRefrigeration ? "green" : "red"}
          size={20}
        />
        <p>Refrigerated goods hauled</p>
      </div>
    </Card>
  );
}

function Vehicles(props) {
  const data = props.data;
  const classOptions = [
    { value: "1", label: "Class 1: 0–6,000 pounds (0–2,722 kg)" },
    { value: "2a", label: "Class 2a: 6,001–8,500 pounds (2,722–3,856 kg)" },
    { value: "2b", label: "Class 2b: 8,501–10,000 pounds (3,856–4,536 kg)" },
    { value: "3", label: "Class 3: 10,001–14,000 pounds (4,536–6,350 kg)" },
    { value: "4", label: "Class 4: 14,001–16,000 pounds (6,351–7,257 kg)" },
    { value: "5", label: "Class 5: 16,001–19,500 pounds (7,258–8,845 kg)" },
    { value: "6", label: "Class 6 : 19,501–26,000 pounds (8,846–11,793 kg)" },
    { value: "7", label: "Class 7: 26,001–33,000 pounds (11,794–14,969 kg)" },
    { value: "8", label: "Class 8: 33,001 pounds (14,969 kg) +" },
  ];
  const bodyTypeOptions = [
    { value: "boom_truck", label: "Boom Truck" },
    { value: "box_truck", label: "Box Truck" },
    { value: "bucket_truck", label: "Bucket Truck" },
    { value: "bus", label: "Bus" },
    { value: "catering_truck", label: "Catering Truck" },
    { value: "cement_truck", label: "Cement Truck" },
    { value: "delivery_truck", label: "Delivery Truck" },
    { value: "delivery_van", label: "Delivery Van" },
    { value: "dump_truck", label: "Dump Truck" },
    { value: "fire_truck", label: "Fire Truck" },
    { value: "flatbed_tow_truck", label: "Flatbed Tow Truck" },
    { value: "flatbed_truck", label: "Flatbed Truck" },
    { value: "food_truck", label: "Food Truck" },
    { value: "fuel_tank_truck", label: "Fuel Tank Truck" },
    { value: "garbage_truck", label: "Garbage Truck" },
    { value: "landscape_truck", label: "Landscape Truck" },
    { value: "logging_truck", label: "Logging Truck" },
    { value: "milk_tanker_truck", label: "Milk Tanker Truck" },
    { value: "mini_van", label: "Mini Van" },
    { value: "moving_truck", label: "Moving Truck" },
    { value: "pick_up", label: "Pickup" },
    { value: "on_hook_tow_truck", label: "On Hook Tow Truck" },
    { value: "rack_truck", label: "Rack Truck" },
    { value: "snow_plow_truck", label: "Snow Plow Truck" },
    { value: "stake_truck", label: "Stake Truck" },
    { value: "step_van", label: "Step Van" },
    { value: "suv", label: "SUV" },
    { value: "tanker_truck", label: "Tanker Truck" },
    { value: "tractor", label: "Tractor" },
    { value: "utility_van", label: "Utility Van" },
  ];

  return (
    <Card className="mb-6 border border-slate-300" id="9">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Vehicles</h2>
      </header>
      {data.vehicles.length > 0 && (
        <div>
          {data.vehicles.map((vehicle, index) => (
            <div
              className="flex justify-between flex-wrap gap-4 w-full border border-slate-300 rounded p-2"
              key={vehicle.vin}
            >
              <p className="w-full font-bold text-xl border-b-2 border-slate-300">
                Vehicle {index + 1}
              </p>
              <div className="w-[49%]">
                <p className="m-0">Vehicle identification number (VIN)</p>
                <p className="font-bold">{vehicle.vin}</p>
              </div>
              {progressive && (
                <div className="w-[49%]">
                  <p className="m-0">Type (Progressive Only)</p>
                  <p className="font-bold">Type</p>
                </div>
              )}
              <div className="w-[49%]">
                <p className="m-0">Year</p>
                <p className="font-bold">{vehicle.year}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">Make of manufacturer</p>
                <p className="font-bold">{vehicle.make}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">Model</p>
                <p className="font-bold">{vehicle.model}</p>
              </div>
              {progressive && (
                <div className="w-[49%]">
                  <p className="m-0">Value (Progressive Only)</p>
                  <p className="font-bold">{vehicle.value}</p>
                </div>
              )}
              <div className="w-[49%]">
                <p className="m-0">Class</p>
                <p className="font-bold">
                  {
                    classOptions.find((item) => item.value === vehicle.classKey)
                      ?.label
                  }
                </p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">Body type</p>
                <p className="font-bold">
                  {
                    bodyTypeOptions.find(
                      (item) => item.value === vehicle.bodyTypeKey
                    )?.label
                  }
                </p>
              </div>
              {progressive && (
                <>
                  <div className="w-[49%]">
                    <p className="m-0">
                      ZIP code where the vehicle is located (Progressive Only)
                    </p>
                    <p className="font-bold">ZIP</p>
                  </div>
                  <div className="w-[49%]">
                    <p className="m-0">
                      Farthest one-way distance this vehicle typically travels
                      (Progressive Only)
                    </p>
                    <p className="font-bold">Distance</p>
                  </div>
                  <p className="w-full">
                    Is there a loan/lease on this vehicle? (Progressive Only):
                    No
                  </p>
                  <div className="flex items-center gap-2 mt-2 w-full">
                    <Icon image="check" color="green" size={20} />
                    <p>
                      Does the customer need comprehensive or collision coverage
                      to protect this vehicle in an accident or loss?
                      (Progressive Only)
                    </p>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function Trailers(props) {
  const data = props.data;
  const bodyTypeOptions = [
    { value: "auto_transporter_trailer", label: "Auto Transporter Trailer" },
    { value: "chip_trailer", label: "Chip Trailer" },
    { value: "curtain_van_trailer", label: "Curtain Van Trailer" },
    { value: "double_van_trailer", label: "Double Van Trailer" },
    { value: "dry_bulk_trailer", label: "Dry Bulk Trailer" },
    { value: "dry_van_trailer", label: "Dry Van Trailer" },
    { value: "dump_trailer", label: "Dump Trailer" },
    { value: "flatbed_trailer", label: "Flatbed Trailer" },
    { value: "gooseneck_trailer", label: "Gooseneck Trailer" },
    { value: "hopper_trailer", label: "Hopper Trailer" },
    {
      value: "intermodal_container_hauler_trailer",
      label: "Intermodal Container Hauler Trailer",
    },
    { value: "logging_trailer", label: "Logging Trailer" },
    { value: "low_boy_trailer", label: "Low Boy Trailer" },
    { value: "pup_trailer", label: "Pup Trailer" },
    { value: "refrigerated_van_trailer", label: "Refrigerated Van Trailer" },
    { value: "step_deck_trailer", label: "Step Deck Trailer" },
    { value: "tanker_trailer", label: "Tanker Trailer" },
    { value: "tilt_deck_trailer", label: "Tilt deck Trailer" },
    { value: "triple_van_trailer", label: "Triple Van Trailer" },
    { value: "utility_trailer", label: "Utility Trailer" },
  ];

  return (
    <Card className="mb-6 border border-slate-300" id="10">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Trailers</h2>
      </header>
      {data.trailers.length > 0 && (
        <div>
          {data.trailers.map((vehicle, index) => (
            <div
              className="flex justify-between flex-wrap gap-4 w-full border border-slate-300 rounded p-2"
              key={vehicle.vin}
            >
              <p className="w-full font-bold text-xl border-b-2 border-slate-300">
                Vehicle {index + 1}
              </p>
              <div className="w-[49%]">
                <p className="m-0">Vehicle identification number (VIN)</p>
                <p className="font-bold">{vehicle.vin}</p>
              </div>
              {progressive && (
                <div className="w-[49%]">
                  <p className="m-0">Type (Progressive Only)</p>
                  <p className="font-bold">Type</p>
                </div>
              )}
              <div className="w-[49%]">
                <p className="m-0">Year</p>
                <p className="font-bold">{vehicle.year}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">Make of manufacturer</p>
                <p className="font-bold">{vehicle.make}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">Model</p>
                <p className="font-bold">{vehicle.model}</p>
              </div>
              {progressive && (
                <div className="w-[49%]">
                  <p className="m-0">Value (Progressive Only)</p>
                  <p className="font-bold">{vehicle.value}</p>
                </div>
              )}
              <div className="w-[49%]">
                <p className="m-0">Body type</p>
                <p className="font-bold">
                  {
                    bodyTypeOptions.find(
                      (item) => item.value === vehicle.bodyTypeKey
                    )?.label
                  }
                </p>
              </div>
              {progressive && (
                <>
                  <div className="w-[49%]">
                    <p className="m-0">
                      ZIP code where the vehicle is located (Progressive Only)
                    </p>
                    <p className="font-bold">ZIP</p>
                  </div>
                  <div className="w-[49%]">
                    <p className="m-0">
                      Farthest one-way distance this vehicle typically travels
                      (Progressive Only)
                    </p>
                    <p className="font-bold">Distance</p>
                  </div>
                  <p className="w-full">
                    Is there a loan/lease on this vehicle? (Progressive Only):
                    No
                  </p>
                  <div className="flex items-center gap-2 mt-2 w-full">
                    <Icon image="check" color="green" size={20} />
                    <p>
                      Does the customer need comprehensive or collision coverage
                      to protect this vehicle in an accident or loss?
                      (Progressive Only)
                    </p>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function Drivers(props) {
  const data = props.data;

  return (
    <Card className="mb-6 border border-slate-300" id="11">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300 flex justify-between">
        <h2 className="capitalize text-xl font-semibold pt-4">Drivers</h2>
      </header>
      {data.drivers.length > 0 && (
        <div>
          {data.drivers.map((driver, index) => (
            <div
              className="flex justify-between flex-wrap gap-4 w-full border border-slate-300 rounded p-2"
              key={driver.driverId}
            >
              <p className="w-full font-bold text-xl border-b-2 border-slate-300">
                Driver {index + 1}
              </p>
              <div className="w-[49%]">
                <p className="m-0">Name</p>
                <p className="font-bold">
                  {driver.firstName || ""} {driver.lastName || ""}
                </p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">Date of birth</p>
                <p className="font-bold">{driver.dateOfBirth || ""}</p>
              </div>
              <div className="w-[49%]">
                <p className="m-0">Years of experience</p>
                <p className="font-bold">{driver.yearsExperience || ""}</p>
              </div>
              {progressive && (
                <>
                  <div className="w-[49%]">
                    <p className="m-0">
                      Driver’s license state (Progressive Only)
                    </p>
                    <p className="font-bold">{driver.licenseState || ""}</p>
                  </div>
                  <div className="w-[49%]">
                    <p className="m-0">
                      Driver’s license number (Progressive Only)
                    </p>
                    <p className="font-bold">{driver.licenseNumber || ""}</p>
                  </div>
                </>
              )}
              <div className="w-[49%]">
                <p className="m-0">Date of Hire</p>
                <p className="font-bold">{driver.dateOfHire || ""}</p>
              </div>
              <div className="w-[32%]">
                <p className="m-0">Suspensions</p>
                <p className="font-bold">{driver.suspensions_total || ""}</p>
              </div>
              <div className="w-[32%]">
                <p className="m-0">Accidents</p>
                <p className="font-bold">{driver.accidents_total || ""}</p>
              </div>
              <div className="w-[32%]">
                <p className="m-0">Violations</p>
                <p className="font-bold">{driver.violations_total || ""}</p>
              </div>
              {driver.frontPhoto && (
                <img
                  src={driver.frontPhoto.url}
                  alt="License front"
                  className="w-[32%] h-60 object-cover mx-auto"
                />
              )}
              {driver.backPhoto && (
                <img
                  src={driver.backPhoto.url}
                  alt="License front"
                  className="w-[32%] h-60 object-cover mx-auto"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}

function Losses(props) {
  const data = props.data;

  return (
    <Card id="12">
      <header className="w-full pb-4 mb-4 bg-transparent border-b-2 border-slate-300">
        <h2 className="capitalize text-xl font-semibold pt-4">Losses</h2>
      </header>
      <p className="text-blue-500 text-xl">
        <strong>2022 - 2023</strong>
      </p>
      <p>Physical damage, cargo</p>
      <div className="flex justify-between flex-wrap gap-4 w-full">
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Auto liability: Loss count (Open or closed):{" "}
              {data.lossAlCount23 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0 whitespace-nowrap">
            <strong>
              Auto liability: Losses paid (Open or reserved):{" $"}
              {data.lossAlPaid23 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Physical damage: Loss count (Open or closed):{" "}
              {data.lossApdCount23 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Physical damage: Losses paid (Open or reserved):{" $"}
              {data.lossApdPaid23 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Cargo: Loss count (Open or closed): {data.lossMtcCount23 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Cargo: Losses paid (Open or reserved): ${data.lossMtcPaid23 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Loss count (Open or closed):{" "}
              {data.lossTglCount23 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Losses paid (Open or reserved):{" $"}
              {data.lossTglPaid23 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Non-Trucking Liability: Loss count (Open or closed):{" "}
              {data.lossNtlCount23 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Non-Trucking Liability: Losses paid (Open or reserved):{" $"}
              {data.lossNtlPaid23 || 0}
            </strong>
          </p>
        </div>
      </div>
      <p className="text-blue-500 text-xl mt-4">
        <strong>2021 - 2022</strong>
      </p>
      <p>Physical damage, cargo</p>
      <div className="flex justify-between flex-wrap gap-4 w-full">
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Auto liability: Loss count (Open or closed):{" "}
              {data.lossAlCount22 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0 whitespace-nowrap">
            <strong>
              Auto liability: Losses paid (Open or reserved):{" $"}
              {data.lossAlPaid22 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Physical damage: Loss count (Open or closed):{" "}
              {data.lossApdCount22 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Physical damage: Losses paid (Open or reserved):{" $"}
              {data.lossApdPaid22 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Cargo: Loss count (Open or closed): {data.lossMtcCount22 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Cargo: Losses paid (Open or reserved): ${data.lossMtcPaid22 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Loss count (Open or closed):{" "}
              {data.lossTglCount22 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Losses paid (Open or reserved):{" $"}
              {data.lossTglPaid22 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Non-Trucking Liability: Loss count (Open or closed):{" "}
              {data.lossNtlCount22 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Non-Trucking Liability: Losses paid (Open or reserved):{" $"}
              {data.lossNtlPaid22 || 0}
            </strong>
          </p>
        </div>
      </div>
      <p className="text-blue-500 text-xl mt-4">
        <strong>2020 - 2021</strong>
      </p>
      <p>Physical damage, cargo</p>
      <div className="flex justify-between flex-wrap gap-4 w-full">
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Auto liability: Loss count (Open or closed):{" "}
              {data.lossAlCount21 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0 whitespace-nowrap">
            <strong>
              Auto liability: Losses paid (Open or reserved):{" $"}
              {data.lossAlPaid21 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Physical damage: Loss count (Open or closed):{" "}
              {data.lossApdCount21 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Physical damage: Losses paid (Open or reserved):{" $"}
              {data.lossApdPaid21 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Cargo: Loss count (Open or closed): {data.lossMtcCount21 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Cargo: Losses paid (Open or reserved): ${data.lossMtcPaid21 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Loss count (Open or closed):{" "}
              {data.lossTglCount21 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Truckers general liability: Losses paid (Open or reserved):{" $"}
              {data.lossTglPaid21 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Non-Trucking Liability: Loss count (Open or closed):{" "}
              {data.lossNtlCount21 || 0}
            </strong>
          </p>
        </div>
        <div className="w-[49%]">
          <p className="m-0">
            <strong>
              Non-Trucking Liability: Losses paid (Open or reserved):{" $"}
              {data.lossNtlPaid21 || 0}
            </strong>
          </p>
        </div>
      </div>
    </Card>
  );
}
