const Style = {

  switch: 'relative block w-full',
  label: 'absolute left-0 top-0.5 normal-case',
  track: 'absolute left-40 top-0 w-12 h-6 cursor-pointer overlow-hidden rounded-full ease-in-out duration-300',
  track2: 'w-12 h-6 cursor-pointer overlow-hidden rounded-full ease-in-out duration-300',
  trackOn: 'bg-blue-500',
  trackOff: 'bg-slate-300',
  handle: `absolute top-1/2 text-white h-4 w-4 text-sm text-enter font-semibold pointer-events-none 
    bg-white rounded-full -translate-y-1/2 ease-in-out duration-300`,
  handle2: `relative mt-3 text-white h-4 w-4 text-sm text-enter font-semibold pointer-events-none 
    bg-white rounded-full -translate-y-1/2 ease-in-out duration-300`,
    
  handleOff: 'left-1',
  handleOn: 'left-7',

}

export default Style;