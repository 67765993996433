import { Text, View } from "@react-pdf/renderer";
import Box from "./box";

function FirstRow({ general, letter }) {
  function formatDate(inputDate) {
    if (!inputDate) {
      return "";
    }
    const date = new Date(inputDate);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
  }

  return (
    <View style={{ flexDirection: "row", height: "118px" }}>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <Text
          style={{
            textAlign: "center",
            borderBottom: "1px solid black",
            paddingBottom: "1px"
          }}
        >
          INSR LTR
        </Text>
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "93%"
          }}
        >
          <Text style={{ textAlign: "center" }}>{letter}</Text>
        </View>
      </View>
      <View
        style={{
          width: "25%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <Text
          style={{
            textAlign: "center",
            borderBottom: "1px solid black",
            paddingTop: "6.5px"
          }}
        >
          TYPE OF INSURANCE
        </Text>
        <Text style={{ textAlign: "left", paddingTop: "2px" }}>
          GENERAL LIABILITY
        </Text>
        <View
          style={{
            flexDirection: "row",
            fontFamily: "Helvetica",
            fontSize: "5.7px"
          }}
        >
          <Box showCross={general ? true : false} />
          <Text style={{ marginLeft: "2px", marginTop: "5px" }}>
            COMMERCIAL GENERAL LIABILITY
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            fontSize: "5.7px",
            fontFamily: "Helvetica"
          }}
        >
          <View style={{ flexDirection: "row", flex: "1", marginLeft: "13px" }}>
            <Box />
            <Text
              style={{
                marginRight: "5px",
                marginLeft: "2px",
                paddingTop: "7px"
              }}
            >
              CLAIMS-MADE
            </Text>
          </View>
          <View style={{ flexDirection: "row", flex: "1" }}>
            <Box showCross={general ? true : false} />
            <Text style={{ marginLeft: "2px", paddingTop: "7px" }}>OCCUR</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            fontFamily: "Helvetica",
            fontSize: "5.7px",
            borderBottom: "0.5px solid black"
          }}
        >
          <Box />
        </View>
        <View
          style={{
            flexDirection: "row",
            fontFamily: "Helvetica",
            fontSize: "5.7px",
            borderBottom: "0.5px solid black"
          }}
        >
          <Box />
        </View>
        <Text
          style={{
            fontFamily: "Helvetica",
            textAlign: "center",
            paddingVertical: "3px"
          }}
        >
          GEN'L AGGREGATE LIMITS APPLY PER:
        </Text>
        <View
          style={{
            fontFamily: "Helvetica",
            justifyContent: "space-between",
            flexDirection: "row"
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <Box showCross={general ? true : false} />
            <Text style={{ marginLeft: "2px", paddingTop: "6px" }}>POLICY</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Box />
            <Text style={{ marginLeft: "2px", width: "20%" }}>PROJECT</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Box />
            <Text
              style={{
                marginLeft: "2px",
                paddingTop: "6px",
                paddingRight: "3px"
              }}
            >
              LOC
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <Text style={{ textAlign: "center", borderBottom: "1px solid black" }}>
          ADDL INSR
        </Text>
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <Text style={{ textAlign: "center", borderBottom: "1px solid black" }}>
          SUBR WVD
        </Text>
      </View>
      <View
        style={{
          width: "18%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <Text
          style={{
            textAlign: "center",
            borderBottom: "1px solid black",
            paddingTop: "6.5px"
          }}
        >
          POLICY NUMBER
        </Text>
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%"
          }}
        >
          <Text style={{ textAlign: "center" }}>
            {general ? general.policy_number : "None"}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <Text style={{ textAlign: "center", borderBottom: "1px solid black" }}>
          POLICY EFF (MM/DD/YYYY)
        </Text>
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%"
          }}
        >
          <Text style={{ textAlign: "center" }}>
            {general && formatDate(general.policy_effective_date)}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <Text
          style={{
            textAlign: "center",
            borderBottom: "1px solid black",
            paddingHorizontal: "1px"
          }}
        >
          POLICY EXP (MM/DD/YYYY)
        </Text>
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%"
          }}
        >
          <Text style={{ textAlign: "center" }}>
            {general && formatDate(general.updated_policy_expiration_date)}
          </Text>
        </View>
      </View>
      <View style={{ width: "29%", borderBottom: "1px solid black" }}>
        <Text
          style={{
            textAlign: "center",
            borderBottom: "1px solid black",
            paddingTop: "6.5px"
          }}
        >
          LIMITS
        </Text>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "87%",
            fontFamily: "Helvetica"
          }}
        >
          <View
            style={{
              flex: "1",
              borderRight: "1px solid black",
              fontSize: "5px"
            }}
          >
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "14.2%",
                paddingTop: "5px",
                paddingLeft: "2px"
              }}
            >
              EACH OCCURENCE
            </Text>
            <View
              style={{
                borderBottom: "1px solid black",
                height: "14.8%",
                paddingTop: "1px"
              }}
            >
              <Text style={{ paddingLeft: "2px" }}>DAMAGE TO RENTED</Text>
              <Text style={{ paddingLeft: "2px" }}>
                PREMISES (Ea occurence)
              </Text>
            </View>
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "14.2%",
                paddingTop: "5px",
                paddingLeft: "2px"
              }}
            >
              MED EXP(Any one person)
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "14.2%",
                paddingTop: "5px",
                paddingLeft: "2px"
              }}
            >
              PERSONAL & AVD INJURY
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "14.2%",
                paddingTop: "5px",
                paddingLeft: "2px"
              }}
            >
              GENERAL AGGREGATE
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "14.8%",
                paddingTop: "5px",
                paddingLeft: "2px"
              }}
            >
              PRODUCTS-COMP/OG AGG
            </Text>
            <Text style={{ height: "13.6%", paddingLeft: "1.5px" }}></Text>
          </View>
          <View style={{ flex: "1", fontSize: "6px" }}>
            <View
              style={{
                height: "14.2%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
              {general && (
                <Text style={{ textAlign: "center" }}>1,000,000</Text>
              )}
            </View>
            <View
              style={{
                height: "14.8%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
              {general && <Text style={{ textAlign: "center" }}>100,000</Text>}
            </View>
            <View
              style={{
                height: "14.2%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
              {general && <Text style={{ textAlign: "center" }}>5,000</Text>}
            </View>
            <View
              style={{
                height: "14.2%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
              {general && (
                <Text style={{ textAlign: "center" }}>1,000,000</Text>
              )}
            </View>
            <View
              style={{
                height: "14.2%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
              {general && (
                <Text style={{ textAlign: "center" }}>{general.limit}</Text>
              )}
            </View>
            <View
              style={{
                height: "14.8%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
              {general && (
                <Text style={{ textAlign: "center" }}>{general.limit}</Text>
              )}
            </View>
            <View
              style={{
                height: "13.6%",
                flexDirection: "row",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
export default FirstRow;
