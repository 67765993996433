/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React, { useContext, useEffect } from "react";
import {
  Animate,
  AuthContext,
  ViewContext,
  Form,
  Card,
  Link,
  Row,
  useLocation,
  useNavigate,
} from "components/lib";

export function Signin(props) {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();

  const form = {
    email: {
      label: "Email",
      type: "email",
      icon: true,
      required: true,
      class: "w-full pl-10",
      placeholder: "devid@mail.com",
    },
    password: {
      label: "Password",
      type: "password",
      required: true,
      complexPassword: true,
      class: "w-full",
      placeholder: "123456789",
    },
    forgotpassword: {
      type: "link",
      url: "/forgotpassword",
      class: "w-full",
      text: "Forgot your password?",
    },
  };

  useEffect(() => {
    // user signed in?
    if (authContext.user?.token) {
      navigate("/customers");
    }

    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes("error")) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf("?error=") + 7));
      viewContext.notification.show(msg, "error");
    }
  }, [location.search, viewContext.notification, authContext, navigate]);

  return (
    <Animate type="pop">
      <Row title="Sign in">
        <Card restrictWidth center>
          <Form
            data={form}
            method="POST"
            url="/api/auth"
            buttonText="Sign In"
            buttonClass="flex justify-center w-full mt-4"
            callback={(res) => {
              res.data["2fa_required"]
                ? navigate(`/signin/otp?token=${res.data.token}`)
                : navigate(authContext.signin(res));
            }}
          />

          <div className="mt-4">
            Don't have an account? <Link url="/signup" text="Sign Up" />
          </div>
        </Card>
        {/* } */}
      </Row>
    </Animate>
  );
}
