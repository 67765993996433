import { React, useContext, useState, useEffect } from 'react';
import { Card, Animate, Table, useAPI, ViewContext } from "components/lib";

const Requests = () => {
  const context = useContext(ViewContext);
  const requests = useAPI('/api/user/getRequests')?.data;
  const [requestsData, setRequestsData] = useState([]);
  
  useEffect(() => {

    // format the user list
    let list = [];

    if (requests instanceof Array){
      list = requests.map(x => {
        return {
          legalName: x.legalName,
          dotNumber: x.dotNumber,
          date_requested: x.request.editedDate,
          alteration: x.request.alteration,
          description: x.request.editedField + ' by ' + x.request.editedByName,
          editedById: x.request.editedById,
          requestId: x.request.requestId,
          id: x.request.requestType
        }
      })
    }

    setRequestsData(list);

  }, [requests]);

  const approveRequest = (data) => {

    context.modal.show({
      title: 'Approve or Deny alteration',
      requestForm: {
        data: data,
        requestsData: requestsData,
        setRequestsData
      }
      }, (form, res) => {

        if (res) {
          context.notification.show('Request approved', 'success', true);
          const removeRequest = [...requestsData].filter(request => request.requestId !== data.requestId);
          setRequestsData(removeRequest);
        };
    });
  };

  return (
    <Animate type='pop'>

      <Card>
        <Table
          search
          data={ requestsData }
          show={['date_requested', 'alteration', 'description']}
          badge={{ col: 'alteration', color: 'blue'}}
          actions={{ edit: approveRequest }}
          paginate
          paginateLimit='6'
        />
      </Card>

    </Animate>
  );

};

export default Requests;
