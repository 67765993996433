/***
 *
 *   Drivers Page
 *
 **********/

import { React, useContext, useState, Fragment, useEffect } from "react";
import {
  Card,
  Table,
  TitleRow,
  Button,
  ViewContext,
  Animate,
  TabView,
} from "components/lib";
import { CompanyNav } from "components/nav/company/companyNav";
import axios from "axios";
import usStates from "../../data/us-states.json";

export function ProspectDrivers(props) {
  const context = useContext(ViewContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [driversData, setDriversData] = useState([]);
  const [archiveDrivers, setArchiveDrivers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const company = JSON.parse(localStorage.getItem("company"));
      const res = await axios.get("/api/driver/prospectGet", {
        params: {
          dotNumber: company["dot_#"],
        },
      });
  
      // format the drivers list table
      let list = [];
  
      if (res.data?.length) {
        list = res.data.map((x) => {
          return {
            name: x.firstName ? `${x.firstName} ${x.lastName || ""}` : x.fullName,
            firstName: x.firstName,
            lastName: x.lastName,
            license_number: x.licenseNumber,
            license_state: x.licenseState,
            date_of_birth: x.dateOfBirth,
            dateOfHire: x.dateOfHire,
            years_of_experience: x.yearsExperience,
            status: x.status,
            front_license: x.frontPhoto,
            back_license: x.backPhoto,
            accidents_total: x.accidents_total,
            violations_total: x.violations_total,
            suspensions_total: x.suspensions_total,
            id: x.driverId,
          };
        });
      }
      setDriversData(list);
      const resArchive = await axios.get(
        `/api/prospect/archives?dotNumber=${company["dot_#"]}`
      );
      let archiveList = [];
  
      if (resArchive.data?.Archives?.drivers?.length) {
        archiveList = resArchive.data?.Archives?.drivers.map((x) => {
          return {
            name: x.firstName ? x.firstName + " " + x.lastName : x.fullName,
            firstName: x.firstName,
            lastName: x.lastName,
            license_number: x.licenseNumber,
            license_state: x.licenseState,
            date_of_birth: x.dateOfBirth,
            dateOfHire: x.dateOfHire,
            years_of_experience: x.yearsExperience,
            status: x.status,
            front_license: x.frontPhoto,
            back_license: x.backPhoto,
            accidents_total: x.accidents_total,
            violations_total: x.violations_total,
            suspensions_total: x.suspensions_total,
            id: x.driverId,
          };
        });
      }
      setArchiveDrivers(archiveList);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const addDriver = (data) => {
    const addDriverURL = "/api/driver/salesAdd";

    context.modal.show(
      {
        title: "Add Driver",
        form: {
          userName: {
            type: "hidden",
            value: user.name,
          },
          client: {
            type: "hidden",
            value: "prospect",
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          editedField: {
            type: "hidden",
            value: "Driver Created",
          },
          dotNumber: {
            type: "hidden",
            value: companyData["dot_#"],
          },
          firstName: {
            id: "firstName",
            label: "First Name",
            type: "text",
            class: "w-full",
            containerClass: "w-[49%]",
            required: true,
          },
          lastName: {
            id: "lastName",
            label: "Last Name",
            type: "text",
            class: "w-full",
            containerClass: "w-[49%]",
          },
          dateOfBirth: {
            label: "Date of Birth",
            type: "date",
            class: "w-full",
            containerClass: "w-[49%]",
          },
          dateOfHire: {
            id: "dateOfHire",
            label: "Date of Hire",
            type: "date",
            class: "w-full",
            containerClass: "w-[49%]",
          },
          licenseNumber: {
            label: "Driver's License Number",
            type: "text",
            class: "w-full",
            containerClass: "w-[32%]",
          },
          licenseState: {
            label: "Driver's License State",
            type: "select",
            options: usStates,
            class: "w-full",
            containerClass: "w-[32%]",
          },
          yearsExperience: {
            label: "Years of Experience",
            type: "number",
            class: "w-full",
            containerClass: "w-[32%]",
          },
          status: {
            type: "hidden",
            value: "active",
          },
          frontPhoto: {
            label: "Driver's License Front Picture",
            type: "file",
            max: 1,
            accept: ["jpg", "png", "jpeg", "pdf"],
            class: "w-full",
            containerClass: "w-[49%]",
          },
          backPhoto: {
            label: "Driver's License Back Picture",
            type: "file",
            max: 1,
            accept: ["jpg", "png", "jpeg", "pdf"],
            class: "w-full",
            containerClass: "w-[49%]",
          },
          mvr: {
            label: "MVR Summary (prior 36 months)",
            type: "header",
            class: "w-full",
            containerClass: "w-[32%] mt-4",
          },
          accidents_total: {
            id: "accidents_total",
            label: "Accidents",
            type: "number",
            class: "w-full",
            containerClass: "w-[32%]",
          },
          violations_total: {
            id: "violations_total",
            label: "Violations",
            type: "number",
            class: "w-full",
            containerClass: "w-[32%]",
          },
          suspensions_total: {
            id: "suspensions_total",
            label: "suspensions",
            type: "number",
            class: "w-full",
            containerClass: "w-[32%] mb-4",
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Save",
        buttonClass: "flex justify-end w-full",
        url: addDriverURL,
        method: "POST",
      },
      (form, res) => {
        if (res.Driver) {
          let list = [];
          list = res.Driver.drivers.map((x) => {
            return {
              name: x.firstName
                ? `${x.firstName} ${x.lastName || ""}`
                : x.fullName,
              firstName: x.firstName,
              lastName: x.lastName,
              license_number: x.licenseNumber,
              license_state: x.licenseState,
              date_of_birth: x.dateOfBirth,
              dateOfHire: x.dateOfHire,
              years_of_experience: x.yearsExperience,
              status: x.status,
              front_license: x.frontPhoto,
              back_license: x.backPhoto,
              accidents_total: x.accidents_total,
              violations_total: x.violations_total,
              suspensions_total: x.suspensions_total,
              id: x.driverId,
            };
          });
          setDriversData(list);
          context.notification.show("Driver Created", "success", true);
        }
      }
    );
  };

  const editDriver = (data, callback) => {
    const editDriverURL = "/api/driver/salesEdit";
    const editDriverMethod = "PUT";

    context.modal.show(
      {
        title: "Edit Driver",
        form: {
          userName: {
            type: "hidden",
            value: user.name,
          },
          client: {
            type: "hidden",
            value: "prospect",
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          editedField: {
            type: "hidden",
            value: `Driver ${data.name} Edited`,
          },
          dotNumber: {
            type: "hidden",
            value: companyData["dot_#"],
          },
          driverId: {
            type: "hidden",
            value: data.id,
          },
          firstName: {
            id: "firstName",
            label: "First Name",
            value: data.firstName ? data.firstName : data.name.split(" ")[0],
            type: "text",
            class: "w-full",
            containerClass: "w-[49%]",
            required: true,
          },
          lastName: {
            id: "lastName",
            label: "Last Name",
            value: data.lastName ? data.lastName : data.name.split(" ")[1],
            type: "text",
            class: "w-full",
            containerClass: "w-[49%]",
          },
          dateOfBirth: {
            label: "Date of Birth",
            type: "date",
            value: data.date_of_birth,
            class: "w-full",
            containerClass: "w-[32%]",
          },
          dateOfHire: {
            id: "dateOfHire",
            label: "Date of Hire",
            type: "date",
            value: data.dateOfHire,
            class: "w-full",
            containerClass: "w-[32%]",
          },
          status: {
            label: "Status",
            type: "select",
            default: data.status,
            value: data.status,
            class: "w-full",
            options: [
              { value: "active", label: "Active" },
              { value: "inactive", label: "Inactive" },
            ],
            containerClass: "w-[32%]",
          },
          licenseNumber: {
            label: "Driver's License Number",
            type: "text",
            value: data.license_number,
            readonly: true,
            class: "w-full",
            containerClass: "w-[32%]",
          },
          licenseState: {
            label: "Driver's License State",
            type: "select",
            value: data.license_state,
            class: "w-full",
            containerClass: "w-[32%]",
            options: usStates,
          },
          yearsExperience: {
            label: "Years of Experience",
            type: "number",
            value: data.years_of_experience,
            class: "w-full",
            containerClass: "w-[32%]",
          },
          frontPhoto: {
            label: "Driver's License Front Picture",
            type: "file",
            max: 1,
            accept: ["jpg", "png", "jpeg", "pdf"],
            class: "w-full",
            containerClass: "w-[49%]",
          },
          backPhoto: {
            label: "Driver's License Back Picture",
            type: "file",
            max: 1,
            accept: ["jpg", "png", "jpeg", "pdf"],
            class: "w-full",
            containerClass: "w-[49%]",
          },
          actualFrontPhoto: {
            class: "block text-blue-500 underline mb-4 text-center w-[49%]",
            text: "Front License Link",
            url: data.front_license?.url,
            type: data.front_license?.url ? "link" : "hidden",
          },
          actualBackPhoto: {
            class:
              "block text-blue-500 underline mb-4 text-center w-[49%] ml-auto",
            text: "Back License Link",
            url: data.back_license?.url,
            type: data.back_license?.url ? "link" : "hidden",
          },
          mvr: {
            label: "MVR Summary (prior 36 months)",
            type: "header",
            class: "w-full",
            containerClass: "w-[32%] mt-4",
          },
          accidents_total: {
            id: "accidents_total",
            label: "Accidents",
            value: data.accidents_total,
            type: "number",
            class: "w-full",
            containerClass: "w-[32%]",
          },
          violations_total: {
            id: "violations_total",
            label: "Violations",
            value: data.violations_total,
            type: "number",
            class: "w-full",
            containerClass: "w-[32%]",
          },
          suspensions_total: {
            id: "suspensions_total",
            label: "Suspensions",
            value: data.suspensions_total,
            type: "number",
            class: "w-full",
            containerClass: "w-[32%] mb-4",
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Save",
        buttonClass: "flex justify-end w-full",
        url: editDriverURL,
        method: editDriverMethod,
      },
      (form, res) => {
        if (res.Driver) {
          let list = [];
          list = res.Driver.drivers.map((x) => {
            return {
              name: x.firstName
                ? `${x.firstName} ${x.lastName || ""}`
                : x.fullName,
              firstName: x.firstName ? x.firstName : "",
              lastName: x.lastName ? x.lastName : "",
              license_number: x.licenseNumber,
              license_state: x.licenseState,
              date_of_birth: x.dateOfBirth,
              dateOfHire: x.dateOfHire,
              years_of_experience: x.yearsExperience,
              status: x.status,
              front_license: x.frontPhoto,
              back_license: x.backPhoto,
              accidents_total: x.accidents_total,
              violations_total: x.violations_total,
              suspensions_total: x.suspensions_total,
              id: x.driverId,
            };
          });
          setDriversData(list);
          context.notification.show("Driver Edited", "success", true);
        }
      }
    );
  };

  const archiveDriver = (data) => {
    context.modal.show(
      {
        title: "Archive Driver",
        text: `Are you sure you want to archive Driver "${data.name}" ?`,
        form: {
          userName: {
            type: "hidden",
            value: user.name,
          },
          client: {
            type: "hidden",
            value: "prospect",
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          editedField: {
            type: "hidden",
            value: `Driver "${data.name}" Archived`,
          },
          licenseNumber: {
            value: data.license_number,
            type: "hidden",
          },
          dotNumber: {
            value: companyData["dot_#"],
            type: "hidden",
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Archive",
        buttonClass: "flex justify-end w-full",
        url: "/api/driver/salesProspectArchive",
        method: "DELETE",
      },
      (form, res) => {
        if (res.archivedDriver) {
          let driversList = [...driversData];
          setDriversData(driversList.filter((item) => item.id !== data.id));

          delete data.actions;

          const archiveList = [data, ...archiveDrivers];

          setArchiveDrivers(archiveList);
          context.notification.show("Driver Archived", "success", true);
        }
      }
    );
  };

  const restoreDriver = (data) => {
    context.modal.show(
      {
        title: "Restore Driver",
        text: `Are you sure you want to restore driver "${data.name}" ?`,
        form: {
          driverId: {
            type: "hidden",
            value: data.id,
          },
          dotNumber: {
            type: "hidden",
            value: companyData["dot_#"],
          },
          user_id: {
            type: "hidden",
            value: user.accounts[0].user_id,
          },
          userName: {
            type: "hidden",
            value: user.name,
          },
          editedField: {
            type: "hidden",
            value: `Restored driver "${data.name}"`,
          },
        },
        className: "flex flex-wrap justify-between",
        buttonText: "Restore",
        buttonClass: "flex justify-end w-full mt-4",
        url: "/api/driver/prospectRestore",
        method: "POST",
      },
      (form, res) => {
        if (res.restoredDriver) {
          delete data.actions;
          data.status = "active";

          let driversList = [data, ...driversData];
          setDriversData(driversList);

          const archiveList = [...archiveDrivers];
          setArchiveDrivers(
            archiveList.filter((item) => item.name !== data.name)
          );

          context.notification.show("Driver Restored", "success", true);
        }
      }
    );
  };

  const driverLicenseModal = (data) => {
    context.modal.show({
      title: `${data.name}'s License Images`,
      imgLinks: [
        {
          url: data.front_license?.url,
          text: "Front license URL",
          linkClassName: "text-white bg-blue-700 w-fit p-4 rounded",
        },
        {
          url: data.back_license?.url,
          text: "Back license URL",
          linkClassName: "text-white bg-blue-700 w-fit p-4 rounded",
        },
      ],
    });
  };

  return (
    <Fragment>
      <CompanyNav />

      <Animate type="pop">
        <TitleRow>
          <Button
            small
            text="Add Driver"
            icon="plus-circle"
            className="bg-blue-700 text-white rounded mr-4"
            action={addDriver}
          />
        </TitleRow>

        <TabView name="Drivers" labels={["Drivers", "Archived Drivers"]}>
          <Card>
            <Table
              search
              loading={loading}
              data={driversData}
              show={[
                "name",
                "license_number",
                "license_state",
                "date_of_birth",
                "years_of_experience",
                "status",
              ]}
              actions={{
                edit: editDriver,
                custom: [
                  {
                    icon: "image",
                    action: driverLicenseModal,
                  },
                  {
                    col: "archive",
                    value: "archive",
                    icon: "archive",
                    action: archiveDriver,
                  },
                ],
              }}
              badge={{
                col: "status",
                color: "orange",
                condition: [
                  { value: "inactive", color: "red" },
                  { value: "active", color: "green" },
                ],
              }}
              paginate
              paginateLimit="6"
            ></Table>
          </Card>

          <Card>
            <Table
              search
              loading={loading}
              data={archiveDrivers}
              show={[
                "name",
                "license_number",
                "license_state",
                "date_of_birth",
                "years_of_experience",
              ]}
              actions={{
                custom: [
                  {
                    col: "restore",
                    value: "restore",
                    icon: "rotate-ccw",
                    action: restoreDriver,
                  },
                ],
              }}
              paginate
              paginateLimit="6"
            ></Table>
          </Card>
        </TabView>
      </Animate>
    </Fragment>
  );
}
