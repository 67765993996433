import { useContext, useState } from "react";
import {
  ViewContext,
  TextInput,
  Error,
  Button,
  DateInput,
} from "components/lib";
import { pdf } from "@react-pdf/renderer";
import Certificate from "components/certificatePDF/certificate";
import axios from "axios";
import { MultiSelect } from "../select/multiSelect";

const AddCertificateModal = ({ submissionsNumbers }) => {
  const context = useContext(ViewContext);
  const company = JSON.parse(localStorage.getItem("company"));
  const [holder, setHolder] = useState("");
  const [remark, setRemark] = useState("");
  const [email, setEmails] = useState(company.email);
  const [pdfData, setPDFData] = useState({});
  const [submissionNumber, setSubmissionNumber] = useState([]);
  const [certificateError, setCertificateError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [secondLoading, setSecondLoading] = useState(false);

  const submissionNumbersList = submissionsNumbers.map((number) => {
    return {
      value: number,
      label: number,
    };
  });

  const onChangeCoveragesDate = (name, value) => {
    setPDFData((prevState) => {
      return {
        ...prevState,
        coverages: {
          ...prevState.coverages,
          [name]: {
            ...prevState.coverages[name],
            updated_policy_expiration_date: value,
          },
        },
      };
    });
  };

  const formatDate = (inputDate) => {
    try {
      const [year, month, day] = inputDate.split("-");
      return `${month}/${day}/${year}`;
    } catch (error) {
      return "";
    }
  };

  const updatePolicyExpirationDates = (object) => {
    const updatedCoverages = { ...object };

    for (const coverageKey in updatedCoverages.coverages) {
      if (
        Object.prototype.hasOwnProperty.call(
          updatedCoverages.coverages,
          coverageKey
        )
      ) {
        const coverage = updatedCoverages.coverages[coverageKey];
        if (coverage.hasOwnProperty("policy_expiration_date")) {
          const formatCoverageDate = formatDate(
            coverage.policy_expiration_date
          );
          const [month, day, year] = formatCoverageDate.split("/");

          coverage.policy_expiration_date = formatCoverageDate;
          coverage.updated_policy_expiration_date = formatCoverageDate;
          coverage.max_policy_expiration_date = {
            year: year,
            month: month,
            day: day,
          };
        }
      }
    }
    return updatedCoverages;
  };

  const getDataFromSubmission = async () => {
    if (submissionNumber.length === 0) {
      return;
    }
    setLoading(true);
    try {
      const responses = [];
      for (let i = 0; i < submissionNumber.length; i++) {
        const policyUrl = `/api/policy/${submissionNumber[i]}`;
        let res = await axios.get(policyUrl);

        if (res?.data?.Policy?.data) {
          responses.push(res.data.Policy.data);
        }
      }

      const newObject = responses[0];

      for (let i = 1; i < responses.length; i++) {
        const currentObject = responses[i];

        for (const coverageKey in currentObject.coverages) {
          if (!(coverageKey in newObject.coverages)) {
            newObject.coverages[coverageKey] =
              currentObject.coverages[coverageKey];
          }
        }
      }

      setDisabled(false);

      setPDFData(updatePolicyExpirationDates(newObject));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const submit = async () => {
    setSecondLoading(true);
    setCertificateError(false);
    try {
      const pdfBlob = await pdf(
        <Certificate data={pdfData} customerHolder={holder} remark={remark} />
      ).toBlob();
      const formData = new FormData();
      formData.append("document", pdfBlob, `${company.company_name}.pdf`);
      formData.append("submission_number", submissionNumber);
      formData.append("certificate_holder", holder);
      formData.append("additional_remarks", remark);
      formData.append("email", email);
      const response = await axios.post(`/api/certificate/save`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!response?.data?.Quote?.pdf_url) {
        setCertificateError(true);
        console.log(response);
        return;
      }
      context.modal.hide(false, response?.data?.Quote);
    } catch (error) {
      console.log(error);
      setCertificateError(true);
    } finally {
      setSecondLoading(false);
    }
  };

  return submissionsNumbers?.length > 0 ? (
    <form
      onSubmit={submit}
      noValidate
      className="flex flex-wrap gap-2 justify-between"
    >
      <TextInput
        name="emails"
        id="emails"
        type="text"
        label="Send To"
        className="w-full"
        containerClass="w-full"
        value={email}
        onChange={(name, value) => {
          setEmails(value);
        }}
      />
      <MultiSelect
        name="submission_number"
        id="submission_number"
        label="Submission Number"
        className="w-full"
        containerClass="w-3/4"
        options={submissionNumbersList}
        onChange={(name, value) => {
          setSubmissionNumber(value);
        }}
      />
      <Button
        color="bluesign"
        loading={loading}
        text="Get Coverages"
        className="w-1/5 mt-6 text-sm"
        action={getDataFromSubmission}
      />
      <TextInput
        name="customerHolder"
        id="customerHolder"
        type="textarea"
        label="Certificate Holder"
        className="w-full"
        containerClass="w-[48%]"
        value={holder}
        onChange={(name, value) => {
          setHolder(value);
        }}
      />
      <TextInput
        name="aditionalRemarks"
        id="aditionalRemarks"
        type="textarea"
        label="Additional Remarks"
        className="w-full"
        containerClass="w-[48%]"
        value={remark}
        onChange={(name, value) => {
          setRemark(value);
        }}
      />
      {pdfData.coverages && (
        <div className="w-full border border-slate-300 rounded-lg text-center text-sm">
          <div className="border-b border-slate-300 flex w-full justify-between">
            <p className="p-2 w-1/3 !mb-0">Coverage</p>
            <p className="p-2 w-1/3 !mb-0">Current Expiration Date</p>
            <p className="p-2 w-1/3 !mb-0">Updated Expiration Date</p>
          </div>

          {Object.keys(pdfData.coverages).map((key, index) => (
            <div
              className={`${
                index + 1 < Object.keys(pdfData.coverages).length &&
                "border-b border-slate-300"
              } flex w-full justify-between`}
              key={key}
            >
              <p className="p-2 w-1/3 flex items-center justify-center !mb-0">
                {key.toUpperCase()}
              </p>
              <p className="p-2 w-1/3 flex items-center justify-center !mb-0">
                {pdfData.coverages[key].policy_expiration_date}
              </p>
              <DateInput
                name={key}
                className="w-full mx-auto mt-1 text-sm"
                containerClass="w-1/3 px-4"
                onChange={onChangeCoveragesDate}
                calendarPosition="top"
                value={pdfData.coverages[key].updated_policy_expiration_date}
                max={pdfData.coverages[key].max_policy_expiration_date}
              />
            </div>
          ))}
        </div>
      )}

      {certificateError && (
        <Error message="There was a problem creating the certificate" />
      )}

      <div className="flex justify-end w-full mt-2">
        <Button
          color="red"
          outline
          className="mr-[2%]"
          text="Cancel"
          action={(e) => context.modal.hide(true)}
        />

        <Button
          color={disabled ? "dark" : "bluesign"}
          loading={secondLoading}
          text="Generate PDF"
          action={submit}
          disabled={disabled}
        />
      </div>
    </form>
  ) : (
    <Error message="There was a problem finding submissions numbers" />
  );
};

export default AddCertificateModal;
