import { Text, View } from "@react-pdf/renderer";
import Box from "./box";

function SecondRow({ automobile, letter }) {
  function formatDate(inputDate) {
    if (!inputDate) {
      return "";
    }
    const date = new Date(inputDate);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
  }

  return (
    <View style={{ flexDirection: "row", height: "65px" }}>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>{letter}</Text>
      </View>
      <View
        style={{
          width: "25%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black"
        }}
      >
        <Text style={{ textAlign: "left", paddingVertical: "2px" }}>
          AUTOMOBILE LIABILITY
        </Text>
        <View
          style={{
            flexDirection: "row",
            fontFamily: "Helvetica",
            fontSize: "5.7px"
          }}
        >
          <Box />
          <Text style={{ marginLeft: "2px", marginTop: "4px" }}>ANY AUTO</Text>
        </View>
        <View style={{ flexDirection: "row", fontSize: "5.4px" }}>
          <View
            style={{ flexDirection: "row", fontFamily: "Helvetica", flex: "1" }}
          >
            <Box />
            <Text
              style={{
                marginRight: "5px",
                width: "60%",
                marginLeft: "2px",
                paddingTop: "1px"
              }}
            >
              ALL OWNED AUTOS
            </Text>
          </View>
          <View
            style={{ flexDirection: "row", fontFamily: "Helvetica", flex: "1" }}
          >
            <Box showCross={automobile ? true : false} />
            <Text style={{ width: "60%", marginLeft: "2px" }}>
              SCHEDULED AUTOS
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", fontSize: "5.7px" }}>
          <View
            style={{ flexDirection: "row", fontFamily: "Helvetica", flex: "1" }}
          >
            <Box />
            <Text
              style={{
                marginRight: "5px",
                marginLeft: "2px",
                width: "60%",
                paddingTop: "7px"
              }}
            >
              HIRED AUTOS
            </Text>
          </View>
          <View
            style={{ flexDirection: "row", fontFamily: "Helvetica", flex: "1" }}
          >
            <Box />
            <Text style={{ width: "60%", marginLeft: "2px" }}>
              NON-OWNED AUTOS
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "4%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
      </View>
      <View
        style={{
          width: "18%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>{automobile && automobile.policy_number}</Text>
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>{automobile && formatDate(automobile.policy_effective_date)}</Text>
      </View>
      <View
        style={{
          width: "9%",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <Text style={{ textAlign: "center" }}>{automobile && formatDate(automobile.updated_policy_expiration_date)}</Text>
      </View>
      <View style={{ width: "29%", borderBottom: "1px solid black" }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
            fontFamily: "Helvetica"
          }}
        >
          <View
            style={{
              flex: "1",
              borderRight: "1px solid black",
              fontSize: "5px"
            }}
          >
            <View style={{ borderBottom: "1px solid black", height: "20%" }}>
              <Text style={{ paddingLeft: "2px" }}>COMBINED SINGLE LIMIT</Text>
              <Text style={{ paddingLeft: "2px" }}>(Ea accident)</Text>
            </View>
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "20%",
                paddingLeft: "2px",
                paddingTop: "4px"
              }}
            >
              BODILY INJURY(per person)
            </Text>
            <Text
              style={{
                borderBottom: "1px solid black",
                height: "20%",
                paddingLeft: "2px",
                paddingTop: "4px"
              }}
            >
              BODILY INJURY(per accident)
            </Text>
            <View style={{ borderBottom: "1px solid black", height: "22%" }}>
              <Text style={{ paddingLeft: "2px" }}>PROPERTY DAMAGE</Text>
              <Text style={{ paddingLeft: "2px" }}>(per accident)</Text>
            </View>
            <Text
              style={{ height: "20%", paddingTop: "4px", paddingLeft: "1.5px" }}
            ></Text>
          </View>
          <View style={{ flex: "1", fontSize: "6px" }}>
            <View
              style={{
                height: "20%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
              <Text style={{ textAlign: "center" }}>{automobile && automobile.limit}</Text>
            </View>
            <View
              style={{
                height: "20%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
            <View
              style={{
                height: "20%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
            <View
              style={{
                height: "20%",
                flexDirection: "row",
                borderBottom: "1px solid black",
                paddingTop: "2px"
              }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
            <View
              style={{ height: "20%", flexDirection: "row", paddingTop: "2px" }}
            >
              <Text style={{ paddingHorizontal: "2px" }}>$</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
export default SecondRow;
